import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useImmer } from 'use-immer';

import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import deleteUserMutation from '../mutations/deleteUser';

interface Props {
    id: string;
    email: string;
    callback?(): void;
}

interface State {
    modalOpen: boolean;
    buttonOpen: boolean;
}

const DeleteUserButton = ({ id, email, callback }: Props) => {
    const [state, setState] = useImmer<State>({
        modalOpen: false,
        buttonOpen: false
    });
    const Toaster = useContext(ToastContext);

    const toggleDeleteModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    const toggleButton = () => {
        setState(draft => {
            draft.buttonOpen = !state.buttonOpen;
        });
    };

    const [deleteUser] = useMutation(deleteUserMutation, {
        onCompleted: () => {
            Toaster.showToast(`Användare borttagen`, 'success');

            toggleDeleteModal();

            if (callback) {
                callback();
            }
        },
        onError: () => {
            Toaster.showToast(`Något gick fel `, 'danger', 5000);
        }
    });

    const handleDelete = async userId => {
        deleteUser({
            variables: {
                uuid: userId
            }
        });
    };

    return (
        <>
            <Button color="danger" type="submit" onClick={toggleDeleteModal}>
                <i className="fa fa-trash mr-1" />
                Ta bort användare
            </Button>

            <Modal isOpen={state.modalOpen} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Ta bort användare</ModalHeader>
                <ModalBody>
                    <p>
                        Är du säker på att du vill ta bort <strong>{email}</strong>?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <ButtonDropdown isOpen={state.buttonOpen} toggle={toggleButton}>
                        <DropdownToggle color="danger" caret>
                            <i className="fa fa-trash mr-1" />
                            Ta bort användare
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={e => {
                                    e.preventDefault();

                                    handleDelete(id);
                                }}
                            >
                                Bekräfta borttagning
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DeleteUserButton;
