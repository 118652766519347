import React, { useEffect } from 'react';
import { Form, FormikProps, withFormik, FormikErrors } from 'formik';

import { string } from 'yup';
import randomatic from 'randomatic';
import { Card, CardBody, Row, Col, Button, Container } from '@bytbil/bootstrap-react';
import { ActionPanel, Field, Toggle, Select, MultipleValueInput } from '@bytbil/general-react';
import { ScrollErrors, ScrollAndFocus } from 'components/FormScrollErrors';

import { DealerGroup, DealerFormProps } from 'types';

import Loader from 'components/Loader/Loader';

import ProductsForm from './ProductsForm';
import ExtrasForm from './ExtrasForm';
import TagsForm from './TagsForm';

import { addDealerSchema, editDealerSchema } from './validationSchema';

import { getProductSettingsForExistingDealer, getProductSettingsForNewDealer } from '../helpers/productsMapper';
import { counties } from './data';
import { DealerFormValues, SelectMultiple } from '../types';

const validators = {
    email: value => string().email('Invalid email').validate(value),
    password: string()
        .min(6, 'Lösenordet måste vara minst sex tecken långt')
        .matches(/^[^åäö]*$/, 'Lösenordet får inte innehålla Å, Ä eller Ö')
};

const INITIAL_FORM_VALUES = {
    code: '',
    companyName: '',
    orgNumber: '',
    established: '',
    website: '',
    emailDisplay: '',
    address: '',
    postalNumber: '',
    city: '',
    county: '',
    groups: [],
    password: randomatic('Aa0', 15),
    active: true
};

interface OtherProps {
    edit?: boolean;
    dealerForm: DealerFormProps;
    validateDealerCode?(value: string): void;
    dealerCodeLoading?: boolean;
    dealerCodeIsValid?: boolean;
    resetDealerCodeState?(): void;
}

const DealerForm = (props: OtherProps & FormikProps<DealerFormValues>) => {
    const {
        edit = false,
        setFieldTouched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        validateDealerCode,
        dealerCodeLoading,
        dealerCodeIsValid,
        validateForm,
        resetDealerCodeState,
        dealerForm
    } = props;

    const { dealerGroups, products, brands } = dealerForm;

    const blurHandler = key => () => {
        // this is going to call setFieldTouched and manually update touched.topics
        setFieldTouched(key, true);
    };

    const changeHandler = key => value => {
        if (value === null) {
            return setFieldValue(key, INITIAL_FORM_VALUES[key]);
        }
        // this is going to call setFieldValue and manually update values.topics
        return setFieldValue(key, value);
    };

    useEffect(() => {
        // Need to validate form after network call, otherwise error won't show until you blur field again
        validateForm();
    }, [dealerCodeIsValid, validateForm]);

    return (
        <ScrollErrors submitCount={submitCount} errors={errors} formId="dealer-form">
            {({ input }) => (
                <Form onSubmit={handleSubmit} noValidate name="dealer-form">
                    <React.Fragment>
                        <Card>
                            <CardBody>
                                <h3>Grundinformation</h3>
                                {!edit && (
                                    <Row className="pb-2">
                                        <Col xs="12" lg="5" xl="4">
                                            <ScrollAndFocus
                                                active={input === 'code'}
                                                tabIndex={input === 'code' ? -1 : undefined}
                                            >
                                                <Field
                                                    type="text"
                                                    label="Handlarkod*"
                                                    name="code"
                                                    id="code"
                                                    placeholder="2signum"
                                                    touched={touched.code}
                                                    error={errors.code}
                                                    onChange={e => {
                                                        // Reset validation error when changing input
                                                        if (resetDealerCodeState && !dealerCodeIsValid) {
                                                            resetDealerCodeState();
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    onBlur={async e => {
                                                        handleBlur(e);
                                                        // If there are no validation errors, do network call to validate code
                                                        if (validateDealerCode && !errors.code) {
                                                            validateDealerCode(values.code);
                                                        }
                                                    }}
                                                    value={values.code}
                                                />
                                            </ScrollAndFocus>
                                            {dealerCodeLoading && !errors.code && (
                                                <div className="d-flex align-items-center">
                                                    <Loader size={1} className="mr-2 text-muted" />
                                                    <p className="m-0">Validerar handlarkod ...</p>
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs="12" lg="5" xl="4">
                                            <Field
                                                type="text"
                                                label="Lösenord"
                                                name="password"
                                                id="password"
                                                placeholder="1234"
                                                touched={touched.password}
                                                error={errors.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <ScrollAndFocus
                                            active={input === 'companyName'}
                                            tabIndex={input === 'companyName' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                name="companyName"
                                                label="Företagsnamn*"
                                                id="companyName"
                                                placeholder="Bytbilsthlm AB"
                                                touched={touched.companyName}
                                                error={errors.companyName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.companyName}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                    <Col xs="12" lg="5" xl="4">
                                        <ScrollAndFocus
                                            active={input === 'orgNumber'}
                                            tabIndex={input === 'orgNumber' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                name="orgNumber"
                                                label="Org.nr*"
                                                id="orgNumber"
                                                placeholder="123456-1234"
                                                touched={touched.orgNumber}
                                                error={errors.orgNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.orgNumber}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12" lg="3" xl="4">
                                        <Field
                                            type="text"
                                            name="established"
                                            label="Bolaget grundades"
                                            id="established"
                                            placeholder="1994"
                                            touched={touched.established}
                                            error={errors.established}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.established}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <Select
                                            multiple
                                            type="text"
                                            label="Handlargrupper"
                                            name="groups"
                                            id="groups"
                                            placeholder="Bytbilgruppen"
                                            touched={touched.groups}
                                            error={errors.groups}
                                            onChange={changeHandler('groups')}
                                            onBlur={blurHandler('groups')}
                                            value={values.groups}
                                            options={dealerGroups.map(({ id, code, name }) => ({
                                                id,
                                                value: code,
                                                label: name
                                            }))}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="card-border-top">
                                <h3>Hemsida, mail, telefon och besöksadress</h3>
                                <p>
                                    Visas på handlarsidan på bytbil.com samt i accesspaketet. E-postadressen är även
                                    kopplad till lösenordet.
                                </p>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <Field
                                            type="text"
                                            label="Webbplats"
                                            name="website"
                                            id="website"
                                            placeholder="www.bytbil.com"
                                            touched={touched.website}
                                            error={errors.website}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.website}
                                        />
                                    </Col>
                                    <Col xs="12" lg="5" xl="4">
                                        <ScrollAndFocus
                                            active={input === 'emailDisplay'}
                                            tabIndex={input === 'emailDisplay' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                label="E-post*"
                                                name="emailDisplay"
                                                id="emailDisplay"
                                                placeholder="anders.andersson@bytbil.com"
                                                touched={touched.emailDisplay}
                                                error={errors.emailDisplay}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.emailDisplay}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="5" xl="3">
                                        <Field
                                            type="text"
                                            name="phoneNumber"
                                            label="Tel.nr"
                                            id="phoneNumber"
                                            placeholder="0731231231"
                                            touched={touched.phoneNumber}
                                            error={errors.phoneNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <ScrollAndFocus
                                            active={input === 'address'}
                                            tabIndex={input === 'address' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                label="Besöksadress"
                                                name="address"
                                                id="address"
                                                placeholder="Bytbilgatan 1"
                                                touched={touched.address}
                                                error={errors.address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.address}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <Field
                                            type="text"
                                            name="postAddress"
                                            id="postAddress"
                                            label="Postadress"
                                            placeholder=""
                                            touched={touched.postAddress}
                                            error={errors.postAddress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.postAddress}
                                        />
                                    </Col>
                                    <Col xs="12" lg="3" xl="2">
                                        <ScrollAndFocus
                                            active={input === 'postalNumber'}
                                            tabIndex={input === 'postalNumber' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                name="postalNumber"
                                                id="postalNumber"
                                                label="Postnummer*"
                                                placeholder="12345"
                                                maxLength="6"
                                                touched={touched.postalNumber}
                                                error={errors.postalNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.postalNumber}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                    <Col xs="12" lg="4" xl="3">
                                        <ScrollAndFocus
                                            active={input === 'city'}
                                            tabIndex={input === 'city' ? -1 : undefined}
                                        >
                                            <Field
                                                type="text"
                                                name="city"
                                                label="Postort*"
                                                id="city"
                                                placeholder="Stockholm"
                                                touched={touched.city}
                                                error={errors.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.city}
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <ScrollAndFocus
                                            active={input === 'county'}
                                            tabIndex={input === 'county' ? -1 : undefined}
                                        >
                                            <Select
                                                label="Län*"
                                                touched={touched.county}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.county}
                                                value={values.county}
                                                options={counties}
                                                id="county"
                                                name="county"
                                            />
                                        </ScrollAndFocus>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mt-3">
                            <CardBody>
                                <h3>Mottagare av intresseanmälningar</h3>
                                <Row>
                                    <Col xs="12" lg="6" xl="5">
                                        <MultipleValueInput
                                            label="E-postadress"
                                            value={values.emailLead}
                                            name="emailLead"
                                            validator={validators.email}
                                            onChange={changeHandler}
                                            onBlur={blurHandler}
                                            placeholder="anders.andersson@bytbil.com"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="card-border-top">
                                <h3>Mottagare av import- och exportmail</h3>
                                <Row>
                                    <Col xs="12" lg="6" xl="5">
                                        <MultipleValueInput
                                            value={values.emailImport}
                                            label="E-postadress för importmail"
                                            name="emailImport"
                                            validator={validators.email}
                                            onChange={changeHandler}
                                            onBlur={blurHandler}
                                            placeholder="anders.andersson@bytbil.com"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="6" xl="5">
                                        <MultipleValueInput
                                            value={values.emailExport}
                                            label="E-postadress för exportmail"
                                            className="mb-1 mb-md-0"
                                            name="emailExport"
                                            validator={validators.email}
                                            onChange={changeHandler}
                                            onBlur={blurHandler}
                                            placeholder="anders.andersson@bytbil.com"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mt-3">
                            <CardBody>
                                <h3>Auktoriserad återförsäljare </h3>
                                <p>Visas på objeksidor, i accesspaketet samt på handlarsidan på bytbil.com.</p>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <Select
                                            multiple
                                            name="authorizedBrandsCar"
                                            label="Bilmärken"
                                            className="mb-3"
                                            menuPlacementz="top"
                                            placeholder="Välj bilmärken"
                                            value={values.authorizedBrandsCar}
                                            onChange={changeHandler('authorizedBrandsCar')}
                                            onBlur={blurHandler('authorizedBrandsCar')}
                                            options={brands.map(({ id, name, slug }) => ({
                                                id,
                                                value: slug,
                                                label: name
                                            }))}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="5" xl="4">
                                        <Select
                                            multiple
                                            name="authorizedBrandsMc"
                                            menuPlacement="top"
                                            label="MC-märken"
                                            placeholder="Välj mc-märken"
                                            value={values.authorizedBrandsMc}
                                            onChange={changeHandler('authorizedBrandsMc')}
                                            onBlur={blurHandler('authorizedBrandsMc')}
                                            options={brands.map(({ id, name, slug }) => ({
                                                id,
                                                value: slug,
                                                label: name
                                            }))}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <ProductsForm products={products} />

                        <TagsForm />

                        <ExtrasForm />

                        <Card className="mt-3 mb-5 pb-3">
                            <CardBody>
                                <h3>På/Av</h3>
                                <Row>
                                    <Col xs="12">
                                        <Toggle
                                            id="active"
                                            name="active"
                                            description="Aktivera eller inaktivera handlare"
                                            className="mb-1 mb-md-0"
                                            value={values.active}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <ActionPanel showPanel>
                            <Container>
                                <Row className="d-flex align-items-center justify-content-end text-right py-2">
                                    <Col>
                                        <Button type="submit" name="dealer" color="primary" loading={isSubmitting}>
                                            Spara ändringar
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </ActionPanel>
                    </React.Fragment>
                </Form>
            )}
        </ScrollErrors>
    );
};

// The type of props MyForm receives
interface MyFormProps {
    edit?: boolean;
    dealer?: DealerFormValues;
    dealerIds?: number[];
    dealerGroups: DealerGroup[];
    dealerForm: DealerFormProps;
    submitHandler({ values, resetForm }): void;
    toaster: any;
    validateDealerCode?(value: string): void;
    dealerCodeIsValid?: boolean;
    dealerCodeMessage?: string;
    dealerCodeLoading?: boolean;
    resetDealerCodeState?(): void;
}

const EnhancedDealerForm = withFormik<MyFormProps, DealerFormValues>({
    validationSchema: props => {
        if (props && props.edit) {
            return editDealerSchema();
        }
        return addDealerSchema();
    },

    // Besides yup validation, also validate if code already exists among other dealers or dealergroups
    validate: async (values, props) => {
        const errors: FormikErrors<DealerFormValues> = {};
        if (!props.dealerCodeIsValid && !props.edit) {
            errors.code = props.dealerCodeMessage;
        }

        return errors;
    },

    enableReinitialize: true,

    mapPropsToValues: ({ dealer, dealerForm }) => {
        const { products } = dealerForm;
        /**
         * Products
         *
         * IF (new user) get the default product values for toggle - and any default settings for settings_schema
         *
         * IF (existing user) get the settings from the dealer object
         *
         */
        let PRODUCT_VALUES;
        let groups: SelectMultiple[] = [];

        if (dealer) {
            /** Will map over the dealers product settings and set the correct product values for the dealer */
            PRODUCT_VALUES = getProductSettingsForExistingDealer({
                dealerProducts: dealer.products,
                products
            });

            groups = dealer.groups.map(d => ({ id: d.id, value: d.code, label: d.name, code: d.code, name: d.name }));
        } else {
            /** These are the initial product values - some products (for example bump) should be active by default */
            PRODUCT_VALUES = getProductSettingsForNewDealer({ products });
        }

        return {
            ...INITIAL_FORM_VALUES,
            ...dealer,
            ...PRODUCT_VALUES,
            groups
        };
    },

    handleSubmit: async (values: DealerFormValues, formikBag) => {
        try {
            return formikBag.props.submitHandler({ values, resetForm: formikBag.resetForm });
        } catch (e) {
            formikBag.props.toaster.showToast(`Kunde inte spara ändringarna`, 'danger');

            return console.error(e);
        }
    },

    displayName: 'DealerForm'
})(DealerForm);

export default EnhancedDealerForm;
