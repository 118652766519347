import React from 'react';

import { Button } from '@bytbil/bootstrap-react';

import getBlocketAdminUrl from 'core/getBlocketAdminUrl';

interface Props {
    dealerId: string;
    className?: string;
}

const BLOCKET_ADMIN_URL = getBlocketAdminUrl();

const LoginToDealerButton = ({ dealerId, className }: Props) => {
    const handleClick = () => {
        window.open(`${BLOCKET_ADMIN_URL}/dealer/impersonate/${dealerId}`, '_blank');
    };

    return (
        <Button onClick={handleClick} color="link" className={className}>
            Logga in
        </Button>
    );
};

export default LoginToDealerButton;
