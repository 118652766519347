import gql from 'graphql-tag';

export default gql`
    query SearchDealers($search: String!) {
        searchDealers(search: $search) {
            id
            login
            code
            name
            email
            level
            active
            city
            groups {
                code
                admin
            }
        }
    }
`;
