import React, { useContext } from 'react';
import { useFormikContext, Formik, FormikProps, FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import { string, object, ref } from 'yup';
import { Button, Card, CardBody, Form } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';
import { ToastContext } from 'context/ToastContext';
import createUserMutation from './mutations/createUser';

const UserForm = () => {
    const {
        values,
        touched,
        errors,

        handleSubmit,
        handleBlur,
        handleChange,

        isSubmitting
    }: FormikProps<FormikValues> = useFormikContext();

    return (
        <Form onSubmit={handleSubmit}>
            <Field
                type="text"
                placeholder="T.ex. bosse"
                label="Användarnamn"
                name="user"
                touched={touched.user}
                error={errors.user}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user}
            />
            <Field
                type="text"
                placeholder="Bosse Bilund"
                label="För- och efternamn"
                name="name"
                touched={touched.name}
                error={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
            />
            <Field
                type="text"
                placeholder="bosse@bossesbilar.se"
                label="E-post"
                name="email"
                touched={touched.email}
                error={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
            />
            <Field
                type="password"
                name="password"
                placeholder="••••••"
                label="Lösenord"
                touched={touched.password}
                error={errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="new-password"
            />
            <Field
                className="mb-4"
                name="confirm_password"
                touched={touched.confirm_password}
                value={values.confirm_password}
                error={errors.confirm_password}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                placeholder="•••••••••"
                label="Ange lösenord igen"
            />
            <Button type="submit" disabled={isSubmitting}>
                Skapa användare
            </Button>
        </Form>
    );
};

const CreateUser = (): JSX.Element => {
    const [createUser] = useMutation(createUserMutation);
    const toaster = useContext(ToastContext);

    return (
        <Formik
            initialValues={{
                user: '',
                name: '',
                email: '',
                password: '',
                confirm_password: ''
            }}
            validationSchema={object().shape({
                user: string()
                    .min(4, 'Användarnamnet måste vara minst fyra tecken långt')
                    .required('Du måste ange ett användarnamn'),
                name: string().min(4, 'Namnet måste vara minst fyra tecken långt').required('Ange användarens namn'),
                email: string().email().required('Ange en e-postadress'),
                password: string()
                    .min(6, 'Lösenordet måste vara minst sex tecken långt')
                    .matches(/^[^åäö]*$/, 'Lösenordet får inte innehålla Å, Ä eller Ö')
                    .required('Du måste fylla i ett lösenord'),
                confirm_password: string()
                    .oneOf([ref('password'), null], 'Dina lösenord matchar inte varandra')
                    .required('Skriv lösenordet igen')
            })}
            onSubmit={async (values, actions) => {
                try {
                    const { data } = await createUser({ variables: values });

                    if (data?.createUser?.status === 201) {
                        actions.resetForm();

                        return toaster.showToast(`Användaren skapades!`);
                    }
                    if (data?.createUser?.status === 409) {
                        return actions.setFieldError('user', 'Användarnamnet är upptaget');
                    }
                    toaster.showToast('Ajdå, nått gick inte bra', 'danger');
                } catch (err) {
                    toaster.showToast('Ajdå, nått gick inte bra', 'danger');
                }

                return actions.setSubmitting(false);
            }}
        >
            <Card>
                <CardBody>
                    <UserForm />
                </CardBody>
            </Card>
        </Formik>
    );
};

export default CreateUser;
