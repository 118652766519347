import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { Alert } from '@bytbil/bootstrap-react';
import { PageLoader } from '@bytbil/general-react';

import { ToastContext } from 'context/ToastContext';
import DealerGroupFormWrapper from './DealerGroupForm';

import apiGroupQuery from '../queries/apiGroup';
import updateApiGroupMutation from '../mutations/updateApiGroup';

const defaultInitialValues = {
    name: '',
    dealerIds: []
};

interface Props {
    name: string;
}

const EditGroup = ({ name }: Props) => {
    const Toaster = useContext(ToastContext);

    const [updateApiGroup] = useMutation(updateApiGroupMutation, {
        refetchQueries: ['apiGroup']
    });

    const submitHandler = async values => {
        try {
            const dealerIds = values.dealerIds.map(dealerId => parseInt(dealerId, 10));

            await updateApiGroup({
                variables: {
                    name: values.name,
                    dealerIds
                }
            });

            Toaster.showToast(`API-gruppen uppdaterades`);
        } catch (e) {
            Toaster.showToast(`Någt gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const {
        error: queryError,
        data: dealerGroupData,
        loading
    } = useQuery(apiGroupQuery, {
        variables: {
            name
        }
    });

    if (queryError) {
        return <Alert color="danger">Kunde inte hämta data för gruppen.</Alert>;
    }

    if (loading) return <PageLoader show />;

    let dealerData = { ids: [], meta: {} };
    if (dealerGroupData && dealerGroupData.apiGroup) {
        dealerData = dealerGroupData.apiGroup.dealers.reduce((acc, dealer) => {
            acc.ids.push(dealer.id);
            acc.meta[dealer.id] = dealer.name;
            return acc;
        }, dealerData);
    }

    const initialValues =
        dealerGroupData && dealerGroupData.apiGroup
            ? { name: dealerGroupData.apiGroup.name, dealerIds: dealerData.ids }
            : defaultInitialValues;

    return (
        <DealerGroupFormWrapper
            edit
            title="Uppdatera API-Grupp"
            onSubmit={submitHandler}
            dealerMeta={dealerData.meta}
            initialValues={initialValues}
            data={dealerGroupData}
        />
    );
};

export default EditGroup;
