import React from 'react';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { FormGroup, Col, Button, ButtonGroup } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

interface Props {
    onSubmit(value): void;
    className: string;
}

const CustomScopeForm = ({ onSubmit, className }: Props) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={{ scope: '', value: '', type: 'custom' }}
        validationSchema={object().shape({
            scope: string().required('Ange scope'),
            value: string().required('Ange värde')
        })}
    >
        {({ touched, values, handleChange, handleSubmit, handleBlur, errors }) => (
            <React.Fragment>
                <FormGroup className={className} row>
                    <Col>
                        <Field
                            name="scope"
                            id="scope"
                            type="text"
                            className="mb-0"
                            touched={touched.scope}
                            value={values.scope}
                            label="Scope"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.scope}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="value"
                            id="value"
                            type="text"
                            className="mb-0"
                            touched={touched.value}
                            value={values.value}
                            label="Värde"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.value}
                        />
                    </Col>
                </FormGroup>
                <ButtonGroup className="d-block mt-3">
                    <Button color="success" onClick={handleSubmit}>
                        <i className="fa fa-plus-circle fs-2" /> Lägg till
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        )}
    </Formik>
);

export default CustomScopeForm;
