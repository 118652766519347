import { Product } from 'types';

interface NewDealerProducts {
    products: Product[];
}

interface ExistingDealerProducts {
    dealerProducts: Product[];
    products: Product[];
}

const getDefaultSettingsSchemaForProduct = ({ product }) => {
    if (product.settings_schema) {
        const defaultSettings = Object.keys(product.settings_schema.properties).reduce((acc, uniqueSettingsKey) => {
            const { default: defaultValue, type } = product.settings_schema.properties[uniqueSettingsKey];

            const uniqueKey = `${product.code}_settings_${uniqueSettingsKey}`;

            let uniqueValue;

            if (type === 'boolean') {
                uniqueValue = defaultValue || false;
            }

            if (type === 'array') {
                uniqueValue = [];
            }

            acc[uniqueKey] = uniqueValue;

            return acc;
        }, {});

        return defaultSettings;
    }
    return null;
};

const getProductSettingsForNewDealer = ({ products }: NewDealerProducts) =>
    products.reduce((acc = {}, product) => {
        acc[product.code] = product.defaultActive;

        if (product.settings_schema) {
            const defaultSetttingsSchemaSettings = getDefaultSettingsSchemaForProduct({ product });

            return { ...acc, ...defaultSetttingsSchemaSettings };
        }

        return acc;
    }, {});

const getProductSettingsForExistingDealer = ({ products, dealerProducts }: ExistingDealerProducts) => {
    const theProductMap = products.reduce((acc, availableProduct) => {
        const dealerProduct = dealerProducts?.filter(product => product.code === availableProduct.code);

        // A used product can have multiple settings so we must map over each one
        // For example the product with code DCB can have settings for nextgen (boolean), payment_methods_cash (array of string)
        if (dealerProduct && dealerProduct[0]) {
            const [product] = dealerProduct;

            acc[product.code] = true;

            if (product.settings) {
                // console.log('this product is used and has settings', dealerProduct.settings);
                Object.keys(product.settings).forEach(uniqueSettingsKey => {
                    acc[`${product.code}_settings_${uniqueSettingsKey}`] = product.settings[uniqueSettingsKey];
                });
            }
        } else {
            // Formik wants default values or it will be unhappy
            const defaultSettings = getDefaultSettingsSchemaForProduct({ product: availableProduct });

            acc[availableProduct.code] = false;

            return {
                ...acc,
                ...defaultSettings
            };
        }

        return acc;
    }, {});

    return theProductMap;
};

export { getProductSettingsForNewDealer, getProductSettingsForExistingDealer };
