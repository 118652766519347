import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { Form, FormikProps, withFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Col, Row, ButtonDropdown, DropdownToggle, DropdownMenu } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

import { ToastContext } from 'context/ToastContext';

import DELETE_OFFER_MUTATION from 'features/object-history/mutations/deleteOffer';

interface FormValues {
    offerId: string;
}

interface StateProps {
    dropdownOpen: boolean;
}

const InnerForm = ({ values, touched, errors, isSubmitting, handleChange, handleBlur }: FormikProps<FormValues>) => {
    const handleFieldClick = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    const [state, setState] = useImmer<StateProps>({
        dropdownOpen: false
    });

    const toggleDeleteBtn = () => {
        setState(draft => {
            draft.dropdownOpen = !state.dropdownOpen;
        });
    };

    return (
        <Form>
            <Row>
                <Col xs="12" lg="5" xl="4">
                    <Field
                        name="offerId"
                        id="offerId"
                        type="text"
                        touched={touched.offerId}
                        value={values.offerId}
                        error={errors.offerId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onClick={handleFieldClick}
                        label="Bytbil annons-ID (OBS, fungerar ej med Blocket-ID)"
                        placeholder="13902305"
                    />
                    <Row>
                        <Col xs="12" md="auto">
                            <ButtonDropdown
                                isOpen={state.dropdownOpen}
                                toggle={toggleDeleteBtn}
                                className="btn-primary"
                            >
                                <DropdownToggle>Radera annons</DropdownToggle>
                                <DropdownMenu>
                                    <Button type="submit" color="danger" block loading={isSubmitting}>
                                        <i className="fa fa-trash mr-2" />
                                        Radera annons
                                    </Button>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

interface MyFormProps {
    handleSubmit(id: string, resetForm: () => void, setSubmitting: (isSubmitting: boolean) => void): void;
}

const MyForm = withFormik<MyFormProps, FormValues>({
    mapPropsToValues: () => ({
        offerId: ''
    }),

    validationSchema: object().shape({
        offerId: string().required('Ange annons-ID')
    }),

    handleSubmit: (values, formikBag) => {
        formikBag.props.handleSubmit(values.offerId, formikBag.resetForm, formikBag.setSubmitting);
    }
})(InnerForm);

const DeleteAd = () => {
    const Toaster = useContext(ToastContext);

    const [deleteOffer] = useMutation(DELETE_OFFER_MUTATION);

    const handleDeleteOffer = async (
        id: string,
        resetForm: () => void,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        try {
            const {
                data: {
                    deleteOffer: { success, message }
                }
            } = await deleteOffer({
                variables: { id: Number(id) }
            });

            if (success) {
                resetForm();

                return Toaster.showToast(message);
            }
            setSubmitting(false);

            return Toaster.showToast(message, 'danger');
        } catch (e) {
            setSubmitting(false);

            return Toaster.showToast('Något gick fel', 'danger');
        }
    };

    return <MyForm handleSubmit={handleDeleteOffer} />;
};

export default DeleteAd;
