import React, { useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { Button, Card, CardBody } from '@bytbil/bootstrap-react';
import { useLazyQuery, useQuery } from '@apollo/client';
import Loader from 'components/Loader/Loader';
import { useImmer } from 'use-immer';
import { string, object } from 'yup';
import { ToastContext } from 'context/ToastContext';
import UserInfo from './UserInfo';
import DealerSearch from './DealerSearch';
import DealerTable from './DealerTable';
import searchDealersQuery from '../queries/searchDealers';
import listRolesQuery from '../queries/listRoles';
import { UserData, Roles, DealerInfo } from '../types';
import SelectedDealerTable from './SelectedDealerTable';

interface GraphQLError {
    data?: {
        body: string;
    };
    message: string;
    name: string;
}
interface InitialValue {
    email: string;
    roles: string | Roles;
    search: string;
}
interface Props {
    isEdit: boolean;
    initialValues: InitialValue;
    submitLoading: boolean;
    userData?: UserData;
    stringOfIds?: string;
    arrayOfUniqueIds?: string[];
    editUser?(variables): void;
    createUser?(variables): void;
    toggleDeleteModal?(): void;
    uuid?: string;
}

interface State {
    checkedIds: string[];
    addedDealer: DealerInfo[] | [];
    searched: boolean;
}

const UsersForm = ({
    isEdit,
    initialValues,
    submitLoading,
    userData,
    arrayOfUniqueIds,
    stringOfIds,
    editUser,
    createUser,
    toggleDeleteModal,
    uuid
}: Props) => {
    const Toaster = useContext(ToastContext);
    const hasEditData = isEdit && userData;

    const [state, setState] = useImmer<State>({
        checkedIds: [],
        addedDealer: [],
        searched: false
    });

    const { data: rolesData, loading: rolesLoading } = useQuery(listRolesQuery);

    const [searchForDealers, { loading, error, data, called }] = useLazyQuery(searchDealersQuery, {
        fetchPolicy: 'cache-and-network'
    });

    const submitHandler = async (values, formikBag, mutation) => {
        const variables = {
            email: values.email,
            roles: values.roles,
            dealerIds: state.checkedIds,
            uuid: uuid && uuid
        };

        try {
            const { data: responseData } = await mutation({
                variables
            });

            if (hasEditData) {
                Toaster.showToast(`Användare ändrad`, 'success');
            } else {
                Toaster.showToast(`Användare ${responseData?.createNewUser?.detail} skapades `, 'success');
            }
            formikBag.resetForm();
        } catch (e) {
            if (e.graphQLErrors.length > 0) {
                const firstError: GraphQLError = e.graphQLErrors[0];
                const errorBody = firstError?.data?.body;

                Toaster.showToast(
                    `Kunde inte ${hasEditData ? 'spara' : 'skapa'} användaren: Felkod: ${errorBody} `,
                    'danger',
                    5000
                );
            }
        }
    };

    const searchDealer = values => {
        const variables = { search: values, searchById: false };

        searchForDealers({
            variables
        });
    };

    const selectDealers = dealerIds => {
        setState(draft => {
            draft.checkedIds = dealerIds;
        });
    };

    const rolesOption =
        rolesData?.listRoles.map(role => ({
            label: role.name,
            value: role.id
        })) || [];

    useEffect(() => {
        if (!called && arrayOfUniqueIds && arrayOfUniqueIds.length > 0) {
            searchForDealers({
                variables: { search: stringOfIds, searchById: true }
            });

            setState(draft => {
                draft.checkedIds = arrayOfUniqueIds;
            });
        }
    }, [called, searchForDealers, setState, arrayOfUniqueIds, stringOfIds]);

    const mutation = hasEditData ? editUser : createUser;

    const setDealers = dealer => {
        setState(draft => {
            draft.addedDealer = dealer;
        });
    };

    const setSearched = () => {
        setState(draft => {
            draft.searched = true;
        });
    };

    useEffect(() => {
        if (state.checkedIds.length > 0 && state.addedDealer.length < 1 && data) {
            setState(draft => {
                draft.addedDealer = data?.searchDealers;
            });
        }
    }, [state.checkedIds, state.addedDealer, data, setState]);

    return (
        <Formik
            onSubmit={(values, formikBag) => submitHandler(values, formikBag, mutation)}
            initialValues={initialValues}
            validationSchema={object().shape({
                email: string().required('Du måste fylla i en e-postadress').email('Ogiltig e-postadress'),
                roles: string().required('Du måste välja en roll')
            })}
            enableReinitialize
        >
            {({ errors, touched, values, handleChange, handleBlur, handleReset, setFieldValue, handleSubmit }) => (
                <>
                    <UserInfo
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleReset={handleReset}
                        rolesOption={rolesOption}
                        rolesLoading={rolesLoading}
                    />
                    <Card className="mt-2">
                        <DealerSearch
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            searchDealer={searchDealer}
                            setSearched={setSearched}
                            searched={state.searched}
                        />
                        {state.searched && data && (
                            <DealerTable
                                data={data.searchDealers}
                                checkedIds={state.checkedIds}
                                setDealers={setDealers}
                                selectedDealers={state.addedDealer}
                                selectDealers={selectDealers}
                            />
                        )}
                        {state.searched && loading && !error && (
                            <Card className="mt-2">
                                <CardBody>
                                    <Loader autocenter size={2} />
                                </CardBody>
                            </Card>
                        )}
                    </Card>
                    {state.checkedIds && (
                        <SelectedDealerTable
                            checkedIds={state.checkedIds}
                            selectDealers={selectDealers}
                            selectedDealers={state.addedDealer}
                            setDealers={setDealers}
                        />
                    )}
                    <div className="d-flex justify-content-between mb-3">
                        <Button
                            color="primary"
                            loading={submitLoading}
                            className="mt-2"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Spara användare
                        </Button>
                        {hasEditData && (
                            <Button color="danger" className="mt-2" type="submit" onClick={toggleDeleteModal}>
                                <i className="fa fa-trash mr-1" />
                                Ta bort användare
                            </Button>
                        )}
                    </div>
                </>
            )}
        </Formik>
    );
};

export default UsersForm;
