import React from 'react';
import { Table } from '@bytbil/bootstrap-react';
import ApiTokenRow from './ApiTokenRow';

import { Scope } from '../types';

interface ApiToken {
    id: string;
    scopes: Scope[];
    expiration: string;
    jti: string;
}

interface Props {
    apiTokens: ApiToken[];
    onRegenerate(regenerationSubject: string): void;
    onDelete(deletionSubject: string): void;
}

const ApiTokenTable = React.memo(({ apiTokens, onRegenerate, onDelete }: Props) => (
    <Table responsive bordered className="mb-0">
        <thead>
            <tr>
                <th>Tillgångsnivå</th>
                <th>JTI</th>
                <th>Expiration</th>
                <th className="text-center">
                    <i className="fa fa-refresh" />
                </th>
                <th className="text-center">
                    <i className="fa fa-trash" />
                </th>
            </tr>
        </thead>
        <tbody>
            {apiTokens.map(({ id, jti, expiration, scopes }) => (
                <ApiTokenRow
                    key={id}
                    jti={jti}
                    expiration={expiration}
                    scopes={scopes}
                    onRegenerate={onRegenerate}
                    onDelete={onDelete}
                />
            ))}
        </tbody>
    </Table>
));

export default ApiTokenTable;
