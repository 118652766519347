import gql from 'graphql-tag';

export default gql`
    query getDealerGroup($code: String!) {
        dealerGroupByCode(code: $code) {
            id
            code
        }
    }
`;
