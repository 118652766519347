import React, { useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { useMutation } from '@apollo/client';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Tooltip } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import deleteOfferMutation from '../../object-history/mutations/deleteOffer';

interface Props {
    offerId: number;
    offerName: string;
    offerRegnr: string;
    refetch: () => void;
}

interface StateProps {
    modalOpen: boolean;
}

interface ModalProps {
    isOpen: boolean;
    loading: boolean;
    toggle: () => void;
    handleDelete: () => void;
    offerName: string;
    offerRegnr: string;
}

const DeleteModal = ({ isOpen, loading, toggle, handleDelete, offerName, offerRegnr }: ModalProps) => (
    <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader>Ta bort annons</ModalHeader>
        <ModalBody>
            <Table bordered>
                <tbody>
                    <tr>
                        <td>{offerRegnr}</td>
                        <td> {offerName}</td>
                    </tr>
                </tbody>
            </Table>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle} loading={loading}>
                Avbryt
            </Button>
            <Button color="danger" onClick={handleDelete} loading={loading}>
                <i className="fa fa-trash mr-2" />
                Ta bort
            </Button>
        </ModalFooter>
    </Modal>
);

const DeleteOfferBtn = ({ offerId, offerName, offerRegnr, refetch }: Props) => {
    const [state, setState] = useImmer<StateProps>({
        modalOpen: false
    });
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    const Toaster = useContext(ToastContext);

    const [deleteOffer, { loading }] = useMutation(deleteOfferMutation);

    const toggleModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    const handleDelete = async () => {
        try {
            const { data } = await deleteOffer({
                variables: { id: offerId }
            });

            if (data?.deleteOffer?.success) {
                Toaster.showToast(`Annonsen har tagits bort`, 'success');

                refetch();
            } else {
                Toaster.showToast(`Något gick fel, försök igen om en stund`, 'danger', 5000);
            }
        } catch (e) {
            Toaster.showToast(`Kunde inte radera annonsen: Felkod: ${e}`, 'danger', 5000);
        }
        toggleModal();
    };

    return (
        <>
            <DeleteModal
                isOpen={state.modalOpen}
                toggle={toggleModal}
                handleDelete={handleDelete}
                loading={loading}
                offerName={offerName}
                offerRegnr={offerRegnr}
            />
            <Button className="hover-icon m-0 p-0" onClick={toggleModal} color="link">
                <i className="fa fa-trash text-dark" id={`removeAdd-${offerId}`} />
                <Tooltip placement="bottom" isOpen={tooltipOpen} target={`removeAdd-${offerId}`} toggle={toggleTooltip}>
                    Ta bort annons
                </Tooltip>
            </Button>
        </>
    );
};

export default DeleteOfferBtn;
