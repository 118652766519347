import React from 'react';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { FormGroup, Button, ButtonGroup } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

interface Props {
    onSubmit(value): void;
    className: string;
}

const VehicleScopeForm = ({ onSubmit, className }: Props) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={{ dealerGroup: '', type: 'vehicles', scope: 'getVehicles' }}
        validationSchema={object().shape({
            dealerGroup: string()
        })}
    >
        {({ touched, values, handleChange, handleSubmit, handleBlur, errors }) => (
            <React.Fragment>
                <FormGroup className={className}>
                    <Field
                        name="dealerGroup"
                        id="dealerGroup"
                        type="text"
                        className="mb-0"
                        placeholder="bytbiltestgrupp"
                        touched={touched.dealerGroup}
                        value={values.dealerGroup}
                        label="Grupp - Lämna tom för att välja alla"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.dealerGroup}
                    />
                </FormGroup>
                <ButtonGroup className="d-block mt-3">
                    <Button color="success" type="submit" onClick={handleSubmit}>
                        <i className="fa fa-plus-circle fs-2" /> Lägg till
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        )}
    </Formik>
);

export default VehicleScopeForm;
