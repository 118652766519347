import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';

const propTypes = {
    userId: PropTypes.string.isRequired,
    homedir: PropTypes.string,
    externalId: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired
};

const defaultProps = {
    homedir: ''
};

const FtpUserRow = React.memo(({ userId, homedir, externalId, onDelete }) => (
    <React.Fragment>
        <td>
            <Link to={`/ftp-user/edit/${userId}`}>{userId}</Link>
        </td>
        <td>{homedir}</td>
        <td>{externalId}</td>
        <td>
            <Button onClick={() => onDelete(userId)}>Ta bort</Button>
        </td>
    </React.Fragment>
));

FtpUserRow.propTypes = propTypes;
FtpUserRow.defaultProps = defaultProps;

export default FtpUserRow;
