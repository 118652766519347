import React from 'react';
import { Link } from '@reach/router';

interface Props {
    id: string;
    children: React.ReactNode;
    className?: string;
    dealerId: number;
}

const TabLink = ({ id, children, className = '', dealerId }: Props) => (
    <Link to={`/dealer/${dealerId}/${id}`}>
        <div className={className}>{children}</div>
    </Link>
);
export default TabLink;
