import React from 'react';
import format from 'date-fns/format';
import { Button } from '@bytbil/bootstrap-react';

import { Scope } from '../types';

interface Props {
    jti: string;
    expiration?: string;
    scopes: Scope[];
    onRegenerate(jti: string): void;
    onDelete(jti: string): void;
}

const scopeToBadge = scope => {
    if (scope.name === 'isAdmin' && scope.value === 'true') {
        return (
            <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
                Fulla administratörsrättigheter
            </div>
        );
    }
    if (scope.name === 'get.vehicles') {
        if (scope.value === '_all') {
            return (
                <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
                    Fulla läsrättigheter för fordon
                </div>
            );
        }
        return (
            <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
                Läsrättigheter för fordon i DealerGroup: {scope.value}
            </div>
        );
    }

    if (scope.name === 'get.statistics.daterange') {
        const period = JSON.parse(scope.value);

        return (
            <div className="d-block fs-1" key={`${scope.name}:${scope.value}`}>
                {format(period.current.start, 'YYYY-MM-DD')} - {format(period.current.stop, 'YYYY-MM-DD')} jämfört med{' '}
                {format(period.comparison.start, 'YYYY-MM-DD')} - {format(period.comparison.stop, 'YYYY-MM-DD')}
            </div>
        );
    }

    if (scope.name === 'get.statistics.dealergroups') {
        return (
            <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
                Statistik för DealerGroup: {scope.value}
            </div>
        );
    }

    if (scope.name === 'get.statistics.dealer_ids') {
        return (
            <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
                Statistik för handlar-id: {JSON.parse(scope.value).join(', ')}
            </div>
        );
    }

    return (
        <div className="d-block mb-1" key={`${scope.name}:${scope.value}`}>
            {scope.name}: {scope.value}
        </div>
    );
};

const ApiTokenRow = React.memo(({ jti, expiration, scopes, onRegenerate, onDelete }: Props) => (
    <tr>
        <td>{scopes && scopes.map(scopeToBadge)}</td>
        <td>{jti}</td>
        <td>{expiration ? format(expiration, 'YYYY-MM-DD HH:mm') : '-'}</td>
        <td>
            <Button color="success" onClick={() => onRegenerate(jti)}>
                Generera ny nyckel
            </Button>
        </td>
        <td>
            <Button color="danger" onClick={() => onDelete(jti)}>
                Ta bort nyckel
            </Button>
        </td>
    </tr>
));

export default ApiTokenRow;
