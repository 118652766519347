import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Col, Row, Alert } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

const propTypes = {
    values: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        dealercode: PropTypes.bool
    }),
    error: PropTypes.string,
    success: PropTypes.bool,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired
};

const defaultProps = {
    success: undefined,
    touched: undefined
};

const UserNameForm = React.memo(props => {
    const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, error, success } = props;

    return (
        <Form onSubmit={handleSubmit} noValidate name="UserNameForm">
            <Row>
                <Col xs="12" lg="5" xl="4">
                    <Field
                        label="Användarnamn"
                        type="text"
                        name="username"
                        placeholder="testuser01"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.username}
                        value={values.username}
                        error={errors.username}
                    />
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12" md="auto">
                            <Button type="submit" color="danger" block loading={isSubmitting}>
                                Nollställ
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="mt-3">
                {(error || success === false) && (
                    <Alert color="danger">Något gick fel, användaren kanske inte längre är aktiverad.</Alert>
                )}
                {success && <Alert color="success">TOTP nollställt för användaren</Alert>}
            </div>
        </Form>
    );
});

UserNameForm.propTypes = propTypes;
UserNameForm.defaultProps = defaultProps;

export default UserNameForm;
