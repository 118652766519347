import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@bytbil/bootstrap-react';

const propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

const formatErrorMessage = error => {
    if (error === 'Unauthorized') {
        return 'Fel användarnamn eller lösenord';
    }

    if (error === 'Invalid secret') {
        return 'Fel verifiktionskod';
    }

    if (error === 'Network error') {
        return 'Kunde inte ansluta till servern';
    }

    return 'Något gick fel. Prova igen om en liten stund';
};

const DisplayError = React.memo(props => {
    const { errors } = props;

    if (errors.length === 0) {
        return null;
    }

    return errors.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert color="danger" key={index}>
            <span>{formatErrorMessage(error)}</span>
        </Alert>
    ));
});

DisplayError.propTypes = propTypes;

export default DisplayError;
