import React from 'react';
import { useQuery } from '@apollo/client';

import { Row, Col, Card, CardBody } from '@bytbil/bootstrap-react';

import Imports from 'features/imports';
import AdEvents from 'features/ad-events';
import DealerFtp from 'features/ftp-user/containers/dealerFtp';

import DealerApi from 'features/dealer-api';

import DEALER_META_QUERY from 'features/dealer-api/queries/editDealer';

import DealerHeader from 'features/dealer/DealerHeader';
import Stocklist from 'features/stocklist';
import ListDealerUsers from 'features/users/ListDealerUsers';

import Tabs from './Tabs';

interface Props {
    activeTab: 'stocklist' | 'edit' | 'import' | 'ftp-user' | 'offer-history' | 'offer' | 'users';
    id: string;
}
interface DealerBodyWrapperProps {
    children: React.ReactNode;
    activeTab: 'stocklist' | 'edit' | 'import' | 'ftp-user' | 'offer-history' | 'offer' | 'users';
    dealerId: number;
}

const DealerBodyWrapper = ({ children, activeTab, dealerId }: DealerBodyWrapperProps) => (
    <Row>
        <Col xs="12" className="mb-5">
            <Tabs activeTab={activeTab} dealerId={dealerId} />
            <Card className="tab-content-card mb-5">
                <CardBody>{children}</CardBody>
            </Card>
        </Col>
    </Row>
);

const Dealer = ({ activeTab, id }: Props) => {
    const { loading, error, data } = useQuery(DEALER_META_QUERY, {
        variables: {
            id: parseInt(id, 10)
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading && !data) {
        return <p>Laddar...</p>;
    }
    if (error) {
        return <p>Något gick fel...</p>;
    }

    const { dealerMeta } = data;

    const dealerIdAsNumber = parseInt(id, 10);

    return (
        <>
            <DealerHeader
                dealerName={dealerMeta.name}
                dealerId={dealerMeta.id}
                dealerCode={dealerMeta.code}
                dealerStoreCode={dealerMeta.blocketStoreId}
            />
            <DealerBodyWrapper activeTab={activeTab} dealerId={dealerMeta.id}>
                {activeTab === 'stocklist' && <Stocklist id={dealerIdAsNumber} dealerCode={dealerMeta.code} />}
                {activeTab === 'edit' && <DealerApi dealerId={dealerIdAsNumber} />}
                {activeTab === 'import' && <Imports dealerId={dealerIdAsNumber} />}
                {activeTab === 'offer-history' && <AdEvents dealerId={dealerIdAsNumber} />}
                {activeTab === 'ftp-user' && <DealerFtp dealerId={dealerIdAsNumber} dealerCode={dealerMeta.code} />}
                {activeTab === 'users' && <ListDealerUsers dealerId={id} />}
            </DealerBodyWrapper>
        </>
    );
};

export default Dealer;
