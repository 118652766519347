import gql from 'graphql-tag';

export default gql`
    query getBrand($id: String!) {
        getBrand(id: $id) {
            id
            name
            slug
            approved
            matches
        }
    }
`;
