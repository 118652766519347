import gql from 'graphql-tag';

export default gql`
    mutation createApiGroup($name: String!, $dealerIds: [Int!]!) {
        createApiGroup(data: { name: $name, dealerIds: $dealerIds }) {
            name
            dealers {
                id
                name
            }
        }
    }
`;
