import React from 'react';
import DealerPrivacy from 'features/dealer-privacy';
import Helmet from 'react-helmet-async';
import MainContainer from 'components/MainContainer';

const action = () => (
    <React.Fragment>
        <Helmet>
            <title>Privacy</title>
        </Helmet>
        <MainContainer>
            <DealerPrivacy />
        </MainContainer>
    </React.Fragment>
);

export default action;
