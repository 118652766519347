import React from 'react';
import { Form, FormikProps, FormikValues } from 'formik';
import { useImmer } from 'use-immer';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@bytbil/bootstrap-react';
import { Field, Select, MultipleValueInput, Radio } from '@bytbil/general-react';
import { OptionType } from 'types';
import DeleteModal from './DeleteModal';

interface Props {
    modalOpen: boolean;
    id: string;
    title: string;
    vehicleTypeOptions?: OptionType[];
    approvedOptions?: OptionType[];
    edit: boolean;
    isBrand?: boolean;
    isApprovedPending: boolean;
    toggle(): void;
}

interface State {
    deleteModalOpen: boolean;
    fade: boolean;
}

const ModalForm = (props: Props & FormikProps<FormikValues>) => {
    const {
        modalOpen,
        edit,
        id,
        vehicleTypeOptions,
        approvedOptions,
        title,
        isBrand,
        isApprovedPending,
        toggle,
        values,
        errors,
        isSubmitting,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm
    } = props;

    const [state, setState] = useImmer<State>({
        deleteModalOpen: false,
        fade: true
    });

    const submitButtonText = edit ? 'Spara' : 'Lägg till';
    const deleteButtonText = isBrand ? 'Ta bort märke' : 'Ta bort modell';
    const label = isBrand ? 'Märke' : 'Modell';
    const placeholder = isBrand ? 't.ex. Volvo' : 't.ex. V70';

    const toggleDeleteModal = () => {
        setState(draft => {
            draft.fade = false;
            draft.deleteModalOpen = !state.deleteModalOpen;
        });
    };

    const changeHandler = (key: string) => (value: string) => {
        setFieldValue(key, value);
    };

    const closeModal = () => {
        resetForm();
        toggle();
    };

    return (
        <>
            <Modal
                isOpen={modalOpen}
                toggle={closeModal}
                centered
                fade={state.fade}
                className={state.deleteModalOpen ? 'invisible' : ''}
            >
                <ModalHeader toggle={closeModal}>{title}</ModalHeader>
                <Form onSubmit={handleSubmit} name="FormModal">
                    <ModalBody className="mb-3">
                        <Field
                            type="text"
                            name="name"
                            label={label}
                            placeholder={placeholder}
                            touched={touched.name}
                            error={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            disabled={isApprovedPending}
                        />
                        {edit && (
                            <Field
                                type="text"
                                name="slug"
                                label="ID"
                                touched={touched.slug}
                                error={errors.slug}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.slug}
                            />
                        )}
                        {vehicleTypeOptions && (
                            <Select
                                touched={touched.vehicleType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.vehicleType}
                                value={values.vehicleType}
                                options={vehicleTypeOptions}
                                id={`vehicleType-${values.vehicleType}`}
                                name="vehicleType"
                                label="Fordonstyp"
                                disabled={isApprovedPending}
                            />
                        )}

                        <MultipleValueInput
                            name="matches"
                            value={values.matches}
                            label="Liknande träffar"
                            onChange={changeHandler}
                        />

                        {edit && (
                            <Radio
                                name="approved"
                                touched={touched.approved}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.approved}
                                value={values.approved}
                                id="approved"
                                options={approvedOptions}
                                label="Status"
                            />
                        )}
                    </ModalBody>
                    <ModalFooter className={`${edit ? 'justify-content-between' : 'justify-content-end'}`}>
                        {edit && (
                            <Button onClick={() => toggleDeleteModal()} disabled={isSubmitting}>
                                {deleteButtonText}
                            </Button>
                        )}

                        <div>
                            <Button onClick={closeModal} disabled={isSubmitting} className="mr-2">
                                Avbryt
                            </Button>
                            <Button type="submit" color="success" loading={isSubmitting}>
                                {submitButtonText}
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
            <DeleteModal
                isOpen={state.deleteModalOpen}
                toggle={toggleDeleteModal}
                name={values.name}
                id={id}
                isBrand={isBrand}
            />
        </>
    );
};

export default ModalForm;
