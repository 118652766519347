import React from 'react';
import { FormikProps, FormikValues, withFormik } from 'formik';
import { object, string, array, number } from 'yup';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import PageLayout from 'components/PageLayout';
import ApiGroupForm from '../components/ApiGroupForm';

import { ApiGroup } from '../types';

const validationSchema = object().shape({
    name: string()
        .matches(/^[a-zA-Z0-9-_]+$/, 'Handlargruppen får bara innehålla bokstäver, siffror eller understreck')
        .required('Ange gruppnamn'),
    dealerIds: array().of(number().integer()).required('Ange anläggnings-idn')
});

interface Props extends FormikProps<FormikValues> {
    edit: boolean;
    title: string;
    data: ApiGroup | undefined;
}

const DealerGroup = (props: Props) => {
    const { title, edit, data } = props;

    return (
        <PageLayout title={title}>
            <Card>
                <CardBody>
                    <ApiGroupForm data={data} edit={edit} />
                </CardBody>
            </Card>
        </PageLayout>
    );
};

interface MyFormProps {
    edit: boolean;
    title: string;
    onSubmit(values: FormikValues): void;
    initialValues: {
        name: string;
        dealerIds: number[];
    };

    dealerMeta?: any;
    data: ApiGroup | undefined;
}

const EnhancedDealerGroupForm = withFormik<MyFormProps, FormikValues>({
    enableReinitialize: true,

    mapPropsToValues: ({ initialValues }) => ({
        ...initialValues
    }),

    validationSchema,

    handleSubmit: async (values: FormikValues, { props }) => props.onSubmit(values),

    displayName: 'CreateApiTokenForm'
})(DealerGroup);

export default EnhancedDealerGroupForm;
