import React from 'react';

import { Table } from '@bytbil/bootstrap-react';

import UserRow from './UserRow';

import { useUsers } from '../ListUsersProvider';

const UsersTable = () => {
    const { users } = useUsers();

    if (!users) {
        return null;
    }

    return (
        <Table responsive bordered className="mb-0">
            <thead>
                <tr>
                    <th>Användare</th>
                    <th>Roll</th>
                    <th>Status</th>
                    <th />
                    <th>Administrera</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <UserRow key={user.id} user={user} />
                ))}
            </tbody>
        </Table>
    );
};

export default UsersTable;
