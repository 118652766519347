import React from 'react';
import classNames from 'classnames';

import { Nav, NavItem } from '@bytbil/bootstrap-react';

import TabLink from './TabLink';

interface Props {
    activeTab: string | undefined;
    dealerId: number;
}

const tabItems = [
    {
        name: 'Annonser',
        id: 'stocklist'
    },
    {
        name: 'Ändra handlare',
        id: 'edit'
    },
    {
        name: 'Importstatus',
        id: 'import'
    },
    {
        name: 'Annonshistorik',
        id: 'offer-history'
    },
    {
        name: 'FTP-konto',
        id: 'ftp-user'
    },
    {
        name: 'Användare',
        id: 'users'
    }
];

const Tabs = ({ activeTab, dealerId }: Props) => (
    <Nav tabs>
        {tabItems.map(item => (
            <NavItem key={item.id}>
                <TabLink
                    className={classNames(
                        'nav-link',
                        activeTab === item.id && 'active',
                        activeTab === undefined && item.id === 'stocklist' && 'active'
                    )}
                    id={item.id}
                    dealerId={dealerId}
                >
                    {item.name}
                </TabLink>
            </NavItem>
        ))}
    </Nav>
);

export default Tabs;
