import React, { useEffect } from 'react';
import { withFormik, FormikProps, FormikValues, Form } from 'formik';
import { Row, Col } from '@bytbil/bootstrap-react';
import { Radio, Field } from '@bytbil/general-react';

const options = [
    { label: 'Grupp', value: 'group' },
    { label: 'Handlar-ID', value: 'id' },
    { label: 'Handlarkod', value: 'code' }
];

interface OtherProps {
    searchForDealerGroup(values): void;
}

const DealerGroupsSearchForm = (props: OtherProps & FormikProps<FormikValues>) => {
    const { values, errors, handleChange, handleBlur, touched, searchForDealerGroup } = props;

    useEffect(() => {
        searchForDealerGroup(values);
    }, [values]);

    return (
        <Form noValidate name="DealerGroupsSearchForm">
            <Row>
                <Col xs="12" className="filter-form-limit-filter">
                    <Radio
                        name="category"
                        toggleButton
                        label="Alternativ"
                        options={options}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.category}
                    />
                </Col>
                <Col xs="12" lg="5" xl="4">
                    <Field
                        type="text"
                        name="query"
                        label="Sök"
                        touched={touched.query}
                        error={errors.query}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.query}
                    />
                </Col>
            </Row>
        </Form>
    );
};

interface MyFormProps {
    searchForDealerGroup(values): void;
}

interface FormValues {
    category: string;
    query: string;
}

const EnhancedDealerGroupsSearchForm = withFormik<MyFormProps, FormValues>({
    enableReinitialize: true,

    mapPropsToValues: () => ({
        category: 'group',
        query: ''
    }),

    handleSubmit: () => {},

    displayName: 'DealerGroupForm'
})(DealerGroupsSearchForm);

export default EnhancedDealerGroupsSearchForm;
