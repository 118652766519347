import gql from 'graphql-tag';

export default gql`
    query ftpUser($userid: String!) {
        ftpUser(id: $userid) {
            userid
            homedir
            external_id
        }
    }
`;
