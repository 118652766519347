import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader/Loader';
import { useImmer } from 'use-immer';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';

import UserForm from './components/UserForm';

import getUserQuery from './queries/getUser';
import deleteUserMutation from './mutations/deleteUser';
import editUserMutation from './mutations/editUser';

interface Props {
    uuid: string;
}

interface State {
    modalOpen: boolean;
}

const EditUser = ({ uuid }: Props) => {
    const [state, setState] = useImmer<State>({
        modalOpen: false
    });
    const Toaster = useContext(ToastContext);

    const { error, data, loading } = useQuery(getUserQuery, {
        variables: {
            id: uuid
        }
    });

    const ToggleDeleteModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    const [deleteUser, meta] = useMutation(deleteUserMutation, {
        onCompleted: () => {
            Toaster.showToast(`Användare borttagen`, 'success');

            ToggleDeleteModal();

            navigate('/users');
        },
        onError: () => {
            Toaster.showToast(`Något gick fel `, 'danger', 5000);
        }
    });

    const [editUser, { loading: editUserLoading, data: editUserData }] = useMutation(editUserMutation, {
        onCompleted: () => {
            Toaster.showToast(`Användare ändrad`, 'success');
        },
        onError: () => {
            Toaster.showToast(`Något gick fel `, 'danger', 5000);
        }
    });

    const handleDelete = async userId => {
        deleteUser({
            variables: {
                uuid: userId
            }
        });
    };

    const arrayOfUniqueIds = data?.getUser.dealers;

    const stringOfIds = arrayOfUniqueIds && arrayOfUniqueIds.length > 0 ? arrayOfUniqueIds.join(',') : [];

    const initialValues = {
        email: data?.getUser.email,
        roles:
            (editUserData && editUserData.updateUser && editUserData.updateUser.roles.map(role => role.id)[0]) ||
            data?.getUser.roles.map(role => role.id)[0],
        search: '',
        firstName: data?.getUser.firstName,
        lastName: data?.getUser.lastName
    };

    if (loading && !error) {
        return <Loader autocenter size={2} />;
    }

    return (
        !error &&
        data && (
            <PageLayout title="Ändra användare" backBtn>
                <UserForm
                    isEdit
                    initialValues={initialValues}
                    submitLoading={editUserLoading}
                    userData={data.getUser}
                    arrayOfUniqueIds={arrayOfUniqueIds}
                    stringOfIds={stringOfIds}
                    editUser={editUser}
                    toggleDeleteModal={ToggleDeleteModal}
                    uuid={uuid}
                />

                <Modal isOpen={state.modalOpen} toggle={ToggleDeleteModal}>
                    <ModalHeader toggle={ToggleDeleteModal}>Ta bort användare</ModalHeader>
                    <ModalBody>
                        <p>
                            Är du säker på att du vill ta bort <strong>{data.getUser.email}</strong>?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={meta.loading}
                            onClick={e => {
                                e.preventDefault();

                                handleDelete(data.getUser.id);
                            }}
                        >
                            <i className="fa fa-trash mr-1" />
                            Ta bort
                        </Button>
                    </ModalFooter>
                </Modal>
            </PageLayout>
        )
    );
};

export default EditUser;
