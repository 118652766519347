import React, { useEffect, useContext } from 'react';
import { object, string } from 'yup';
import { useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Link } from '@reach/router';

import onGraphQLError from 'core/onGraphQLError';

import { Context } from 'core/Store/Store';

import { Alert, Card, CardBody, Button } from '@bytbil/bootstrap-react';

import PageLayout from 'components/PageLayout';
import DealerSearchForm from './components/DealerSearchForm';
import DealerTable from './components/DealerTable';

import searchDealersQuery from './queries/searchDealers';

const DealerSearch = () => {
    let errorMessage;

    const {
        state: { dealerSearch },
        dispatch
    } = useContext(Context);

    const [searchForDealers, { loading, error, data, called }] = useLazyQuery(searchDealersQuery, {
        fetchPolicy: 'cache-first',
        onCompleted: () => dispatch({ type: 'DEALER_SEARCH_CALLED', called: true })
    });

    if (error) {
        errorMessage = onGraphQLError(error);
    }

    useEffect(() => {
        if (!called && dealerSearch.called) {
            searchForDealers({
                variables: { search: dealerSearch.value }
            });
        }
    }, [called, dealerSearch, searchForDealers]);

    return (
        <PageLayout title="Sök handlare">
            <div className="d-flex justify-content-between mb-3">
                <p>Du kan söka på handlarkod, företagsnamn, org.nummer, handlar-ID, butiks-ID eller handlargrupp</p>
                <Link to="/dealer/add">
                    <Button color="success">
                        <i className="fa fa-plus mr-1" />
                        Lägg till handlare
                    </Button>
                </Link>
            </div>
            <Card>
                <CardBody>
                    <Formik
                        onSubmit={values => {
                            searchForDealers({
                                variables: { search: values.search }
                            });
                        }}
                        validationSchema={object().shape({
                            search: string()
                                .min(2, 'Skriv minst två tecken')
                                .required('Skriv minst två tecken för att söka')
                        })}
                        initialValues={{
                            active: dealerSearch.active,
                            search: dealerSearch.value,
                            category: 'name'
                        }}
                    >
                        {props => <DealerSearchForm {...props} />}
                    </Formik>
                </CardBody>
            </Card>

            {error && (
                <Alert color="danger" className="mt-3 mt-0 d-inline-block">
                    <i className="fa fa-exclamation-triangle mr-2" />
                    {errorMessage}
                </Alert>
            )}

            {called && !error && (
                <Card className="mt-3">
                    <CardBody>
                        <DealerTable
                            called={called}
                            loading={loading}
                            dealers={data?.searchDealers}
                            filterInactive={dealerSearch.active}
                        />
                    </CardBody>
                </Card>
            )}
        </PageLayout>
    );
};

export default DealerSearch;
