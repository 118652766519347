import gql from 'graphql-tag';

export default gql`
    query apiGroup($name: String!) {
        apiGroup(name: $name) {
            name
            dealers {
                id
                name
            }
        }
    }
`;
