import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { Card, CardBody, Alert, Button, Row, Col } from '@bytbil/bootstrap-react';
import { useQuery } from '@apollo/client';

import Loader from 'components/Loader/Loader';
import { SORT_NAME_ASC } from 'core/constants/common';
import ModelContainer from '../containers/ModelContainer';
import listModels from '../queries/listModels';
import { Model } from '../types';
import { onQuery } from '../helpers';

import ModelsTable from './ModelsTable';
import FilterForm from './common/FilterForm';

interface State {
    models: Model[];
    modalOpen: boolean;
}

interface Props {
    brandId: string;
}

const Models = ({ brandId }: Props) => {
    const { data, error, loading, refetch } = useQuery(listModels, {
        variables: { brandId, ordering: SORT_NAME_ASC },
        fetchPolicy: 'cache-and-network'
    });

    // to be removed when BE has updated the value in field <vehicletype> (in model), from uuid to slug.
    const { data: oneModel } = useQuery(listModels, {
        variables: { limit: 1 },
        fetchPolicy: 'cache-and-network'
    });
    const UUID_LENGTH = 36;
    const useSlugAsVehicleTypeValue = oneModel?.listModels?.[0]?.vehicleTypeId?.length < UUID_LENGTH;
    // to here + useSlugAsVehicleTypeValue in other files

    const [state, setState] = useImmer<State>({
        models: [],
        modalOpen: false
    });

    const hasModels = !!state.models.length;

    const filterOnQuery = ({ query }: { query: string }) => {
        const models = data?.listModels?.filter(onQuery(query)) || state.models;
        setState(draft => {
            draft.models = models;
        });
    };

    useEffect(() => {
        if (data?.listModels && !loading) {
            setState(draft => {
                draft.models = data.listModels;
            });
        }
    }, [data?.listModels, loading, setState]);

    const toggleModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };
    return (
        <Card>
            <CardBody>
                <Row className="align-items-end mb-3">
                    <Col>
                        <FilterForm
                            name="ModelsFilterForm"
                            placeholder="Modell, Fordonstyp eller Liknande träffar"
                            filterOnQuery={filterOnQuery}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button color="success" onClick={() => toggleModal()}>
                            Lägg till modell
                        </Button>
                    </Col>
                    <ModelContainer
                        brandId={brandId}
                        modalOpen={state.modalOpen}
                        toggle={toggleModal}
                        refetchListModels={refetch}
                        useSlugAsVehicleTypeValue={useSlugAsVehicleTypeValue}
                    />
                </Row>

                {loading && <Loader size={3} autocenter className="my-4" />}

                {!loading && !error && (
                    <>
                        {hasModels ? (
                            <ModelsTable
                                models={state.models}
                                refetchListModels={refetch}
                                useSlugAsVehicleTypeValue={useSlugAsVehicleTypeValue}
                            />
                        ) : (
                            <Alert color="warning">Hittade inga modeller</Alert>
                        )}
                    </>
                )}

                {!loading && error && (
                    <Alert color="danger" className="mt-3">
                        Något gick fel, försök igen om en stund.
                    </Alert>
                )}
            </CardBody>
        </Card>
    );
};

export default Models;
