import React from 'react';

import { useFormikContext, FormikProps, FormikValues } from 'formik';

import { Field, MultipleValueInput, Select, Toggle } from '@bytbil/general-react';

import { Product, Setting } from '../../../../types';

import END_DATE_PRODUCTS from './endDateProducts';

const ProductSetting = ({ productSettings, code, name, type }: Setting) => {
    const { values, handleChange, handleBlur, setFieldTouched, setFieldValue }: FormikProps<FormikValues> =
        useFormikContext();

    const blurHandler = key => () => {
        // this is going to call setFieldTouched and manually update touched[key]
        setFieldTouched(key, true);
    };

    const changeHandler = key => value => {
        // this is going to call setFieldValue and manually update values[key]
        setFieldValue(key, value);
    };

    const mapEnumTypesForSelect = items => items.map(item => ({ label: item, value: item, name: item }));

    const settingsId = `${code}_settings_${name}`;

    if (type === 'boolean') {
        return (
            <Toggle
                id={settingsId}
                name={settingsId}
                description={productSettings.description || name}
                value={values[`${settingsId}`] || false}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }

    if (type === 'string') {
        return (
            <Field
                id={settingsId}
                name={settingsId}
                type="text"
                placeholder={name}
                label={productSettings.description || name}
                value={values[`${settingsId}`]}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                disabled={productSettings.readOnly || false}
            />
        );
    }

    if (type === 'integer') {
        return (
            <Field
                id={settingsId}
                name={settingsId}
                type="number"
                placeholder={name}
                label={productSettings.description || name}
                value={values[`${settingsId}`]}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                disabled={productSettings.readOnly || false}
            />
        );
    }

    if (type === 'array') {
        // TEMP ENUM, WAIT FOR DEALER-API UPDATE AND CHANGE
        if (productSettings?.items?.type === 'string') {
            return (
                <MultipleValueInput
                    label={productSettings.description || name}
                    value={values[`${settingsId}`]}
                    name={settingsId}
                    onChange={changeHandler}
                    onBlur={blurHandler}
                    placeholder={name}
                />
            );
        }
    }

    if (type === 'array') {
        if (productSettings?.items?.enum) {
            return (
                <Select
                    multiple
                    name={settingsId}
                    label={productSettings.description || name}
                    className="mb-3"
                    placeholder="Välj"
                    value={values[`${settingsId}`]}
                    onChange={changeHandler(settingsId)}
                    onBlur={blurHandler(settingsId)}
                    options={mapEnumTypesForSelect(productSettings.items.enum)}
                />
            );
        }
    }

    return <div>Inga inställningar för {type} hittades</div>;
};

interface EndDateProps {
    code: string;
}

const ProductEndDate = ({ code }: EndDateProps) => {
    const { values, handleChange, handleBlur }: FormikProps<FormikValues> = useFormikContext();

    return (
        <Field
            id={`${code}_end_date`}
            name={`${code}_end_date`}
            type="date"
            label="Slutdatum"
            value={values[`${code}_end_date`] || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            text="Här kan ett slutdatum för produkten ställas in. Ett historisk datum är detsamma som att slå av produkten."
        />
    );
};

const ProductTag = ({ settings_schema: settingsSchema, code }: Product) => {
    let productSettingsNode: React.ReactNode;
    let endDateNode: React.ReactNode;
    const fullSettingsNode: React.ReactNodeArray = [];

    const requiresEndDateField = END_DATE_PRODUCTS.includes(code);

    if (!settingsSchema?.properties && !requiresEndDateField) {
        return null;
    }

    if (requiresEndDateField) {
        endDateNode = (
            <div key={`endDate-${code}`}>
                <ProductEndDate code={code} />
            </div>
        );

        fullSettingsNode.push(endDateNode);
    }

    if (settingsSchema?.properties) {
        productSettingsNode = Object.keys(settingsSchema?.properties).map(settingsName => (
            <div key={settingsName}>
                <ProductSetting
                    code={code}
                    type={settingsSchema.properties[settingsName].type}
                    productSettings={settingsSchema.properties[settingsName]}
                    name={settingsName}
                />
            </div>
        ));

        fullSettingsNode.push(productSettingsNode);
    }

    /**
     * Wrap the result in a Fragment
     * https://stackoverflow.com/questions/62702485/component-cannot-be-used-as-a-jsx-component-its-return-type-element-is-not?rq=1
     */
    return <>{fullSettingsNode}</>;
};

export default ProductTag;
