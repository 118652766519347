import React from 'react';
import Helmet from 'react-helmet-async';
import TOTP from 'features/totp';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>TOTP (time based one time password)</title>
        </Helmet>
        <MainContainer>
            <TOTP />
        </MainContainer>
    </React.Fragment>
);

export default Route;
