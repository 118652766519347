import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Button } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import RESEND_INVITE from '../mutations/resendInvite';

interface Props {
    email: string;
    callback?(): void;
}

const ResendInviteButton = ({ email, callback }: Props) => {
    const Toaster = useContext(ToastContext);

    const [resendInvite, { loading }] = useMutation(RESEND_INVITE, {
        variables: { email },
        onCompleted: () => {
            Toaster.showToast(`Ett nytt aktiveringsmail har skickats`, 'success');

            if (callback) {
                callback();
            }
        },
        onError: () => {
            Toaster.showToast(`Något gick fel `, 'danger', 5000);
        }
    });

    return (
        <Button type="submit" loading={loading} onClick={resendInvite}>
            <i className="fa fa-refresh mr-1" />
            Skicka ny inbjudan
        </Button>
    );
};

export default ResendInviteButton;
