const counties = [
    { value: -1, label: 'Välj län' },
    { value: 'Blekinge län', label: 'Blekinge län' },
    { value: 'Dalarnas län', label: 'Dalarnas län' },
    { value: 'Gotlands län', label: 'Gotlands län' },
    { value: 'Gävleborgs län', label: 'Gävleborgs län' },
    { value: 'Hallands län', label: 'Hallands län' },
    { value: 'Jämtlands län', label: 'Jämtlands län' },
    { value: 'Jönköpings län', label: 'Jönköpings län' },
    { value: 'Kalmar län', label: 'Kalmar län' },
    { value: 'Kronobergs län', label: 'Kronobergs län' },
    { value: 'Norrbottens län', label: 'Norrbottens län' },
    { value: 'Skåne län', label: 'Skåne län' },
    { value: 'Stockholms län', label: 'Stockholms län' },
    { value: 'Södermanlands län', label: 'Södermanlands län' },
    { value: 'Uppsala län', label: 'Uppsala län' },
    { value: 'Värmlands län', label: 'Värmlands län' },
    { value: 'Västerbottens län', label: 'Västerbottens län' },
    { value: 'Västernorrlands län', label: 'Västernorrlands län' },
    { value: 'Västmanlands län', label: 'Västmanlands län' },
    { value: 'Västra Götalands län', label: 'Västra Götalands län' },
    { value: 'Örebro län', label: 'Örebro län' },
    { value: 'Östergötlands län', label: 'Östergötlands län' }
];

export { counties }; // eslint-disable-line
