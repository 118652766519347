import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

class FtpUserForm extends React.PureComponent {
    static propTypes = {
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
        edit: PropTypes.bool,
        setFieldValue: PropTypes.func.isRequired
    };

    render() {
        const { handleChange, handleBlur, handleSubmit, values, edit, errors, touched } = this.props;

        const handleUserNameChange = e => {
            const { setFieldValue } = this.props;
            setFieldValue('homedir', `/opt/www/bytbil/seller_incoming/${e.target.value}`);
            handleChange(e);
        };

        return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs="12" lg="5" xl="4">
                        <Field
                            name="userid"
                            label="FTP-användarnamn"
                            type="text"
                            placeholder="2signum"
                            onChange={e => handleUserNameChange(e)}
                            onBlur={handleBlur}
                            value={values.userid}
                            error={errors.userid}
                            touched={touched.userid}
                            disabled={edit}
                            text="FTP-användarnamnet. Bör i regel matcha handlarkoden för handlaren"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="5" xl="4">
                        <Field
                            name="passwd"
                            label="Lösenord"
                            type="password"
                            placeholder="******"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwd}
                            error={errors.passwd}
                            touched={touched.passwd}
                            text="Lösenordet som används vid FTP-autentisering. Ej kopplat till handlarens andra lösenord."
                            autoComplete="new-password"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="5" xl="4">
                        <Field
                            name="homedir"
                            label="Hemkatalog"
                            type="text"
                            placeholder="/opt/www/bytbil/seller_incoming/2signum"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.homedir}
                            error={errors.homedir}
                            touched={touched.homedir}
                            disabled={edit}
                            text="Katalogen där handlarens filer landar."
                        />
                    </Col>
                </Row>
                {!edit && (
                    <Row>
                        <Col xs="12" lg="5" xl="4">
                            <Field
                                name="externalId"
                                label="Externt ID"
                                type="number"
                                placeholder="1191"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.externalId}
                                error={errors.externalId}
                                touched={touched.externalId}
                                text="Handlarens ID i övriga system."
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs="12">
                        <Row>
                            <Col xs="12" md="auto">
                                <Button type="submit" block color="primary">
                                    {edit ? 'Uppdatera lösenord' : 'Skapa användare'}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default FtpUserForm;
