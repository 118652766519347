import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';

import { ToastContext } from 'context/ToastContext';

import DealerGroupForm from './DealerGroupForm';
import createDealerGroupMutation from '../mutations/createDealerGroup';

const CreateGroup = () => {
    const [createDealerGroup] = useMutation(createDealerGroupMutation);
    const toaster = useContext(ToastContext);

    const [showActionPanel, setShowActionPanel] = useState(false);

    const submitHandler = async variables => {
        try {
            await createDealerGroup({ variables });

            toaster.showToast('Handlaren skapades!');

            setShowActionPanel(false);

            return navigate('/dealer-groups');
        } catch (err) {
            return toaster.showToast('Aj då, nått gick fel', 'danger');
        }
    };

    const defaultInitialValues = {
        name: '',
        code: '',
        dealerIds: [],
        groupAdmin: '-1'
    };

    return (
        <DealerGroupForm
            edit={false}
            title="Skapa ny Handlargrupp"
            toaster={toaster}
            showActionPanel={showActionPanel}
            setShowActionPanel={setShowActionPanel}
            submitHandler={submitHandler}
            initialValues={defaultInitialValues}
        />
    );
};

export default CreateGroup;
