import React, { createContext, useContext } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';

import { useImmer } from 'use-immer';

import { User } from './types';
import { LIMIT } from './constants';

import LIST_USERS_QUERY from './queries/listUsers';

interface Props {
    dealerId?: string;
}

type QueryVariables = {
    offset: number;
    limit: number;
    search?: string;
};

type UsersContextType = {
    users: User[];
    totalUsers: number;
    loading: boolean;
    refetch: (variables?: QueryVariables) => Promise<ApolloQueryResult<QueryData>>;
};

type QueryData = {
    users: User[];
};

const UsersContext = createContext<UsersContextType>({
    users: [],
    totalUsers: 0,
    loading: false,
    refetch: async () => ({ data: { users: [] }, loading: false, networkStatus: 7 })
});

export const useUsers = () => useContext(UsersContext);

const withUsers = Component => (props: Props) => {
    const [state, setState] = useImmer<{ users: User[] }>({
        users: []
    });

    const { data, loading, error, refetch } = useQuery(LIST_USERS_QUERY, {
        variables: { offset: 0, limit: LIMIT, search: props.dealerId ? props.dealerId : '' },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onCompleted: completedData => {
            const userMap = new Map(state.users.map(user => [user.id, user]));
            completedData.listUsers.userData.forEach(user => userMap.set(user.id, user));
            setState(draft => {
                draft.users = Array.from(userMap.values());
            });
        }
    });

    const searchUsers = async ({ search }: { search: string }) => {
        // reset state.users
        setState(draft => {
            draft.users = [];
        });

        const getSearchString = () => (props.dealerId ? `${props.dealerId}${search ? `,${search}` : ''}` : search);

        await refetch({ offset: 0, limit: LIMIT, search: getSearchString() });
    };

    return (
        <UsersContext.Provider
            value={{ users: state.users, totalUsers: data && data.listUsers.count, loading, refetch }}
        >
            <Component {...props} searchUsers={searchUsers} error={error} />
        </UsersContext.Provider>
    );
};

export default withUsers;
