import React from 'react';
import Helmet from 'react-helmet-async';
import { ListGroups } from 'features/api-tokens';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Skapa API-grupp</title>
        </Helmet>
        <MainContainer>
            <ListGroups />
        </MainContainer>
    </React.Fragment>
);

export default Route;
