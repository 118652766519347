import React, { ReactNode } from 'react';
import { Link } from '@reach/router';

interface Props {
    title: string;
    to: string;
    children: ReactNode;
}

const DealerLinkCell = ({ title, to, children }: Props) => (
    <td className="p-0">
        <Link title={title} to={to} className="d-block text-decoration-none m-0 p-2 text-dark">
            {children}
        </Link>
    </td>
);

export default DealerLinkCell;
