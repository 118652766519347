import React from 'react';
import { Link } from '@reach/router';

import { User } from '../types';
import InitialsIcon from './InitialsIcon';
import DeleteUserButton from './DeleteUserButton';
import { useUsers } from '../ListUsersProvider';
import ResendInviteButton from './ResendInviteButton';

interface Props {
    user: User;
}

interface State {
    state: 'active' | 'inactive' | 'confirmed' | 'invited' | 'signup';
}

const getBadgeColourByState = (state: State['state']) => {
    switch (state) {
        case 'active':
            return 'success';
        case 'inactive':
            return 'danger';
        case 'confirmed':
            return 'success';
        case 'invited':
            return 'warning';
        case 'signup':
            return 'warning';
        default:
            return 'secondary';
    }
};

const getBadgeNameByState = (state: State['state']) => {
    switch (state) {
        case 'active':
            return 'Aktiv';
        case 'inactive':
            return 'Inaktiv';
        case 'confirmed':
            return 'Aktiverad';
        case 'invited':
            return 'Inbjuden';
        case 'signup':
            return 'Registrerad';
        default:
            return 'Okänd';
    }
};

const StateCell = ({ state }: State) => (
    <div className={`badge badge-${getBadgeColourByState(state)} p-2`}>{getBadgeNameByState(state)}</div>
);

const FlexedTd = ({ children }: { children: React.ReactNode }) => (
    <td>
        <div className="d-flex align-items-center h-100">{children}</div>
    </td>
);

const UserRow = ({ user: { firstName, lastName, id, state, email, roles } }: Props) => {
    const { refetch } = useUsers();

    return (
        <tr className="iua-user-row">
            <FlexedTd>
                <InitialsIcon firstName={firstName} lastName={lastName} />

                <Link to={`/users/edit/${id}`} className="ml-2 user-credentials text-decoration-none">
                    <div className="ellipsis">
                        {firstName} {lastName}
                    </div>
                    <div className="text-muted email-link ellipsis">{email}</div>
                </Link>
            </FlexedTd>
            <FlexedTd>{roles.map((role, i) => (i ? `, ${role.name}` : role.name))}</FlexedTd>
            <FlexedTd>
                <StateCell state={state} />
            </FlexedTd>
            <FlexedTd>{state === 'invited' && <ResendInviteButton email={email} />}</FlexedTd>
            <FlexedTd>
                <DeleteUserButton id={id} email={email} callback={refetch} />
            </FlexedTd>
        </tr>
    );
};

export default UserRow;
