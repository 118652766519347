import { ToastContext } from 'context/ToastContext';
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from '@bytbil/bootstrap-react';

import SYNC_OFFER_MUTATION from '../../blocket-exporter/mutations/syncOffer';

interface Props {
    offerId: string;
}

const ResyncOfferBtn = ({ offerId }: Props) => {
    const Toaster = useContext(ToastContext);
    const stringId = offerId.toString();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const [syncOffer, { loading }] = useMutation(SYNC_OFFER_MUTATION);

    const handleSyncOffer = async (id: string) => {
        try {
            const {
                data: {
                    syncOfferByRegNoOrAdId: { success, message }
                }
            } = await syncOffer({
                variables: { id }
            });

            if (success) {
                return Toaster.showToast(message);
            }
            return Toaster.showToast(message, 'danger');
        } catch (e) {
            return Toaster.showToast('Något gick fel', 'danger');
        }
    };
    return (
        <Button className="hover-icon p-0 m-0" onClick={() => handleSyncOffer(stringId)} loading={loading} color="link">
            <i className="fa fa-refresh text-dark" id={`resyncAdd-${offerId}`} />
            <Tooltip placement="bottom" isOpen={tooltipOpen} target={`resyncAdd-${offerId}`} toggle={toggleTooltip}>
                Synka om annons
            </Tooltip>
        </Button>
    );
};

export default ResyncOfferBtn;
