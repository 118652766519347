import { string, object, number, boolean } from 'yup';

import { counties } from './data';

const CURRENT_YEAR = new Date().getFullYear();

const URL_REGEX =
    /^(((http(s?):(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

export const addDealerSchema = () =>
    object().shape({
        code: string()
            .min(2, 'Handlarkoden måste innehålla minst två tecken')
            .matches(
                /^[a-z0-9_-]+$/,
                'Handlargruppen får bara innehålla små bokstäver, siffror samt under- eller bindestreck'
            )
            .required('Ange handlarkod, inga mellanslag'),
        website: string().matches(URL_REGEX, 'Ange en giltig URL'),
        companyName: string().min(2, 'Företagsnamnet måste innehålla minst två tecken').required('Ange företagsnamn'),
        established: number()
            .min(1886, 'Ange ett giltigt årtal yyyy')
            .max(CURRENT_YEAR + 1, `Du kan inte ange ett tal högre än ${CURRENT_YEAR + 1}`)
            .typeError('Ange ett giltigt årtal yyyy')
            .nullable(),
        orgNumber: string()
            .matches(/^\d{6}-\d{4}$/, 'Ange ett giltigt organisationsnummer')
            .required('Ange organisationsnummer'),
        address: string(),
        postalNumber: string()
            .matches(/^\d{5}$|^\d{3}\s\d{2}$/, 'Postnumret måste innehålla exakt fem siffror')
            .required('Ange postnummer'),
        city: string().required('Ange stad'),
        county: string()
            .oneOf(
                counties.map(county => county.value),
                'Ange län'
            )
            .required('Ange län'),
        dealerGroupName: string()
            .test({
                message: 'Ange företagsgrupp',
                test(value) {
                    const { dealerGroupAdmin } = this.parent;
                    if (dealerGroupAdmin) return !!value;
                    return true;
                }
            })
            .nullable(),
        emailDisplay: string().email('Ange en giltig e-postadress').required('Ange e-postadress'),
        googleReviews: boolean().nullable(),
        accountId: string()
            .when('googleReviews', {
                is: true,
                then: string().required('Ange accountId'),
                otherwise: string()
            })
            .nullable(),
        locationId: string()
            .when('googleReviews', {
                is: true,
                then: string().required('Ange locationId'),
                otherwise: string()
            })
            .nullable()
    });

export const editDealerSchema = () =>
    object().shape({
        website: string().matches(URL_REGEX, 'Ange en giltig URL'),
        companyName: string().min(2, 'Företagsnamnet måste innehålla minst två tecken').required('Ange företagsnamn'),
        established: number()
            .min(1886, 'Ange ett giltigt årtal yyyy')
            .max(CURRENT_YEAR + 1, `Du kan inte ange ett tal högre än ${CURRENT_YEAR + 1}`)
            .typeError('Ange ett giltigt årtal yyyy')
            .nullable(),
        orgNumber: string()
            .matches(/^\d{6}-\d{4}$/, 'Ange ett giltigt organisationsnummer')
            .required('Ange organisationsnummer'),
        address: string(),
        postalNumber: string()
            .matches(/^\d{5}$|^\d{3}\s\d{2}$/, 'Postnumret måste innehålla exakt fem siffror')
            .required('Ange postnummer'),
        city: string().required('Ange stad'),
        county: string()
            .oneOf(
                counties.map(county => county.value),
                'Ange län'
            )
            .required('Ange län'),
        dealerGroupName: string()
            .test({
                message: 'Ange företagsgrupp',
                test(value) {
                    const { dealerGroupAdmin } = this.parent;
                    if (dealerGroupAdmin) return !!value;
                    return true;
                }
            })
            .nullable(),
        emailDisplay: string().email('Ange en giltig e-postadress').required('Ange e-postadress'),
        googleReviews: boolean().nullable(),
        accountId: string()
            .when('googleReviews', {
                is: true,
                then: string().required('Ange accountId'),
                otherwise: string()
            })
            .nullable(),
        locationId: string()
            .when('googleReviews', {
                is: true,
                then: string().required('Ange locationId'),
                otherwise: string()
            })
            .nullable()
    });
