import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.string || PropTypes.undefined,
    verticalAlign: PropTypes.string || PropTypes.undefined
};

const Icon = props => {
    const { name, color, id, size, verticalAlign } = props;

    return <i className={classNames(`fa fa-${name}`, size && `fa-${size}`)} style={{ color, verticalAlign }} id={id} />;
};

Icon.propTypes = propTypes;

export default Icon;
