import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import { object, string, number } from 'yup';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';
import FtpUserForm from '../components/FtpUserForm';
import createFtpUser from '../mutations/createFtpUser';

class CreateFtpUser extends React.Component {
    static contextType = ToastContext;

    static propTypes = {
        onCreateUserSuccess: PropTypes.func,
        onCreateUserError: PropTypes.func
    };

    static defaultProps = {
        onCreateUserSuccess: Function.prototype,
        onCreateUserError: Function.prototype
    };

    constructor(props) {
        super(props);

        this.submitHandler = this.submitHandler.bind(this);
    }

    onCreateUserSuccess() {
        this.props.onCreateUserSuccess();
        this.context.showToast('Skapade FTP-användaren', 'success');
        setTimeout(() => {
            window.history.back();
        }, 2000);
    }

    onCreateUserError() {
        this.props.onCreateUserError();
        this.context.showToast('Kunde inte skapa FTP-användaren', 'danger');
    }

    submitHandler(mutation) {
        return async (values, methods) => {
            const { setSubmitting } = methods;

            try {
                const { data } = await mutation({
                    variables: {
                        userid: values.userid,
                        passwd: values.passwd,
                        external_id: values.externalId,
                        homedir: values.homedir
                    }
                });

                if (data.createFtpUser.userid) {
                    this.onCreateUserSuccess();
                } else {
                    this.onCreateUserError();
                }
            } catch (e) {
                this.onCreateUserError();
            }

            setSubmitting(false);
        };
    }

    render() {
        const { dealerCode, dealerId, dealerHref } = this.props;

        return (
            <Mutation mutation={createFtpUser}>
                {mutation => (
                    <PageLayout title="Skapa FTP-användare">
                        <Card>
                            <CardBody>
                                <Formik
                                    onSubmit={this.submitHandler(mutation)}
                                    initialValues={{
                                        userid: dealerCode,
                                        passwd: '',
                                        externalId: dealerId,
                                        homedir: dealerHref
                                    }}
                                    validationSchema={object().shape({
                                        userid: string().required('Måste vara ifyllt'),
                                        passwd: string().required('Måste vara ifyllt'),
                                        externalId: number().integer().required('Måste vara ifyllt')
                                    })}
                                >
                                    {props => <FtpUserForm {...props} edit={false} />}
                                </Formik>
                            </CardBody>
                        </Card>
                    </PageLayout>
                )}
            </Mutation>
        );
    }
}

export default CreateFtpUser;
