import React from 'react';
import Helmet from 'react-helmet-async';
import EditUser from 'features/users/EditUser';

import MainContainer from 'components/MainContainer';

interface Props {
    uuid: string;
}

const Route = ({ uuid }: Props) => (
    <React.Fragment>
        <Helmet>
            <title>Ändra användare</title>
        </Helmet>
        <MainContainer>
            <EditUser uuid={uuid} />
        </MainContainer>
    </React.Fragment>
);

export default Route;
