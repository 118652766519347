import React, { useEffect } from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import { object, string, array, number } from 'yup';
import { Card, CardBody, Container, Row, Col, Button } from '@bytbil/bootstrap-react';
import { ActionPanel, Field, MultipleValueInput, Select } from '@bytbil/general-react';

interface FormValues {
    name: string;
    code: string;
    groupAdmin: string;
    dealerIds: number[];
}

interface OtherProps {
    edit: boolean;
    title: string;
    showActionPanel: boolean;
    setShowActionPanel(boolean): void;
}

const DealerGroupForm = (props: OtherProps & FormikProps<FormValues>) => {
    const {
        touched,
        values,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,

        isSubmitting,
        resetForm,
        dirty,

        showActionPanel,
        setShowActionPanel,

        edit,
        title
    } = props;

    const handleMultiValueChange = key => value => {
        // this is going to call setFieldValue and manually update values.dealerIds
        // as calling the regular Formik.handleChange won't work
        setFieldValue(
            key,
            value.map(v => parseInt(v, 10))
        );
    };

    const adminOptions = values.dealerIds.map(dealerId => ({
        value: dealerId,
        label: dealerId.toString()
    }));

    adminOptions.unshift({ value: -1, label: 'Välj handlargruppsadmin' });

    useEffect(() => {
        setShowActionPanel(dirty);
    }, [dirty]);

    return (
        <Form>
            <h1 className="page-title">{title}</h1>
            <Card>
                <CardBody>
                    <Row>
                        <Col xs="12" lg="5" xl="4">
                            <Field
                                name="name"
                                id="name"
                                type="text"
                                touched={touched.name}
                                value={values.name}
                                error={errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Gruppnamn"
                                disabled={edit}
                            />
                        </Col>
                        {edit && (
                            <Col xs="12" lg="5" xl="4">
                                <Field
                                    name="code"
                                    id="code"
                                    type="text"
                                    touched={touched.code}
                                    value={values.code}
                                    error={errors.code}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Gruppkod"
                                    disabled
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col xs="12" lg="5" xl="4">
                            <MultipleValueInput
                                name="dealerIds"
                                value={values.dealerIds}
                                onChange={handleMultiValueChange}
                                onBlur={handleBlur}
                                label="Handlare"
                                placeholder="Handlarid, tex: 1191"
                            />
                            {errors.dealerIds && <span className="text-danger">{errors.dealerIds}</span>}
                        </Col>
                        <Col xs="12" lg="5" xl="4">
                            <Select
                                label="Handlargruppsadmin"
                                touched={touched.groupAdmin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.groupAdmin}
                                value={values.groupAdmin}
                                options={adminOptions}
                                id="groupAdmin"
                                name="groupAdmin"
                            />
                        </Col>
                    </Row>

                    <ActionPanel showPanel={showActionPanel}>
                        <Container>
                            <Row>
                                <Col md="12" className="d-flex align-items-center">
                                    <Col md="12" className="py-3 d-none d-md-block">
                                        <div className="d-flex justify-content-between justify-content-md-end">
                                            <Button onClick={resetForm} outline className="mr-2">
                                                Återställ
                                            </Button>
                                            <Button color="success" disabled={isSubmitting} type="submit">
                                                {isSubmitting ? (
                                                    <div className="loader fa fa-circle-o-notch fa-spin" />
                                                ) : (
                                                    'Spara'
                                                )}
                                            </Button>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>
                    </ActionPanel>
                </CardBody>
            </Card>
        </Form>
    );
};

interface MyFormProps {
    edit: boolean;
    title: string;
    initialValues: {
        name: string;
        code: string;
        dealerIds: number[];
        groupAdmin: string;
    };
    submitHandler(variables): void;
    toaster: any;
    showActionPanel: boolean;
    setShowActionPanel(boolean): void;
}

const EnhancedDealerGroupForm = withFormik<MyFormProps, FormValues>({
    validationSchema: object().shape({
        name: string()
            .min(2, 'Ange minst två tecken')
            .max(128, 'Nu är namnet lite väl långt va?')
            .required('Ange gruppnamn')
            .typeError('Aj aj'),
        dealerIds: array().of(number().integer()).required('Ange anläggnings-idn')

        // Save this for now
        // code: string()
        // .matches(/^[a-zA-Z0-9-_]+$/, 'Handlargruppen får bara innehålla bokstäver, siffror eller understreck')
        // .required('Ange gruppnamn'),
    }),

    enableReinitialize: true,

    mapPropsToValues: ({ initialValues }: MyFormProps) => ({
        name: initialValues.name || '',
        code: initialValues.code || '',
        dealerIds: initialValues.dealerIds,
        groupAdmin: initialValues.groupAdmin
    }),

    handleSubmit: async (values: FormValues, formikBag) => {
        try {
            const { name, dealerIds, groupAdmin } = values;

            const variables = { name, dealerIds, groupAdmin: parseInt(groupAdmin, 10) };

            return formikBag.props.submitHandler(variables);
        } catch (e) {
            formikBag.props.toaster.showToast(`Kunde inte spara ändringarna`, 'danger');

            return console.error(e);
        }
    },

    displayName: 'DealerGroupForm'
})(DealerGroupForm);

export default EnhancedDealerGroupForm;
