import gql from 'graphql-tag';

export default gql`
    mutation login($username: String!, $password: String!, $secret: String!) {
        login(username: $username, password: $password, secret: $secret) {
            accessToken
            refreshToken
        }
    }
`;
