import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { connect, getIn, FormikValues, FormikErrors } from 'formik';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = wtf => !!(wtf && typeof wtf.focus === 'function');

const getFormInputs = name => () => {
    if (typeof document === 'undefined') {
        return [];
    }
    // $FlowFixMe
    const form = document.forms[name];

    return form && form.length ? Array.prototype.slice.call(form).filter(isFocusableInput) : []; // cast cheat to get from HTMLFormElement children to FocusableInput
};

interface ScrollerProps {
    /** The name or id of the form */
    formId: String;
    /** a formik prop, the number of times a form has been submit */
    submitCount: Number;
    /** a formik prop, an array of error strings */
    errors: FormikErrors<FormikValues>;
    children: (state) => React.ReactNode | any;
}

const Scroller = ({ formId, submitCount, errors, children }: ScrollerProps) => {
    const [state, setState] = useImmer({
        input: undefined
    });

    const onUpdate = firstInput => {
        setState(draft => {
            draft.input = firstInput.id || firstInput.name;
        });
    };

    useEffect(() => {
        if (submitCount > 0) {
            const firstInput = getFormInputs(formId)().find(
                input => (input.id || input.name) && getIn(errors, input.id || input.name)
            );

            // Check if any errors exist
            if (firstInput) {
                onUpdate(firstInput);
            }
        }
    }, [submitCount]);

    return children(state);
};

const ScrollErrors = connect(Scroller);

export default ScrollErrors;
