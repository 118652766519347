import { Brand, Model, VehicleType } from './types';

interface VehicleTypeOptionsProps {
    vehicleTypes: VehicleType[];
    edit: boolean;
    useSlugAsVehicleTypeValue: boolean;
}

const includesQuery = (query: string) => (attribute: string) => attribute.toLowerCase().includes(query.toLowerCase());

export const onQuery =
    (query: string) =>
    ({ name = '', slug = '', vehicleTypeName = '', matches }) => {
        if (!query) {
            return true;
        }
        return [name, slug, vehicleTypeName, ...matches].some(includesQuery(query));
    };

export const getIsApprovedPending = (approved: boolean | null) => approved === null;

export const getVehicleTypeOptions = ({ vehicleTypes, edit, useSlugAsVehicleTypeValue }: VehicleTypeOptionsProps) => {
    const defaultOption = [{ value: '0', label: 'Välj' }];
    const options = vehicleTypes
        ? vehicleTypes.map(({ id, name, slug }) => ({ value: useSlugAsVehicleTypeValue ? slug : id, label: name }))
        : [];

    return edit ? options : [...defaultOption, ...options];
};

export const getApprovedOptions = ({ isBrand }: { isBrand: boolean }) => {
    const text = isBrand ? 'märke' : 'modell';
    return [
        { value: 'true', label: `Godkänn ${text}` },
        { value: 'false', label: `Neka ${text}` }
    ];
};

export const getBrandValues = (brand: Brand | undefined) =>
    brand
        ? { name: brand.name, slug: brand.slug, matches: brand.matches, approved: `${brand.approved}` }
        : { name: '', matches: [], approved: 'true', slug: '' };

export const getModelValues = (model: Model | undefined) =>
    model
        ? {
              name: model.name,
              slug: model.slug,
              vehicleType: model.vehicleTypeId,
              matches: model.matches,
              approved: `${model.approved}`
          }
        : { name: '', vehicleType: '0', matches: [], approved: 'true', slug: '' };

export const BRAND_STATUS_OPTIONS = [
    {
        value: 'true',
        label: 'Godkända'
    },
    {
        value: 'false',
        label: 'Nekade'
    },
    {
        value: 'null',
        label: 'Ej hanterade'
    }
];

export const getBrandVariable = (status: string) =>
    status === 'null' ? { approved__isnull: true } : { approved: status };
