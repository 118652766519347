import React from 'react';
import { Table } from '@bytbil/bootstrap-react';
import ScopeTableRow from './ScopeTableRow';

import { Scope, ScopeHeader } from '../../types';

interface Props {
    createdScopes: Scope[];
    scopeHeaders: ScopeHeader;
    onDelete(value): void;
}

const ScopeTable = React.memo(({ createdScopes, onDelete, scopeHeaders }: Props) => (
    <Table responsive bordered className="mb-3">
        <thead>
            <tr>
                <th>Tillgång till</th>
                <th>Handlar-ID</th>
                <th>Grupp</th>
                <th>Värde</th>
                <th className="text-center">
                    <i className="fa fa-trash" />
                </th>
            </tr>
        </thead>
        <tbody>
            {createdScopes.map(scope => (
                <ScopeTableRow key={scope.scope} scope={scope} onDelete={onDelete} scopeHeaders={scopeHeaders} />
            ))}
        </tbody>
    </Table>
));

export default ScopeTable;
