import React from 'react';
import { Field } from '@bytbil/general-react';
import { Form, CardBody } from '@bytbil/bootstrap-react';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';

interface Props {
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    values: FormikValues;
    handleChange(e: React.FocusEvent<HTMLInputElement>): void;
    handleBlur(e: React.FocusEvent<HTMLInputElement>): void;
    setFieldValue(key: string, value: string): void;
    searchDealer(value: string): void;
    setSearched(): void;
    searched: boolean;
}

const DealerSearch = ({
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    searchDealer,
    setSearched,
    searched
}: Props) => {
    const handleSearchFieldClick = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    const resetHandler = () => {
        setFieldValue('search', '');
    };

    const handleSubmit = e => {
        e.preventDefault();
        searchDealer(values.search);
        if (!searched) {
            setSearched();
        }
    };
    return (
        <CardBody>
            <h3>Välj handlare</h3>
            <Form onSubmit={handleSubmit} noValidate name="DealerSearchForm">
                <Field
                    type="search"
                    name="search"
                    label="Sök handlare"
                    touched={touched.search}
                    error={errors.search}
                    onClick={handleSearchFieldClick}
                    onChange={handleChange}
                    onReset={resetHandler}
                    placeholder="Sök på handlarkod, företagsnamn, org.nummer, handlar-ID eller handlargrupp"
                    onBlur={handleBlur}
                    value={values.search}
                    iconPosition="right"
                    className="mb-0"
                />
            </Form>
        </CardBody>
    );
};

export default DealerSearch;
