import React from 'react';

interface Props {
    children: React.ReactNode;
}

const Login = ({ children }: Props) => (
    <main className="main-content">
        <div className="container card-container my-4">
            <div className="row">
                <div className="col-12 col-lg-4 col-left offset-lg-4">
                    <h1 className="page-title mb-3">Toolbox</h1>
                    <div className="card card-container-card">
                        <div className="card-body border-top-0">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
);

export default Login;
