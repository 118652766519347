import React from 'react';
import classNames from 'classnames';
import { Nav, NavItem } from '@bytbil/bootstrap-react';
import TabLink from 'components/TabLink';

interface Props {
    activeTab: string;
    showTab(id: string): void;
}

const tabItems = [
    {
        name: 'Uppdatera annonser',
        id: 'updateAds'
    },
    {
        name: 'Synka om annons',
        id: 'resyncVehicle'
    },
    {
        name: 'Radera annons',
        id: 'deleteOffer'
    }
];

const Tabs = ({ activeTab, showTab }: Props) => (
    <Nav tabs>
        {tabItems.map(item => (
            <NavItem key={item.id}>
                <TabLink
                    className={classNames('nav-link', activeTab === item.id && 'active')}
                    id={item.id}
                    showTab={showTab}
                >
                    {item.name}
                </TabLink>
            </NavItem>
        ))}
    </Nav>
);

export default Tabs;
