import gql from 'graphql-tag';

export default gql`
    mutation generateTOTP($username: String!, $password: String!) {
        generateTOTP(username: $username, password: $password) {
            qr
            secret
        }
    }
`;
