import React from 'react';
import { Query } from '@apollo/client/react/components';
import { ApolloConsumer } from '@apollo/client';
import { Alert, Button, Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter } from '@bytbil/bootstrap-react';
import copy from 'copy-to-clipboard';
import produce from 'immer';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';
import ApiUserTable from '../components/ApiUserTable';
import CreateUser from './create-user';

import apiUsersQuery from '../queries/apiUsers';
import deleteApiUserMutation from '../mutations/deleteApiUser';
import regenerateApiUserPasswordMutation from '../mutations/regenerateApiUserPassword';

class ApiUsers extends React.PureComponent {
    static contextType = ToastContext;

    constructor(props) {
        super(props);

        this.state = {
            password: undefined,
            deletionSubject: undefined,
            passwordRegenerationSubject: undefined,
            modalState: {
                id: undefined,
                open: false
            }
        };

        this.onCreated = this.onCreated.bind(this);
        this.cancelDeletionModal = this.cancelDeletionModal.bind(this);
        this.dismissPasswordRegenerationSucceededModal = this.dismissPasswordRegenerationSucceededModal.bind(this);
        this.cancelPasswordRegenerationModal = this.cancelPasswordRegenerationModal.bind(this);
    }

    onCreated(refetch) {
        return () => {
            this.context.showToast('API-användaren skapades.');
            refetch();
        };
    }

    regenerateApiUserPassword = (client, refetch) => async () => {
        const { passwordRegenerationSubject } = this.state;

        try {
            const { data } = await client.mutate({
                mutation: regenerateApiUserPasswordMutation,
                variables: {
                    username: passwordRegenerationSubject
                }
            });

            await refetch();

            this.setState(
                {
                    password: data.regenerateApiUserPassword
                },
                () => {
                    this.cancelPasswordRegenerationModal();
                    this.showApiUserPasswordRegenerationSuccessModal();
                }
            );
        } catch (e) {
            this.cancelPasswordRegenerationModal();
            this.showErrorToast();
        }
    };

    showPasswordRegenerationConfirmationModal = passwordRegenerationSubject => () => {
        this.setState(
            produce(draft => {
                draft.passwordRegenerationSubject = passwordRegenerationSubject;
                draft.modalState = {
                    id: 'CONFIRM_API_USER_PASSWORD_REGENERATION',
                    open: true
                };
            })
        );
    };

    deleteApiUser = (client, refetch) => async () => {
        const { deletionSubject } = this.state;

        try {
            await client.mutate({
                mutation: deleteApiUserMutation,
                variables: {
                    username: deletionSubject
                }
            });

            this.context.showToast('API-Användaren togs bort.');

            await refetch();
        } catch (e) {
            this.showErrorToast();
        }

        this.setState(
            produce(draft => {
                draft.modalState = {
                    id: 'CONFIRM_API_USER_PASSWORD_REGENERATION',
                    open: false
                };
            })
        );
    };

    showApiUserPasswordRegenerationSuccessModal = () => {
        this.setState(
            produce(draft => {
                draft.modalState = {
                    id: 'API_USER_PASSWORD_REGENERATED',
                    open: true
                };
            })
        );
    };

    showDeleteConfirmationModal = deletionSubject => () => {
        this.setState(
            produce(draft => {
                draft.deletionSubject = deletionSubject;
                draft.modalState = {
                    id: 'CONFIRM_API_USER_DELETION',
                    open: true
                };
            })
        );
    };

    copyPassword = () => {
        const { password } = this.state;
        copy(password);
    };

    showErrorToast() {
        this.context.showToast('Något gick fel, försök igen om en stund.', 'danger');
    }

    cancelPasswordRegenerationModal() {
        this.setState(
            produce(draft => {
                draft.modalState = {
                    id: 'CONFIRM_API_USER_PASSWORD_REGENERATION',
                    open: false
                };
            })
        );
    }

    dismissPasswordRegenerationSucceededModal() {
        this.setState(
            produce(draft => {
                draft.modalState = {
                    id: 'API_USER_PASSWORD_REGENERATED',
                    open: false
                };
            })
        );
    }

    cancelDeletionModal() {
        this.setState(
            produce(draft => {
                draft.modalState = {
                    id: 'CONFIRM_API_USER_DELETION',
                    open: false
                };
            })
        );
    }

    render() {
        const { password, modalState } = this.state;

        return (
            <ApolloConsumer>
                {client => (
                    <Query query={apiUsersQuery} fetchPolicy="cache-and-network">
                        {({ loading, data, error, refetch }) => (
                            <PageLayout title="API-användare">
                                <p>Här kan man se api-användare. Tryck på en användare för att hantera dess nycklar</p>
                                <Card className="mb-4">
                                    <CardBody>
                                        <CreateUser onCreated={this.onCreated(refetch)} />
                                    </CardBody>
                                </Card>
                                <Card className="mb-4">
                                    <CardBody>
                                        {loading && (
                                            <div className="text-center">
                                                <i className="fa fa-circle-o-notch fa-spin" />
                                            </div>
                                        )}
                                        {data && data.apiUsers && (
                                            <ApiUserTable
                                                users={data.apiUsers}
                                                onRegeneratePassword={this.showPasswordRegenerationConfirmationModal}
                                                onDelete={this.showDeleteConfirmationModal}
                                            />
                                        )}
                                    </CardBody>
                                </Card>

                                {error && (
                                    <Alert className="mt-3" color="danger">
                                        Något gick fel
                                    </Alert>
                                )}
                                <Modal
                                    isOpen={modalState.id === 'API_USER_PASSWORD_REGENERATED' && modalState.open}
                                    toggle={this.dismissPasswordRegenerationSucceededModal}
                                >
                                    <ModalHeader toggle={this.dismissPasswordRegenerationSucceededModal}>
                                        Åtgärd utförd!
                                    </ModalHeader>
                                    <ModalBody>
                                        <p>Lösenordet är:</p>
                                        <pre>{password}</pre>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.copyPassword}>
                                            Kopiera
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal
                                    id="confirm_api_user_deletion"
                                    isOpen={modalState.id === 'CONFIRM_API_USER_DELETION' && modalState.open}
                                    toggle={this.cancelDeletionModal}
                                >
                                    <ModalHeader toggle={this.cancelDeletionModal}>Bekräfta borttagning</ModalHeader>
                                    <ModalBody>
                                        Är du säker på att du vill ta bort den här användaren? Det går inte att ångra
                                        den här åtgärden. Notera även att alla nycklar associerade med denna användare
                                        kommer att tas bort om du slutför den här åtgärden.
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.deleteApiUser(client, refetch)} color="danger">
                                            Ta bort API-användaren
                                        </Button>
                                        <Button onClick={this.cancelDeletionModal}>Avbryt</Button>
                                    </ModalFooter>
                                </Modal>
                                <Modal
                                    id="confirm_api_user_password_regeneration"
                                    isOpen={
                                        modalState.id === 'CONFIRM_API_USER_PASSWORD_REGENERATION' && modalState.open
                                    }
                                    toggle={this.cancelPasswordRegenerationModal}
                                >
                                    <ModalHeader toggle={this.cancelPasswordRegenerationModal}>
                                        Skapa nytt lösenord
                                    </ModalHeader>
                                    <ModalBody>
                                        Är du säker på att du vill skapa ett nytt lösenord för den här användaren? Det
                                        går inte att ångra den här åtgärden. Notera att användarens nuvarande lösenord
                                        inte kommer att kunna användas längre, utan det nya genererade lösenordet måste
                                        skickas till användaren.
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="danger"
                                            onClick={this.regenerateApiUserPassword(client, refetch)}
                                        >
                                            Skapa nytt lösenord
                                        </Button>
                                        <Button onClick={this.cancelPasswordRegenerationModal}>Avbryt</Button>
                                    </ModalFooter>
                                </Modal>
                            </PageLayout>
                        )}
                    </Query>
                )}
            </ApolloConsumer>
        );
    }
}

export default ApiUsers;
