import React from 'react';

import { Formik } from 'formik';
import { string, object } from 'yup';
import { FormGroup, Button, ButtonGroup, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

const validationError = 'Fyll i antingen grupp eller id';

interface Props {
    onSubmit(value): void;
    className: string;
}

const StatisticsScopeForm = ({ onSubmit, className }: Props) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={{ dealerId: '', dealerGroup: '', type: 'statistics', scope: 'getStatistics' }}
        validationSchema={object().shape(
            {
                dealerGroup: string().when(['dealerId'], {
                    is: dealerId => !dealerId,
                    then: string().required(validationError)
                }),
                dealerId: string().when(['dealerGroup'], {
                    is: dealerGroup => !dealerGroup,
                    then: string().required(validationError)
                })
            },
            [
                ['dealerId', 'dealerGroup'],
                ['dealerGroup', 'dealerId']
            ]
        )}
    >
        {({ touched, values, handleChange, handleSubmit, handleBlur, errors }) => (
            <React.Fragment>
                <FormGroup className={className} row>
                    <Col>
                        <Field
                            name="dealerGroup"
                            id="dealerGroup"
                            type="text"
                            className="mb-0"
                            placeholder="bytbiltestgrupp"
                            touched={touched.dealerGroup}
                            value={values.dealerGroup}
                            label="Grupp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.dealerGroup}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="dealerId"
                            id="dealerId"
                            type="text"
                            className="mb-0"
                            placeholder="1191"
                            touched={touched.dealerId}
                            value={values.dealerId}
                            label="Handlar-ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.dealerId}
                        />
                    </Col>
                </FormGroup>
                <ButtonGroup className="d-block mt-3">
                    <Button color="success" type="submit" onClick={handleSubmit}>
                        <i className="fa fa-plus-circle fs-2" /> Lägg till
                    </Button>
                </ButtonGroup>
            </React.Fragment>
        )}
    </Formik>
);

export default StatisticsScopeForm;
