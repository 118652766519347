import React, { useRef, ReactNode, useEffect } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

interface ScrollAndFocusProps {
    children: ReactNode;
    active: boolean;
    tabIndex: number | undefined;
    /** Scroll behavior, defaults to smooth */
    behavior?: 'auto' | 'smooth';
}

const ScrollAndFocus = ({ active, tabIndex, children, behavior = 'smooth' }: ScrollAndFocusProps) => {
    const nodeRef = useRef(null);

    useEffect(() => {
        if (active) {
            scrollIntoView(nodeRef.current || document.body, {
                behavior
            });
        }
    }, [active]);

    return (
        <div ref={nodeRef} tabIndex={tabIndex}>
            {children}
        </div>
    );
};

export default ScrollAndFocus;
