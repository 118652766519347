import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Alert } from '@bytbil/bootstrap-react';
import { PageLoader } from '@bytbil/general-react';
import { Query } from '@apollo/client/react/components';

import { ToastContext } from 'context/ToastContext';

import ftpUsersQuery from '../queries/ftpUsers';
// import deleteFtpUser from '../mutations/deleteFtpUser'; // may need if delete options shall be availible
import EditFtpUser from './edit';
import CreateFtpUser from './create';

class DealerFtp extends React.PureComponent {
    static contextType = ToastContext;

    static propTypes = {
        onDeleteUserSuccess: PropTypes.func,
        onDeleteUserError: PropTypes.func
    };

    static defaultProps = {
        onDeleteUserSuccess: Function.prototype,
        onDeleteUserError: Function.prototype
    };

    constructor(props) {
        super(props);

        this.state = {
            deleteUser: {
                error: false,
                loading: false,
                success: undefined
            }
        };

        this.deleteFtpUser = this.deleteFtpUser.bind(this);
    }

    onDeleteSuccess() {
        this.props.onDeleteUserSuccess();
        this.context.showToast('FTP-användaren togs bort');

        this.setState(
            produce(draft => {
                draft.deleteUser = {
                    error: false,
                    loading: false,
                    success: true
                };
            })
        );
    }

    onDeleteError() {
        this.props.onDeleteUserError();
        this.context.showToast('Kunde inte tabort FTP-användaren', 'danger');

        this.setState(
            produce(draft => {
                draft.deleteUser = {
                    error: true,
                    loading: false,
                    success: false
                };
            })
        );
    }

    deleteFtpUser(mutation) {
        return async userid => {
            this.setState(
                produce(draft => {
                    draft.deleteUser = {
                        error: false,
                        loading: true,
                        success: undefined
                    };
                })
            );

            const { data } = await mutation({
                variables: { userid }
            });

            if (data.deleteFtpUser.userid) {
                this.onDeleteSuccess();
            } else {
                this.onDeleteError();
            }
        };
    }

    render() {
        const { deleteUser } = this.state;
        const { dealerId, dealerCode } = this.props;

        return (
            <Query query={ftpUsersQuery} fetchPolicy="cache-and-network">
                {({ loading, error, data }) => {
                    if (loading || deleteUser.loading) return <PageLoader show />;
                    if (error || deleteUser.error)
                        return <Alert color="danger">Något gick fel, försök igen om en stund.</Alert>;

                    const sortedUser = data.ftpUsers.find(user => user.external_id === dealerId) || null;

                    return (
                        <>
                            {sortedUser ? (
                                <EditFtpUser id={sortedUser.userid} />
                            ) : (
                                <CreateFtpUser
                                    dealerCode={dealerCode}
                                    dealerId={dealerId}
                                    dealerHref={`/opt/www/bytbil/seller_incoming/${dealerCode}`}
                                />
                            )}
                        </>
                    );
                }}
            </Query>
        );
    }
}

export default DealerFtp;
