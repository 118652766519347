import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useQuery } from '@apollo/client';
import { Alert, Button } from '@bytbil/bootstrap-react';

import PageLayout from 'components/PageLayout';
import Loader from 'components/Loader/Loader';
import BrandContainer from '../containers/BrandContainer';
import getBrand from '../queries/getBrand';
import { getIsApprovedPending } from '../helpers';
import Models from './Models';

interface State {
    showEditBrandModal: boolean;
}

interface Props {
    id: string;
}

const Brand = ({ id }: Props) => {
    const { data, error, loading, refetch } = useQuery(getBrand, {
        variables: { id },
        fetchPolicy: 'cache-and-network'
    });

    const [state, setState] = useImmer<State>({
        showEditBrandModal: false
    });

    const brand = data?.getBrand;
    const isApprovedPending = brand && getIsApprovedPending(brand?.approved);

    const toggleEditBrandModal = () =>
        setState(draft => {
            draft.showEditBrandModal = !state.showEditBrandModal;
        });

    useEffect(() => {
        if (brand && isApprovedPending) {
            setState(draft => {
                draft.showEditBrandModal = true;
            });
        }
    }, [brand, isApprovedPending, setState]);

    if (loading) {
        return <Loader size={3} autocenter className="my-4" />;
    }

    if (error) {
        return (
            <Alert color="danger" className="mt-3">
                Något gick fel, försök igen om en stund.
            </Alert>
        );
    }

    return (
        <PageLayout title={brand?.name} backBtn>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="mb-0">Lista på {brand?.name} modeller</p>
                <Button color="primary" onClick={() => toggleEditBrandModal()}>
                    Redigera märke
                </Button>
                <BrandContainer
                    brand={brand}
                    modalOpen={state.showEditBrandModal}
                    toggle={toggleEditBrandModal}
                    refetcher={refetch}
                />
            </div>

            {isApprovedPending ? <p>Detta märke är ännu inte godkänt</p> : <Models brandId={id} />}
        </PageLayout>
    );
};
export default Brand;
