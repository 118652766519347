import gql from 'graphql-tag';

export default gql`
    fragment objectHistoryFields on ObjectHistory {
        date
        vehicle_count
    }

    fragment product on Product {
        code
        end_date
        settings
    }

    fragment dealerFields on Dealer {
        id
        active
        code
        name
        city
        postalNumber

        county

        groups {
            id
            name
            code
            admin
        }
        org_number
        address
        postAddress
        phoneNumber
        emailDisplay
        emailLead
        emailImport
        emailExport
        website
        authorizedBrandsCar {
            id
            value
            name
            label
        }
        authorizedBrandsMc {
            id
            value
            name
            label
        }
        ftpNumber
        ftpAccount
        importNumber

        object_history {
            ...objectHistoryFields
        }

        locationId
        accountId
        reviewsActive

        established
        hide_dealer_api_fields

        products {
            ...product
        }

        reviewProviders {
            id
            code
            active
            accountId
            locationId
        }

        tags {
            tag
        }

        blocketStoreId
    }

    query dealerMeta($id: Int!) {
        dealerMeta(id: $id) {
            ...dealerFields
        }

        dealerGroups {
            id
            code
            name
        }
    }
`;
