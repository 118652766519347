import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, NavItem } from '@bytbil/bootstrap-react';
import TabLink from 'components/TabLink';

const propTypes = {
    activeTab: PropTypes.string.isRequired,
    showTab: PropTypes.func.isRequired
};

const tabItems = [
    {
        name: 'Takeout',
        id: 'takeout'
    },
    {
        name: 'Deletion',
        id: 'deletion'
    },
    {
        name: 'Lista',
        id: 'list'
    }
];

const Tabs = React.memo(props => {
    const { activeTab, showTab } = props;

    return (
        <Nav tabs>
            {tabItems.map(item => (
                <NavItem key={item.id}>
                    <TabLink
                        id={item.id}
                        showTab={showTab}
                        className={classNames('nav-link', activeTab === item.id && 'active')}
                    >
                        {item.name}
                    </TabLink>
                </NavItem>
            ))}
        </Nav>
    );
});

Tabs.propTypes = propTypes;

export default Tabs;
