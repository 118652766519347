import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Query, Mutation } from '@apollo/client/react/components';
import { object, string, number } from 'yup';
import { Card, CardBody } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';
import FtpUserForm from '../components/FtpUserForm';
import updateFtpUser from '../mutations/updateFtpUser';
import ftpUser from '../queries/ftpUser';

class EditFtpUser extends React.PureComponent {
    static contextType = ToastContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        onEditUserError: PropTypes.func,
        onEditUserSuccess: PropTypes.func
    };

    static defaultProps = {
        onEditUserError: Function.prototype,
        onEditUserSuccess: Function.prototype
    };

    constructor(props) {
        super(props);

        this.submitHandler = this.submitHandler.bind(this);
    }

    onEditUserSuccess() {
        this.props.onEditUserSuccess();
        this.context.showToast('Uppdaterade FTP-användaren', 'success');
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    onEditUserError() {
        this.props.onEditUserError();
        this.context.showToast('Kunde inte uppdatera FTP-användaren', 'danger');
    }

    submitHandler(mutation) {
        return async (values, methods) => {
            const { setSubmitting } = methods;

            try {
                const { data } = await mutation({
                    variables: {
                        userid: values.userid,
                        passwd: values.passwd,
                        external_id: values.externalId,
                        homedir: values.homedir
                    }
                });

                if (data.updateFtpUser.userid) {
                    this.onEditUserSuccess();
                } else {
                    this.onEditUserError();
                }
            } catch (e) {
                this.onEditUserError();
            }

            setSubmitting(false);
        };
    }

    render() {
        const { id } = this.props;

        return (
            <Query query={ftpUser} variables={{ userid: id }} fetchPolicy="cache-and-network">
                {({ loading, error, data }) => {
                    if (loading) return <div>Loading...</div>;
                    if (error) return <div>Error: {error.message}</div>;

                    const user = data && data.ftpUser;

                    return (
                        <Mutation mutation={updateFtpUser}>
                            {mutation => (
                                <PageLayout title="Redigera FTP-användare">
                                    <Card>
                                        <CardBody>
                                            {user && (
                                                <Formik
                                                    onSubmit={this.submitHandler(mutation)}
                                                    initialValues={{
                                                        userid: user.userid,
                                                        passwd: user.passwd,
                                                        externalId: user.external_id,
                                                        homedir: user.homedir
                                                    }}
                                                    validationSchema={object().shape({
                                                        passwd: string().required('Måste vara ifyllt'),
                                                        externalId: number().integer()
                                                    })}
                                                >
                                                    {props => <FtpUserForm {...props} userid={user.userid} edit />}
                                                </Formik>
                                            )}
                                        </CardBody>
                                    </Card>
                                </PageLayout>
                            )}
                        </Mutation>
                    );
                }}
            </Query>
        );
    }
}

export default EditFtpUser;
