import gql from 'graphql-tag';

export default gql`
    mutation updateModel(
        $name: String!
        $slug: String
        $id: String!
        $vehicleTypeId: String!
        $matches: [String]
        $approved: Boolean
    ) {
        updateModel(
            name: $name
            slug: $slug
            id: $id
            vehicleTypeId: $vehicleTypeId
            matches: $matches
            approved: $approved
        ) {
            status
        }
    }
`;
