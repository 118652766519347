import React from 'react';
import { useImmer } from 'use-immer';

import Loader from 'components/Loader/Loader';
import EndlessScrollCallback from 'components/EndlessScrollMarker/EndlessScrollMarker';

import { LIMIT } from './constants';
import { useUsers } from './ListUsersProvider';

interface StateProps {
    offset: number;
    /* If we are currently refetching */
    refetching: boolean;
}

const EndlessUsers = () => {
    const { users, totalUsers, loading, refetch } = useUsers();

    const [state, setState] = useImmer<StateProps>({
        // Set initial offset value to the value of LIMIT as we load that amount of users initially
        offset: LIMIT,
        refetching: false
    });

    const handleReachedEnd = async () => {
        // Prevent refetch if we are loading OR if we don't have any users yet (initial load)
        if (loading || !users) return;

        // If we have already loaded all users, don't refetch
        if (users.length >= totalUsers) return;

        setState(draft => {
            draft.refetching = true;
        });

        await refetch({ offset: state.offset, limit: LIMIT });

        setState(draft => {
            draft.offset = state.offset + LIMIT;
            draft.refetching = false;
        });
    };

    if (loading || state.refetching) {
        return <Loader size={3} autocenter />;
    }

    return <EndlessScrollCallback callBack={handleReachedEnd} />;
};

export default EndlessUsers;
