import gql from 'graphql-tag';

export default gql`
    mutation updateDealer($data: DealerInput!) {
        updateDealerAPIDealer(data: $data) {
            status
            message
        }
    }
`;
