import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Alert, Row, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

const propTypes = {
    touched: PropTypes.shape({
        jwt: PropTypes.boolean
    }).isRequired,
    values: PropTypes.shape({
        jwt: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        jwt: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    json: PropTypes.string
};

const defaultProps = {
    error: undefined,
    json: undefined
};

const JWTDecodeForm = React.memo(props => {
    const { touched, values, errors, handleChange, handleBlur, isSubmitting, handleSubmit, error, json } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xs="12" md="" lg="8" xl="6">
                    <Field
                        label="JWT-token"
                        type="textarea"
                        name="jwt"
                        rows={10}
                        touched={touched.jwt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jwt}
                        error={errors.jwt}
                    />
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12" md="auto">
                            <Button type="submit" color="primary" block loading={isSubmitting}>
                                <i className="fa fa-unlock-alt mr-2" />
                                Decode
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {error && (
                <Alert color="danger" className="mb-0 d-inline-block mt-3">
                    {error}
                </Alert>
            )}
            {json && <pre className="mb-0 mt-3">{JSON.stringify(json, null, 2)}</pre>}
        </Form>
    );
});

JWTDecodeForm.propTypes = propTypes;
JWTDecodeForm.defaultProps = defaultProps;

export default JWTDecodeForm;
