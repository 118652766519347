import React from 'react';
import { Field, Select } from '@bytbil/general-react';
import { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { Card, CardBody, Row, Col, Form } from '@bytbil/bootstrap-react';

interface Props {
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    values: FormikValues;
    handleChange(e: React.FocusEvent<HTMLInputElement>): void;
    handleBlur(e: React.FocusEvent<HTMLInputElement>): void;
    handleReset(e: React.FocusEvent<HTMLInputElement>): void;
    rolesOption: {
        id: number;
        label: string;
        value: string;
    }[];
    rolesLoading: boolean;
}

const DEFAULT_OPTIONS = [{ label: 'Välj roll', value: '' }];

const UserInfo = ({
    handleChange,
    handleBlur,
    handleReset,
    errors,
    touched,
    values,
    rolesOption,
    rolesLoading
}: Props) => (
    <Card>
        <CardBody>
            <Form noValidate name="DealerInfoForm">
                <Row>
                    <Col xs="12" md="8">
                        <Field
                            type="email"
                            id="email"
                            name="email"
                            label="E-post"
                            iconPosition="right"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onReset={handleReset}
                            error={errors.email}
                            touched={touched.email}
                            value={values.email}
                        />
                    </Col>
                    <Col xs="12" md="4">
                        <Select
                            id="roles"
                            name="roles"
                            label="Roll"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onReset={handleReset}
                            error={errors.roles}
                            touched={touched.roles}
                            value={values.roles}
                            options={[...DEFAULT_OPTIONS, ...rolesOption]}
                            disabled={rolesLoading}
                        />
                    </Col>
                </Row>
            </Form>
        </CardBody>
    </Card>
);
export default UserInfo;
