import gql from 'graphql-tag';

export default gql`
    mutation deleteOffer($id: Int!) {
        deleteOffer(where: { id: $id }) {
            success
            message
        }
    }
`;
