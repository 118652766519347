import React from 'react';
import Helmet from 'react-helmet-async';
import AddUser from 'features/users/AddUser';
import MainContainer from 'components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Bjud in användare</title>
        </Helmet>
        <MainContainer>
            <AddUser />
        </MainContainer>
    </React.Fragment>
);

export default Route;
