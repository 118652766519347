/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Table, Pagination, PaginationLink, PaginationItem, Tooltip } from '@bytbil/bootstrap-react';
import Icon from 'components/icon';
import { Link } from '@reach/router';
import { thousandFormatter } from 'core/numberFormat';

import DeleteOfferBtn from './DeleteOfferBtn';
import ResyncOfferBtn from './ResyncOfferBtn';

interface StocklistTableProps {
    vehicles: vehicleTypes[];
    totalVehicles: number;
    refetch: () => void;
}

interface vehicleTypes {
    id: string;
    regnr: string;
    mediaCount: number;
    model: string;
    price: number;
    bytbilOffer: string;
    bytbilUrl: string;
    status: string;
    visible: boolean;
    blocketOffer: string;
    blocketUrl: string;
    boostLevel: number;
}

const ITEMS_PER_PAGE = 200;

const StocklistTable = ({ vehicles, totalVehicles, refetch }: StocklistTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sort_order, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [sortField, setSortField] = useState<string | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>({});

    const toggleTooltip = (id: string) => {
        setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const offerUrl = (offerId: string) => `/offer/${offerId}/edit-offer`;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const sortedVehicles = vehicles
        .slice()
        .sort((a, b) => {
            if (sortField === 'regnr' || sortField === 'model') {
                return sort_order === 'asc'
                    ? a[sortField].localeCompare(b[sortField])
                    : b[sortField].localeCompare(a[sortField]);
            }
            if (sortField === 'price' || sortField === 'mediaCount' || sortField === 'boostLevel') {
                return sort_order === 'asc' ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
            }
            return 0;
        })
        .slice(startIndex, endIndex);

    const handleSort = (field: string) => {
        if (field === sortField) {
            setSortOrder(sort_order === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    const displaySortorder = (field: string) => {
        if (field === sortField) {
            return sort_order === 'asc' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <div>
            <p>
                Visar {vehicles.length} av {totalVehicles} annonser
            </p>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('boostLevel')} className="sortable-table-header pr-0">
                            <Icon name="star" /> {displaySortorder('boostLevel')}
                        </th>
                        <th onClick={() => handleSort('regnr')} className="sortable-table-header">
                            <span className="d-flex">Reg.nr {displaySortorder('regnr')}</span>
                        </th>
                        <th onClick={() => handleSort('mediaCount')} className="text-right sortable-table-header">
                            <Icon name="camera" /> {displaySortorder('mediaCount')}
                        </th>
                        <th
                            style={{ maxWidth: '500px' }}
                            onClick={() => handleSort('model')}
                            className="sortable-table-header"
                        >
                            Märke & Modell {displaySortorder('model')}
                        </th>
                        <th onClick={() => handleSort('price')} className="text-right sortable-table-header">
                            Pris (kr) {displaySortorder('price')}
                        </th>
                        <th>Annons</th>
                        <th>Status</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedVehicles.map(
                        ({
                            id,
                            regnr,
                            mediaCount,
                            model,
                            price,
                            bytbilOffer,
                            bytbilUrl,
                            visible,
                            status,
                            blocketOffer,
                            blocketUrl,
                            boostLevel
                        }) => (
                            <tr key={id} className={`table-style-props position-relative ${visible ? '' : 'grey-row'}`}>
                                <td className="pr-0">
                                    {boostLevel === 20 && (
                                        <div>
                                            <i className="fa fa-star text-success" id={`Premiumannons-${id}`} />
                                            <Tooltip
                                                placement="bottom"
                                                isOpen={tooltipOpen[`Premiumannons-${id}`]}
                                                target={`Premiumannons-${id}`}
                                                toggle={() => toggleTooltip(`Premiumannons-${id}`)}
                                            >
                                                Premiumannons
                                            </Tooltip>
                                        </div>
                                    )}
                                </td>
                                <td className="p-0">
                                    <Link to={offerUrl(id)} className="d-block text-decoration-none text-dark m-0 p-2">
                                        {regnr}
                                    </Link>
                                </td>
                                <td className="p-0 text-center">
                                    <Link to={offerUrl(id)} className="d-block text-decoration-none text-dark m-0 p-2">
                                        {mediaCount}
                                    </Link>
                                </td>
                                <td className="p-0">
                                    <Link
                                        to={offerUrl(id)}
                                        className="d-block text-decoration-none text-dark m-0 p-2 text-truncate"
                                        style={{ maxWidth: '500px' }}
                                    >
                                        {model}
                                    </Link>
                                </td>
                                <td className="text-right p-0">
                                    <Link
                                        to={offerUrl(id)}
                                        className="d-block text-decoration-none text-dark m-0 p-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        {thousandFormatter(price, ' kr')}
                                    </Link>
                                </td>
                                <td className="px-0">
                                    {!!bytbilOffer && visible === true && (
                                        <a
                                            className="mx-1 pl-2"
                                            href={bytbilUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Bytbil
                                        </a>
                                    )}
                                    {!!blocketOffer && visible === true && (
                                        <a href={blocketUrl} target="_blank" rel="noopener noreferrer">
                                            Blocket
                                        </a>
                                    )}
                                </td>
                                <td className="p-0">
                                    <Link to={offerUrl(id)} className="d-block text-decoration-none text-dark m-0 p-2">
                                        {visible ? <span>Aktiv</span> : ''}

                                        {!visible && status !== 'reserved' ? (
                                            <span>
                                                <i className="fa fa-eye-slash mr-1" /> Dold
                                            </span>
                                        ) : (
                                            ''
                                        )}

                                        {!visible && status === 'reserved' ? <span>Reserverad</span> : ''}
                                    </Link>
                                </td>
                                <td className="px-0">
                                    <div className="d-flex px-2 pr-3 ml-1">
                                        <div>
                                            <ResyncOfferBtn offerId={id} />
                                        </div>
                                        <div className="ml-3">
                                            <DeleteOfferBtn
                                                offerId={Number(id)}
                                                offerName={model}
                                                offerRegnr={regnr}
                                                refetch={refetch}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>

            {vehicles.length > 200 && (
                <div>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)} />
                        </PaginationItem>

                        {Array.from({ length: Math.ceil(vehicles.length / ITEMS_PER_PAGE) }).map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => setCurrentPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}

                        <PaginationItem disabled={currentPage === Math.ceil(vehicles.length / ITEMS_PER_PAGE)}>
                            <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </div>
            )}
        </div>
    );
};

export default StocklistTable;
