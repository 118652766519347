import React from 'react';
import { Link } from '@reach/router';
import { Table } from '@bytbil/bootstrap-react';
import { Brand } from '../types';
import ApprovedIcon from './common/ApprovedIcon';

interface Props {
    brands: Brand[];
}

const BrandsTable = ({ brands }: Props) => (
    <Table responsive bordered>
        <thead>
            <tr>
                <th>Märke</th>
                <th>Status</th>
                <th>Liknande träffar</th>
            </tr>
        </thead>
        <tbody>
            {brands.map(({ id, name, approved, matches }) => (
                <tr key={id}>
                    <td>
                        <Link to={`/brands/${id}`}>{name}</Link>
                    </td>
                    <td>
                        <ApprovedIcon approved={approved} id={id} />
                    </td>
                    <td>{matches.join(', ')}</td>
                </tr>
            ))}
        </tbody>
    </Table>
);

export default BrandsTable;
