import React from 'react';
import Helmet from 'react-helmet-async';
import UserSearch from 'features/dealer-search';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Sök handlare</title>
        </Helmet>
        <MainContainer>
            <UserSearch />
        </MainContainer>
    </React.Fragment>
);

export default Route;
