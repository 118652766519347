import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { ToastContext } from 'context/ToastContext';
import { DealerFormProps } from 'types';

import normalizeDealer from '../helpers/normalizeDealer';

import editDealerQuery from '../queries/editDealer';
import updateDealerMutation from '../mutations/updateDealer';
import DealerForm from '../components/DealerForm';
import dataMapper from '../helpers/dataMapper';

interface Props {
    dealerId: number;

    dealerForm: DealerFormProps;
}

const DealerEditContainer = (props: Props) => {
    const { dealerId, dealerForm } = props;

    const [updateDealer] = useMutation(updateDealerMutation);

    const Toaster = useContext(ToastContext);

    const {
        loading,
        error,
        data: dealerData
    } = useQuery(editDealerQuery, {
        variables: {
            id: dealerId
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading && !dealerData) {
        return <p>Laddar...</p>;
    }
    if (error) {
        return <p>Något gick fel...</p>;
    }

    const dealer = normalizeDealer(dealerData.dealerMeta);

    const submitHandler = async ({ values }) => {
        try {
            const { data } = await updateDealer({
                variables: {
                    data: {
                        ...dataMapper({ values, products: dealerForm.products })
                    }
                }
            });

            if (data?.updateDealerAPIDealer?.status === 200) {
                return Toaster.showToast('Ändringarna sparades.');
            }
            return Toaster.showToast('API:et svarade inte som förväntat', 'danger');
        } catch (err) {
            const detail = err?.graphQLErrors?.[0].data?.body?.detail;

            const errorMsg = detail.includes('Blocket product not available')
                ? 'Det går inte att välja Blocket som produkt, Butiks-ID saknas'
                : 'Något gick fel';

            return Toaster.showToast(errorMsg, 'danger', 10000);
        }
    };

    if (typeof dealer !== 'undefined') {
        return (
            <DealerForm
                edit
                dealerGroups={dealerData.dealerGroups}
                dealer={dealer}
                toaster={Toaster}
                dealerForm={dealerForm}
                submitHandler={submitHandler}
            />
        );
    }

    return <div />;
};

export default DealerEditContainer;
