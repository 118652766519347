import gql from 'graphql-tag';

export default gql`
    query getOfferStocklistByDealer($dealerId: Int) {
        getOfferStocklistByDealer(dealerId: $dealerId) {
            id
            regnr
            model
            price
            sellerId
            bytbilOffer
            bytbilUrl
            blocketOffer
            blocketUrl
            mediaCount
            status
            visible
            boostLevel
        }
    }
`;
