import React from 'react';
import { Table, CardBody, Button } from '@bytbil/bootstrap-react';
import { DealerInfo } from '../types';

interface Props {
    data: DealerInfo[];
    checkedIds: string[];
    selectedDealers: DealerInfo[];
    setDealers(value: DealerInfo[]): void;
    selectDealers(value: number[]): void;
}

const DealerTable = ({ data, checkedIds, setDealers, selectedDealers, selectDealers }: Props) => {
    const handleAddDealer = e => {
        const checkedId = e.currentTarget.id;
        const newDealer = selectedDealers.findIndex(dealer => dealer.id.toString() === checkedId) < 0;

        if (newDealer) {
            const newDealerObject = data.filter(dealer => dealer.id.toString() === checkedId);
            const newSelectedDealerArray = [...selectedDealers, ...newDealerObject];
            setDealers(newSelectedDealerArray);

            const checkedIdsList = [...checkedIds, checkedId];
            selectDealers(checkedIdsList);
        }
    };

    return (
        <CardBody style={{ borderTop: '1px solid #e8e8e8' }}>
            <h5>Anläggningar</h5>
            <Table responsive bordered className="mb-0">
                <thead>
                    <tr>
                        <th key="head-code">Handlarkod</th>
                        <th key="head-name">Företagsnam</th>
                        <th key="head-id">Handlar-ID</th>
                        <th key="head-group">Grupp</th>
                        <th key="head-city">Ort</th>
                        <th key="head-action" />
                    </tr>
                </thead>
                <tbody>
                    {data.map(dealer => (
                        <tr key={dealer.id}>
                            <td key={`code-${dealer.id}`}>{dealer.code}</td>
                            <td key={`name-${dealer.id}`}>{dealer.name}</td>
                            <td key={`id-${dealer.id}`}>{dealer.id}</td>
                            <td key={`groups-${dealer.id}`}>{dealer.groups.map(group => group.code)}</td>
                            <td key={`city-${dealer.id}`}>{dealer.city}</td>
                            <td key={`action-${dealer.id}`}>
                                <Button
                                    disabled={checkedIds.includes(dealer.id.toString())}
                                    id={dealer.id}
                                    color="primary"
                                    className=""
                                    type=""
                                    onClick={handleAddDealer}
                                >
                                    Lägg till
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </CardBody>
    );
};

export default DealerTable;
