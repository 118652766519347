import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Alert, Card, CardBody } from '@bytbil/bootstrap-react';
import { PageLoader } from '@bytbil/general-react';
import { Mutation, Query } from '@apollo/client/react/components';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';
import FtpUserTable from '../components/FtpUserTable';
import ftpUsersQuery from '../queries/ftpUsers';
import deleteFtpUser from '../mutations/deleteFtpUser';

class FtpUsers extends React.PureComponent {
    static contextType = ToastContext;

    static propTypes = {
        onDeleteUserSuccess: PropTypes.func,
        onDeleteUserError: PropTypes.func
    };

    static defaultProps = {
        onDeleteUserSuccess: Function.prototype,
        onDeleteUserError: Function.prototype
    };

    constructor(props) {
        super(props);

        this.state = {
            deleteUser: {
                error: false,
                loading: false,
                success: undefined
            }
        };

        this.deleteFtpUser = this.deleteFtpUser.bind(this);
    }

    onDeleteSuccess() {
        this.props.onDeleteUserSuccess();
        this.context.showToast('FTP-användaren togs bort');

        this.setState(
            produce(draft => {
                draft.deleteUser = {
                    error: false,
                    loading: false,
                    success: true
                };
            })
        );
    }

    onDeleteError() {
        this.props.onDeleteUserError();
        this.context.showToast('Kunde inte tabort FTP-användaren', 'danger');

        this.setState(
            produce(draft => {
                draft.deleteUser = {
                    error: true,
                    loading: false,
                    success: false
                };
            })
        );
    }

    deleteFtpUser(mutation, refetch) {
        return async userid => {
            this.setState(
                produce(draft => {
                    draft.deleteUser = {
                        error: false,
                        loading: true,
                        success: undefined
                    };
                })
            );

            const { data } = await mutation({
                variables: { userid }
            });

            if (data.deleteFtpUser.userid) {
                this.onDeleteSuccess();
                refetch();
            } else {
                this.onDeleteError();
            }
        };
    }

    render() {
        const { deleteUser } = this.state;

        return (
            <Query query={ftpUsersQuery} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch }) => {
                    if (loading || deleteUser.loading) return <PageLoader show />;
                    if (error || deleteUser.error)
                        return <Alert color="danger">Något gick fel, försök igen om en stund.</Alert>;

                    const { ftpUsers: users } = data;

                    return (
                        <PageLayout title="FTP-användare">
                            <p>
                                Här är en lista på alla FTP-användare. Tryck på en användare för att redigera dess
                                detaljer.
                            </p>
                            {users && users.length > 0 && (
                                <Card>
                                    <CardBody>
                                        <Mutation mutation={deleteFtpUser} refetchQueries={[{ query: ftpUsersQuery }]}>
                                            {mutation => (
                                                <FtpUserTable
                                                    users={users}
                                                    onDelete={this.deleteFtpUser(mutation, refetch)}
                                                />
                                            )}
                                        </Mutation>
                                    </CardBody>
                                </Card>
                            )}
                        </PageLayout>
                    );
                }}
            </Query>
        );
    }
}

export default FtpUsers;
