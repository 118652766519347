import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';

const propTypes = {
    name: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired
};

const ApiGroupRow = React.memo(({ name, onDelete }) => (
    <tr>
        <td>
            <Link to={`/api-group/edit/${name}`}>{name}</Link>
        </td>
        <td>
            <Button color="danger" onClick={onDelete(name)}>
                Ta bort
            </Button>
        </td>
    </tr>
));

ApiGroupRow.propTypes = propTypes;

export default ApiGroupRow;
