import React from 'react';

import { Table } from '@bytbil/bootstrap-react';

import DealerGroupRow from './DealerGroupRow';

import { DealerGroup } from '../types';

interface Props {
    groups: DealerGroup[];
    onDelete(id: number): void;
}

const DealerGroupTable = ({ groups, onDelete }: Props) => (
    <Table responsive bordered className="mb-0">
        <thead>
            <tr>
                <th>Gruppnamn</th>
                <th className="text-center" style={{ width: 102 }}>
                    <i className="fa fa-trash" />
                </th>
            </tr>
        </thead>
        <tbody>
            {groups.map(group => (
                <DealerGroupRow key={group.id} id={group.id} name={group.name} onDelete={onDelete} />
            ))}
        </tbody>
    </Table>
);

export default DealerGroupTable;
