import React from 'react';
import { FormikProps, FormikValues, useFormikContext } from 'formik';

import { Card, CardBody, Col, Row } from '@bytbil/bootstrap-react';
import { MultipleValueInput } from '@bytbil/general-react';

const TagsForm = () => {
    const { values, setFieldValue, setFieldTouched }: FormikProps<FormikValues> = useFormikContext();

    const blurHandler = key => () => {
        setFieldTouched(key, true);
    };

    const changeHandler = key => value => {
        setFieldValue(key, value);
    };
    return (
        <Card className="mt-3">
            <CardBody>
                <h3>Taggar</h3>
                <p>Föredetta kategorifältet - de flesta taggar är ersatta av Produkter enligt ovan</p>

                <Row className="mt-3">
                    <Col xs="12" lg="5" xl="4">
                        <MultipleValueInput
                            value={values.tags}
                            label="Lägg till Taggar"
                            className="mt-3"
                            name="tags"
                            onChange={changeHandler}
                            onBlur={blurHandler}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default TagsForm;
