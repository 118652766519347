import gql from 'graphql-tag';

export default gql`
    mutation SyncOffersByDealerCode($dealerCode: String!) {
        syncOffersByDealerCode(dealerCode: $dealerCode) {
            success
            message
        }
    }
`;
