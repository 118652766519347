import { useRef, useEffect, useState } from 'react';

interface Props {
    root: null;
    rootMargin: string;
    threshold: number;
    callBack(): void;
}

const useElementOnScreen = (options: Props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const callbackFunction = entries => {
            const [entry] = entries;
            setIsVisible(entry.isIntersecting);

            if (entry.isIntersecting) {
                options.callBack();
            }
        };

        // Fixes an issue when tests using this hook were run
        // https://stackoverflow.com/questions/59424347/gatsby-intersectionobserver-is-not-defined
        if (!window.IntersectionObserver) return;

        const observer = new IntersectionObserver(callbackFunction, options);
        const currentRef = componentRef.current;
        if (currentRef) observer.observe(currentRef);

        // eslint-disable-next-line consistent-return
        return () => {
            if (currentRef) observer.unobserve(currentRef);
        };
    }, [componentRef, options]);

    return [{ componentRef, isVisible }];
};

export default useElementOnScreen;
