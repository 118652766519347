import gql from 'graphql-tag';

export default gql`
    query getDealer($code: String!) {
        dealerByCode(code: $code) {
            id
            code
        }
    }
`;
