import React from 'react';
import { Link } from '@reach/router';

import { Button } from '@bytbil/bootstrap-react';
import { useUsers } from '../ListUsersProvider';

const UserListLegend = () => {
    const { users, totalUsers } = useUsers();

    if (!users) {
        return null;
    }

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="font-weight-bold d-flex">
                Visar {users.length} av {totalUsers} användare
            </div>
            <div>
                <Button color="primary" tag={Link} to="/users/add">
                    <i className="fa fa-user-plus pr-1" />
                    Bjud in användare
                </Button>
            </div>
        </div>
    );
};

export default UserListLegend;
