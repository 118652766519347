import gql from 'graphql-tag';

export default gql`
    query privacyRequests($from: String!, $to: String!) {
        privacyRequests(from: $from, to: $to) {
            id
            entityID
            action
            docType
            state
            created
            requestSent
            responseCompleted
            done
        }
    }
`;
