import gql from 'graphql-tag';

export default gql`
    mutation updateDealerGroup($id: Int!, $memberships: [DealerMembershipInput!]!) {
        updateDealerGroup(where: { id: $id }, data: { memberships: $memberships }) {
            memberships {
                id
            }
        }
    }
`;
