import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Mutation } from '@apollo/client/react/components';
import { Alert } from '@bytbil/bootstrap-react';

import CreateApiUserForm from '../components/CreateApiUserForm';
import createApiUserMutation from '../mutations/createApiUser';

const propTypes = {
    onCreated: PropTypes.func.isRequired
};

class CreateUser extends React.PureComponent {
    constructor(props) {
        super(props);

        this.createApiUser = this.createApiUser.bind(this);

        this.state = {
            error: false
        };
    }

    createApiUser(mutation) {
        return async (values, methods) => {
            const { onCreated } = this.props;
            const { setSubmitting } = methods;

            try {
                await mutation({ mutation: createApiUserMutation, variables: values });
                await onCreated();

                this.setState(
                    produce(draft => {
                        draft.error = false;
                    })
                );
            } catch (error) {
                this.setState(
                    produce(draft => {
                        draft.error = true;
                    })
                );
            }

            setSubmitting(false);
        };
    }

    render() {
        const { error } = this.state;

        return (
            <Mutation mutation={createApiUserMutation}>
                {mutation => (
                    <React.Fragment>
                        <Formik
                            onSubmit={this.createApiUser(mutation)}
                            validationSchema={object().shape({
                                username: string()
                                    .min(2, 'Användarnamnet måste innehålla åtminstone två tecken')
                                    .max(30, 'Användarnamnet får max innehålla 30 tecken')
                                    .matches(
                                        /^[a-zA-Z0-9_.-]+$/,
                                        'Användarnamnet får endast innehålla a-z, 0-9, (_) och (.)'
                                    )
                                    .required('Måste fyllas i')
                            })}
                            initialValues={{
                                username: ''
                            }}
                        >
                            {props => <CreateApiUserForm {...props} />}
                        </Formik>
                        {error && (
                            <Alert className="mt-3 mb-0 d-inline-block" color="danger">
                                Något gick fel
                            </Alert>
                        )}
                    </React.Fragment>
                )}
            </Mutation>
        );
    }
}

CreateUser.propTypes = propTypes;

export default CreateUser;
