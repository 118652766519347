import React from 'react';
import Helmet from 'react-helmet-async';
import MainContainer from 'components/MainContainer';
import Brand from 'features/brands/components/Brand';

const Route = React.memo(({ id }) => (
    <React.Fragment>
        <Helmet>
            <title>Märken/Modeller</title>
        </Helmet>
        <MainContainer>
            <Brand id={id} />
        </MainContainer>
    </React.Fragment>
));

export default Route;
