import gql from 'graphql-tag';

export default gql`
    query imports($dealerId: Int!) {
        imports(dealerId: $dealerId) {
            id
            log
            modified
            count {
                added
                updated
                deleted
                errors
            }
        }
        importFile(dealerId: $dealerId)
    }
`;
