import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';

const propTypes = {
    username: PropTypes.string.isRequired,
    onRegeneratePassword: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

const ApiUserRow = React.memo(({ username, onRegeneratePassword, onDelete }) => (
    <tr>
        <td>
            <Link to={`/api-tokens/${username}`}>{username}</Link>
        </td>
        <td>
            <Button className="ApiUserRow-regenerate-password" onClick={onRegeneratePassword(username)}>
                Nytt lösenord
            </Button>
        </td>
        <td>
            <Button className="ApiUserRow-delete-user" color="danger" onClick={onDelete(username)}>
                Ta bort
            </Button>
        </td>
    </tr>
));

ApiUserRow.propTypes = propTypes;

export default ApiUserRow;
