import React from 'react';
import Dealer from 'features/dealer-api';
import Helmet from 'react-helmet-async';
import MainContainer from 'components/MainContainer';

interface Props {
    dealerId: string | undefined;
}

const action = ({ dealerId }: Props) => (
    <React.Fragment>
        <Helmet>
            <title>{dealerId ? 'Hantera handlare' : 'Lägg till handlare'}</title>
        </Helmet>
        <MainContainer>
            <Dealer dealerId={dealerId ? Number.parseInt(dealerId, 10) : undefined} />
        </MainContainer>
    </React.Fragment>
);

export default action;
