import React from 'react';
import Helmet from 'react-helmet-async';

import MainContainer from '../../components/MainContainer';
import UserAdd from '../../features/user-add';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Skapa Toolbox-användare</title>
        </Helmet>
        <MainContainer>
            <h1>Skapa Toolbox-användare</h1>
            <UserAdd />
        </MainContainer>
    </React.Fragment>
);

export default Route;
