import gql from 'graphql-tag';

export default gql`
    query apiUser($username: String!) {
        apiUser(username: $username) {
            keys {
                id
                jti
                expiration
                scopes {
                    name
                    value
                }
            }
        }
    }
`;
