import gql from 'graphql-tag';

export default gql`
    mutation EditUser($dealerIds: [String], $roles: String, $uuid: String, $email: String) {
        updateUser(dealerIds: $dealerIds, roles: $roles, uuid: $uuid, email: $email) {
            roles {
                id
                name
            }
        }
    }
`;
