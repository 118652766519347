import gql from 'graphql-tag';

export default gql`
    query DealerForm {
        dealerForm {
            products {
                name
                code
                description
                defaultActive: default_active
                settings_schema {
                    type
                    properties
                }
            }

            brands {
                id
                name
                slug
                description
            }

            dealerGroups {
                id
                name
                code
            }
        }
    }
`;
