import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Button, Row } from '@bytbil/bootstrap-react';
import { Field, Radio } from '@bytbil/general-react';

const docTypes = [
    { label: 'Organisationsnummer', value: 'Dealer' },
    { label: 'SPID-ID', value: 'SiteUser' }
];

const propTypes = {
    touched: PropTypes.shape({
        organizationNumber: PropTypes.bool
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        organizationNumber: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.shape({
        organizationNumber: PropTypes.string
    }).isRequired,
    isFetching: PropTypes.bool.isRequired,
    buttonColor: PropTypes.string.isRequired,
    buttonIcon: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired
};

const DealerPrivacyForm = React.memo(props => {
    const {
        touched,
        handleSubmit,
        errors,
        handleChange,
        handleBlur,
        values,
        buttonColor,
        buttonIcon,
        buttonText,
        isFetching
    } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xs="12">
                    <Radio
                        name="docType"
                        label="Typ"
                        id="docType"
                        toggleButton
                        options={docTypes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.docType}
                    />
                </Col>
                <Col xs="12" lg="5" xl="4">
                    <Field
                        label={values.docType === 'Dealer' ? 'Organisationsnummer' : 'SPID-ID'}
                        type="text"
                        name="entityID"
                        id="entityID"
                        touched={touched.entityID}
                        error={errors.entityID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.entityID}
                        placeholder={values.docType === 'Dealer' ? '556036-0793' : '10808389'}
                    />
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12" md="auto">
                            <Button type="submit" color={buttonColor} block loading={isFetching}>
                                <i className={`fa fa-${buttonIcon} mr-2`} />
                                {buttonText}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
});

DealerPrivacyForm.propTypes = propTypes;

export default DealerPrivacyForm;
