import React, { useContext } from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Row, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

import { ToastContext } from 'context/ToastContext';

import SYNC_OFFERS_MUTATION from '../mutations/syncOffers';

interface FormValues {
    dealerCode: string;
}

interface OtherProps {
    loading: boolean;
}

const InnerForm = ({
    values,
    touched,
    errors,
    loading,
    handleChange,
    handleBlur
}: OtherProps & FormikProps<FormValues>) => {
    const handleFieldClick = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    return (
        <Form>
            <Row>
                <Col xs="12" md="" lg="5" xl="4">
                    <Field
                        label="Handlarkod"
                        type="text"
                        name="dealerCode"
                        placeholder="bossesbilar"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={handleFieldClick}
                        touched={touched.dealerCode}
                        value={values.dealerCode}
                        error={errors.dealerCode}
                    />
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12" md="auto">
                            <Button type="submit" color="primary" block loading={loading}>
                                <i className="fa fa-refresh mr-2" />
                                Uppdatera annonser
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

interface MyFormProps {
    loading: boolean;
    handleSubmit(id: string): void;
}

const MyForm = withFormik<MyFormProps, FormValues>({
    mapPropsToValues: () => ({
        dealerCode: ''
    }),

    validationSchema: object().shape({
        dealerCode: string().required('Ange handlarkod')
    }),

    handleSubmit: (values, formikBag) => {
        formikBag.props.handleSubmit(values.dealerCode);
    }
})(InnerForm);

const ResyncVehicles = () => {
    const Toaster = useContext(ToastContext);

    const [syncOffer, { loading, error }] = useMutation(SYNC_OFFERS_MUTATION);

    const handleSyncVehicles = async (dealerCode: string) => {
        try {
            const {
                data: {
                    syncOffersByDealerCode: { success, message }
                }
            } = await syncOffer({
                variables: { dealerCode },

                optimisticResponse: {
                    syncOffersByDealerCode: {
                        success: true,
                        __typename: '"MutationResponse"',
                        message:
                            'Annonserna håller på att synkas om... observera att detta kan ta en stund beroende på handlarens lagerstorlek...'
                    }
                }
            });

            if (error) {
                return Toaster.showToast(message, 'danger', 5000);
            }

            if (success) {
                return Toaster.showToast(message);
            }

            return Toaster.showToast(message, 'danger', 5000);
        } catch (e) {
            return Toaster.showToast(
                'Det tog lång tid att få svar från servern - förmodligen kommer annonserna synkas om om ett antal minuter. Vänligen kontrollera lagret innan du testar att synka om igen',
                'warning',
                10000
            );
        }
    };

    return <MyForm handleSubmit={handleSyncVehicles} loading={loading} />;
};

export default ResyncVehicles;
