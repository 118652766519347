const objectArrayToCSV = (data, dataMapper, delimiter = ';') =>
    `\uFEFF${data.reduce((acc, object) => {
        let csv = acc;
        const col = [];
        Object.keys(object).forEach(key => {
            const columnData = dataMapper(key, object[key]);

            if (typeof columnData !== 'undefined') {
                col.push(`${columnData}`);
            }
        });

        csv += `${col.join(delimiter)}\r\n`;

        return csv;
    }, '')}`;

export default objectArrayToCSV;
