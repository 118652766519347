import React from 'react';
import classNames from 'classnames';
import ArrowBackButton from 'components/Button/ArrowBackBtn';

interface Props {
    children: React.ReactNode;
    title: string;
    backBtn?: boolean;
}

const PageLayout = React.memo(({ children, title, backBtn }: Props) => (
    <React.Fragment>
        <div className={classNames(backBtn && 'd-flex align-items-center')}>
            {backBtn && <ArrowBackButton />}
            <h1 className={classNames('page-title', backBtn && 'mb-0')}>{title}</h1>
        </div>
        <div className="mb-3">{children}</div>
    </React.Fragment>
));

export default PageLayout;
