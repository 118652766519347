import gql from 'graphql-tag';

export default gql`
    query apiGroups {
        apiGroups {
            name
            dealers {
                id
                name
            }
        }
    }
`;
