import gql from 'graphql-tag';

export default gql`
    mutation createUser($user: String!, $name: String!, $email: String!, $password: String!) {
        createUser(user: $user, name: $name, email: $email, password: $password) {
            added
            status
        }
    }
`;
