import gql from 'graphql-tag';

export default gql`
    query dealerGroups {
        dealerGroups {
            id
            code
            name
            # memberships {
            #     id
            # }
        }
    }
`;
