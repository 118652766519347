import React from 'react';
import { Button } from '@bytbil/bootstrap-react';
import Icon from 'components/icon';

const ArrowBackButton = () => (
    <Button onClick={() => window.history.go(-1)} color="link" className="text-dark pl-0">
        <Icon name="arrow-left" size="lg" verticalAlign="0" />
    </Button>
);

export default ArrowBackButton;
