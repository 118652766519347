import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Alert } from '@bytbil/bootstrap-react';
import { PageLoader } from '@bytbil/general-react';
import { ToastContext } from 'context/ToastContext';

import DealerGroupForm from './DealerGroupForm';

import dealerGroupQuery from '../queries/dealerGroup';
import updateDealerGroupMutation from '../mutations/updateDealerGroup';

interface Props {
    id: string;
}

const EditGroup: React.FunctionComponent<Props> = ({ id }: Props) => {
    const [updateDealerGroup] = useMutation(updateDealerGroupMutation);
    const toaster = useContext(ToastContext);

    const [showActionPanel, setShowActionPanel] = useState(false);

    const {
        data: dealerGroupData,
        loading,
        error: queryError
    } = useQuery(dealerGroupQuery, {
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    });

    if (queryError) {
        return <Alert color="danger">Kunde inte hämta data för gruppen.</Alert>;
    }
    if (loading) {
        return <PageLoader show />;
    }

    let dealerData = { ids: [], meta: {} };
    if (dealerGroupData?.dealerGroup?.memberships) {
        dealerData = dealerGroupData.dealerGroup.memberships.reduce((acc, dealer) => {
            acc.ids.push(dealer.id);

            return acc;
        }, dealerData);
    }

    const [groupAdmin] = dealerGroupData?.dealerGroup?.memberships.filter(membership => membership.admin) || {};

    let groupAdminId = '-1';

    if (groupAdmin?.id) {
        groupAdminId = groupAdmin.id.toString();
    }

    const defaultInitialValues = {
        name: '',
        code: '',
        dealerIds: [],
        groupAdmin: groupAdminId
    };

    const initialValues = dealerGroupData?.dealerGroup
        ? {
              name: dealerGroupData.dealerGroup.name,
              code: dealerGroupData.dealerGroup.code,
              dealerIds: dealerData.ids,
              groupAdmin: groupAdminId
          }
        : defaultInitialValues;

    const submitHandler = async variables => {
        const memberships = variables.dealerIds.map(dealerId => ({
            id: dealerId,
            admin: dealerId === variables.groupAdmin
        }));

        await updateDealerGroup({
            variables: {
                ...variables,
                id: parseInt(id, 10),
                memberships
            }
        });

        setShowActionPanel(false);

        return toaster.showToast('Ändringarna sparades.');
    };

    return (
        <DealerGroupForm
            edit
            title="Uppdatera handlargrupp"
            initialValues={initialValues}
            toaster={toaster}
            showActionPanel={showActionPanel}
            setShowActionPanel={setShowActionPanel}
            submitHandler={submitHandler}
        />
    );
};

export default EditGroup;
