import React, { ChangeEvent } from 'react';
import { Input, Label, InputWrapper, InputIcon, Row, Col } from '@bytbil/bootstrap-react';
import { Select } from '@bytbil/general-react';

interface StocklistFilterProps {
    filter: string;
    status: string;
    onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onStatusChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StocklistFilter = ({ filter, status, onFilterChange, onStatusChange }: StocklistFilterProps) => {
    const FILTER_OPTIONS = [
        { label: 'Alla annonser', value: '' },
        { label: 'Aktiv', value: 'visible' },
        { label: 'Dold', value: 'hidden' },
        { label: 'Reserverad', value: 'reserved' }
    ];

    return (
        <div>
            <Row>
                <Col>
                    <Label htmlFor="filtrera">Filtrera</Label>
                    <InputWrapper left>
                        <InputIcon icon="fa-search" position="left" />
                        <Input
                            type="text"
                            name="Filtrera"
                            id="filtrera"
                            placeholder="T.ex reg.nr, märke, modell el. pris"
                            value={filter}
                            onChange={onFilterChange}
                        />
                    </InputWrapper>
                </Col>
                <Col>
                    <Select
                        name="Filtrera"
                        id="filtrera"
                        options={FILTER_OPTIONS}
                        label="Status"
                        value={status}
                        onChange={onStatusChange}
                    />
                </Col>
            </Row>
        </div>
    );
};
export default StocklistFilter;
