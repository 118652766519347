import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { ToastContext } from 'context/ToastContext';
import { Formik, FormikValues } from 'formik';
import { object, string } from 'yup';

import { Brand } from '../types';
import updateBrandMutation from '../mutations/updateBrand';
import addBrandMutation from '../mutations/addBrand';
import ModalForm from '../components/common/ModalForm';
import { getApprovedOptions, getBrandValues, getIsApprovedPending } from '../helpers';

const validationSchema = object().shape({
    name: string().required('Ange ett märke'),
    approved: string().matches(/^(?!null\b)/i, 'Godkänn eller neka märket')
});

interface Props {
    brand?: Brand;
    modalOpen: boolean;
    toggle(): void;
    refetcher(): void;
}

const BrandContainer = ({ brand, modalOpen, toggle, refetcher }: Props) => {
    const Toaster = useContext(ToastContext);

    const [addMutation] = useMutation(addBrandMutation);
    const [updateMutation] = useMutation(updateBrandMutation);

    const addBrand = async ({ name, matches }: FormikValues, { setFieldError, resetForm }) => {
        try {
            const { data } = await addMutation({
                variables: {
                    name,
                    matches,
                    approved: true
                }
            });

            const { status } = data?.addBrand;

            switch (status) {
                case 201:
                    Toaster.showToast(`Märket ${name} har lagts till`);

                    refetcher();
                    resetForm();
                    toggle();
                    break;
                case 409:
                    setFieldError('name', 'Märket finns redan');
                    break;
                default:
                    Toaster.showToast('Något gick fel', 'danger');
            }
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const editBrand = async ({ name, slug, matches, approved }: FormikValues, { setFieldError }) => {
        try {
            const { data } = await updateMutation({
                variables: {
                    name,
                    slug,
                    id: brand?.id,
                    matches,
                    approved: approved === 'true'
                }
            });

            const { status } = data?.updateBrand;

            switch (status) {
                case 200:
                    Toaster.showToast(`Märket uppdaterades`);

                    refetcher();
                    toggle();
                    break;
                case 409:
                    setFieldError('name', 'Märket finns redan');
                    break;
                default:
                    Toaster.showToast('Något gick fel', 'danger');
            }
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const edit = !!brand;

    const approvedOptions = edit ? getApprovedOptions({ isBrand: true }) : [];
    const isApprovedPending = edit && getIsApprovedPending(brand.approved);
    const initialValues = getBrandValues(brand);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={edit ? editBrand : addBrand}
            validationSchema={validationSchema}
        >
            {props => (
                <ModalForm
                    {...props}
                    edit={edit}
                    id={edit ? brand.id : ''}
                    modalOpen={modalOpen}
                    title={edit ? 'Redigera märke' : 'Lägg till märke'}
                    toggle={toggle}
                    isBrand
                    approvedOptions={approvedOptions}
                    isApprovedPending={isApprovedPending}
                />
            )}
        </Formik>
    );
};

export default BrandContainer;
