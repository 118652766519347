import React, { useContext } from 'react';
import { object, string, ref } from 'yup';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import PageLayout from 'components/PageLayout';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { ToastContext } from 'context/ToastContext';
import PasswordForm from './components/PasswordForm';
import changePasswordMutation from './mutations/changePassword';

const ChangePassword = () => {
    const [changePassword] = useMutation(changePasswordMutation);
    const toaster = useContext(ToastContext);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            await changePassword({
                variables: {
                    data: {
                        user: values.user,
                        password: values.password
                    }
                }
            });
            resetForm();
            return toaster.showToast('Lösenordet ändrades!', 'success');
        } catch (e) {
            toaster.showToast('Något gick fel!', 'danger');
            return console.error(e);
        }
    };

    return (
        <PageLayout title="Ändra lösenord för Toolbox-användare">
            <Card>
                <CardBody>
                    <Formik
                        onSubmit={handleSubmit}
                        validationSchema={object().shape({
                            user: string().required('Ange användare'),
                            password: string()
                                .min(6, 'Lösenordet måste vara minst sex tecken långt')
                                .matches(/^[^åäö]*$/, 'Lösenordet får inte innehålla Å, Ä eller Ö')
                                .required('Ange nytt lösenord'),
                            confirmPassword: string()
                                .oneOf([ref('password'), null], 'Dina lösenord matchar inte varandra')
                                .required('Skriv lösenordet igen')
                        })}
                        initialValues={{
                            user: '',
                            password: '',
                            confirmPassword: ''
                        }}
                    >
                        {props => <PasswordForm {...props} />}
                    </Formik>
                </CardBody>
            </Card>
        </PageLayout>
    );
};

export default ChangePassword;
