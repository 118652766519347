import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Alert } from '@bytbil/bootstrap-react';
import Import from './Import';

const propTypes = {
    imports: PropTypes.arrayOf(
        PropTypes.shape({
            log: PropTypes.string.isRequired
        })
    ),
    error: PropTypes.object,
    clearing: PropTypes.bool.isRequired,
    onClear: PropTypes.func.isRequired
};

const defaultProps = {
    imports: undefined,
    error: undefined
};

class ImportList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: undefined
        };

        this.onClear = this.onClear.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onClick(id) {
        const { open } = this.state;

        if (open !== id) {
            this.setState({ open: id });
        } else {
            this.setState({ open: undefined });
        }
    }

    onClear() {
        /* eslint-disable no-restricted-globals, no-alert */
        if (confirm('Är du säker på att du vill rensa alla låsta fält')) {
            const { onClear } = this.props;
            onClear();
        }
    }

    render() {
        const { imports, importFile, error, clearing, loading } = this.props;
        const { open } = this.state;

        // Sort imports based on modified date in descending order
        const sortedImports = imports ? [...imports].sort((a, b) => new Date(b.modified) - new Date(a.modified)) : [];

        if (typeof sortedImports === 'undefined' && loading) {
            return (
                <div className="text-center">
                    <i className="fa fa-circle-o-notch fa-spin" />
                </div>
            );
        }

        return (
            <React.Fragment>
                {error && (
                    <Alert color="warning" className="mb-0 d-inline-block">
                        Kunde inte hämta all importinformation för anläggningen.
                    </Alert>
                )}

                {typeof sortedImports !== 'undefined' && (
                    <Button loading={clearing} onClick={this.onClear} color="danger" className="mb-3">
                        {clearing ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Rensa låsta fält'}
                    </Button>
                )}
                {sortedImports && (
                    <Table responsive bordered className="mb-0">
                        <thead>
                            <tr>
                                <th>Datum</th>
                                <th>Tillagda</th>
                                <th>Uppdaterade</th>
                                <th>Borttagna</th>
                                <th>Fel</th>
                                <th>
                                    <i className="fa fa-file-text" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedImports.map(importItem => (
                                <Import
                                    key={importItem.id}
                                    onClick={this.onClick}
                                    open={open}
                                    {...importItem}
                                    modified={new Date(importItem.modified)}
                                />
                            ))}
                        </tbody>
                    </Table>
                )}
                {importFile && (
                    <React.Fragment>
                        <h2 className="mt-3">Senaste importen</h2>
                        <pre className="import-summary pre-scrollable mw-100 p-2">{importFile}</pre>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

ImportList.propTypes = propTypes;
ImportList.defaultProps = defaultProps;

export default ImportList;
