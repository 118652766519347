import React from 'react';
import Helmet from 'react-helmet-async';
import MainContainer from 'components/MainContainer';

import Dealer from '../../features/dealer';

interface Props {
    id: string;
    activeTab: 'stocklist' | 'edit' | 'import' | 'ftp-user' | 'offer-history';
}

const Route = ({ id, activeTab }: Props) => (
    <MainContainer>
        <Helmet>
            <title>{id ? 'Hantera handlare' : 'Lägg till handlare'}</title>
        </Helmet>
        <Dealer id={id} activeTab={activeTab} />
    </MainContainer>
);

export default Route;
