import gql from 'graphql-tag';

export default gql`
    mutation createDealer($data: DealerInput!) {
        createDealerAPIDealer(data: $data) {
            id
            status
            message
        }
    }
`;
