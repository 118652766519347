import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@bytbil/bootstrap-react';

import FtpUserRow from './FtpUserRow';

const propTypes = {
    users: PropTypes.array,
    onDelete: PropTypes.func.isRequired
};

const defaultProps = {
    users: []
};

const FtpUserTable = React.memo(({ users, onDelete }) => (
    <Table responsive bordered className="mb-0">
        <thead>
            <tr>
                <th className="white-space-nowrap">FTP-användarnamn</th>
                <th className="white-space-nowrap">Hemkatalog</th>
                <th className="white-space-nowrap">Externt ID</th>
                <th className="text-center">
                    <i className="fa fa-trash" />
                </th>
            </tr>
        </thead>
        <tbody>
            {users.map(user => (
                <tr key={user.userid}>
                    <FtpUserRow
                        userId={user.userid}
                        homedir={user.homedir}
                        externalId={user.external_id}
                        onDelete={onDelete}
                    />
                </tr>
            ))}
        </tbody>
    </Table>
));

FtpUserTable.propTypes = propTypes;
FtpUserTable.defaultProps = defaultProps;

export default FtpUserTable;
