import React, { useContext, useEffect } from 'react';
import { FormikValues, FormikProps } from 'formik';

import { Context } from 'core/Store/Store';

import { Form, Row, Col, Button } from '@bytbil/bootstrap-react';
import { Field, Toggle } from '@bytbil/general-react';

const DealerSearchForm = (props: FormikProps<FormikValues>) => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,

        setFieldValue,

        touched
    } = props;

    const { dispatch } = useContext(Context);

    const resetHandler = () => {
        setFieldValue('search', '');
    };

    const handleSearchFieldClick = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    useEffect(() => {
        dispatch({ type: 'DEALER_SEARCH', value: values.search });
    }, [dispatch, values.search]);

    useEffect(() => {
        dispatch({ type: 'DEALER_SEARCH_ACTIVE', active: values.active });
    }, [dispatch, values.active]);

    return (
        <Form onSubmit={handleSubmit} noValidate name="DealerSearchForm">
            <Row>
                <Col xs="12" sm="8" lg="8" xl="6" className="pr-0">
                    <Field
                        type="search"
                        name="search"
                        label="Sök"
                        touched={touched.search}
                        error={errors.search}
                        onClick={handleSearchFieldClick}
                        onChange={handleChange}
                        onReset={resetHandler}
                        onBlur={handleBlur}
                        value={values.search}
                        iconPosition="right"
                        className="dealer-search mb-0"
                    />
                </Col>
                <Col className="pl-0">
                    <Button onClick={handleSubmit} color="primary">
                        Sök
                    </Button>
                </Col>
                <Col xs="12" sm="4" lg="4" xl="2">
                    <Toggle
                        touched
                        onChange={handleChange}
                        value={values.active}
                        description="Endast aktiva"
                        id="active"
                        name="active"
                        className="mt-2"
                    />
                </Col>
            </Row>
        </Form>
    );
};
export default DealerSearchForm;
