import React from 'react';
import copy from 'copy-to-clipboard';
import { useImmer } from 'use-immer';

import { useMutation } from '@apollo/client';
import { Link } from '@reach/router';

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from '@bytbil/bootstrap-react';

import PageLayout from 'components/PageLayout';
import createApiTokenMutation from '../mutations/createApiToken';
import CreateApiTokenForm from '../components/CreateApiTokenForm/CreateApiTokenForm';

import { ScopeHeader } from '../types';

const scopeHeaders: ScopeHeader = {
    isAdmin: { name: 'Allt' },
    getVehicles: { name: 'Fordon' },
    getStatistics: { name: 'Statistik' },
    custom: { name: 'Övrigt' }
};

const config = {
    scopes: {
        isAdmin: {
            name: 'isAdmin',
            value: 'true'
        },
        getVehicles: {
            name: 'get.vehicles',
            value: data => data.dealerGroup || '_all'
        },
        getStatistics: {
            name: ({ dealerGroup }) => `get.statistics.${dealerGroup ? 'dealergroups' : 'dealer_ids'}`,
            value: ({ dealerGroup, dealerIds }) => dealerGroup || dealerIds
        },
        custom: {
            name: ({ scope }) => scope,
            value: ({ value }) => value
        }
    }
};

const getScopeConfig = scope => config.scopes[scope] || config.scopes.custom;

interface Props {
    username: string;
}

interface StateProps {
    apiToken: string | undefined;
    modalOpen: boolean;
    error: boolean;
}

const CreateApiToken = ({ username }: Props) => {
    const [state, setState] = useImmer<StateProps>({
        apiToken: undefined,
        modalOpen: false,
        error: false
    });

    const { apiToken, error, modalOpen } = state;

    const [createApiToken] = useMutation(createApiTokenMutation);

    const onDismiss = () => {
        setState(draft => {
            draft.modalOpen = false;
        });
    };

    const handleCreateApiToken = async values => {
        // Map over the provided scopes and use config to generate
        // { name: <string>, value: <string> } items
        const mappedScopes = values.scopes.map(scope =>
            Object.keys(getScopeConfig(scope.scope)).reduce((acc, scopeKey) => {
                const value = getScopeConfig(scope.scope)[scopeKey];
                acc[scopeKey] = typeof value === 'function' ? value(scope) : value;
                return acc;
            }, {})
        );

        setState(draft => {
            draft.error = false;
        });

        try {
            const { data } = await createApiToken({
                variables: {
                    username,
                    scopes: mappedScopes,
                    ...(values.expiration ? { expiration: new Date(values.expiration) } : {})
                }
            });

            setState(draft => {
                draft.apiToken = data.createApiToken;
            });

            setState(draft => {
                draft.modalOpen = true;
            });
        } catch (e) {
            setState(draft => {
                draft.error = true;
            });
        }
    };

    const copyKey = () => {
        if (apiToken) {
            copy(apiToken);
        }
    };

    return (
        <PageLayout title="Skapa API-nyckel">
            <p>
                För användare:{' '}
                <strong>
                    <Link to={`/api-tokens/${username}`}>{username}</Link>
                </strong>
            </p>

            <CreateApiTokenForm submitHandler={handleCreateApiToken} scopeHeaders={scopeHeaders} error={error} />

            <Modal isOpen={modalOpen} toggle={onDismiss}>
                <ModalHeader toggle={onDismiss}>Nyckel skapad</ModalHeader>
                <ModalBody>
                    <pre style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{apiToken}</pre>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={copyKey}>
                        Kopiera
                    </Button>
                </ModalFooter>
            </Modal>
        </PageLayout>
    );
};

export default CreateApiToken;
