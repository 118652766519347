import React from 'react';
import { Table, Button } from '@bytbil/bootstrap-react';
import { useImmer } from 'use-immer';
import ModelContainer from '../containers/ModelContainer';
import { Model } from '../types';
import ApprovedIcon from './common/ApprovedIcon';

interface Props {
    models: Model[];
    useSlugAsVehicleTypeValue: boolean;
    refetchListModels(): void;
}
interface State {
    modalOpen: boolean;
    selectedModel: Model | undefined;
}

const ModelsTable = ({ models, useSlugAsVehicleTypeValue, refetchListModels }: Props) => {
    const [state, setState] = useImmer<State>({
        modalOpen: false,
        selectedModel: undefined
    });

    const toggleModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    const handleClick = (selectedModel: Model) => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
            draft.selectedModel = selectedModel;
        });
    };

    return (
        <Table responsive bordered>
            <thead>
                <tr>
                    <th>Modell</th>
                    <th>Fordonstyp</th>
                    <th>Status</th>
                    <th>Liknande träffar</th>
                </tr>
            </thead>
            <tbody>
                {models.map(model => (
                    <tr key={model.id}>
                        <td>
                            <Button color="link" className="p-0 font-weight-normal" onClick={() => handleClick(model)}>
                                {model.name}
                            </Button>
                        </td>
                        <td>{model.vehicleTypeName}</td>
                        <td>
                            <ApprovedIcon approved={model.approved} id={model.id} />
                        </td>
                        <td>{model.matches.join(', ')}</td>
                    </tr>
                ))}
                {state.selectedModel && (
                    <ModelContainer
                        model={state.selectedModel}
                        modalOpen={state.modalOpen}
                        toggle={toggleModal}
                        refetchListModels={refetchListModels}
                        useSlugAsVehicleTypeValue={useSlugAsVehicleTypeValue}
                    />
                )}
            </tbody>
        </Table>
    );
};

export default ModelsTable;
