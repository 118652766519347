import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Button } from '@bytbil/bootstrap-react';
import { Radio, Field } from '@bytbil/general-react';

const propTypes = {
    values: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        category: PropTypes.bool,
        query: PropTypes.bool
    }),
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired
};

const defaultProps = {
    touched: undefined
};

const options = [
    { label: 'Grupp', value: 'group' },
    { label: 'Handlar-ID', value: 'id' },
    { label: 'Handlarkod', value: 'code' }
];

class ApiGroup extends React.PureComponent {
    render() {
        const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched } = this.props;

        return (
            <Form onSubmit={handleSubmit} noValidate name="UserSearchForm">
                <Row>
                    <Col xs="12" className="filter-form-limit-filter">
                        <Radio
                            name="category"
                            toggleButton
                            label="Alternativ"
                            options={options}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}
                        />
                    </Col>
                    <Col xs="12" lg="5" xl="4">
                        <Field
                            type="text"
                            name="query"
                            label="Sök"
                            touched={touched.query}
                            error={errors.query}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.query}
                        />
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col xs="12" md="auto">
                                <Button type="submit" color="primary" block loading={isSubmitting}>
                                    <i className="fa fa-search mr-2" />
                                    Sök
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}

ApiGroup.propTypes = propTypes;
ApiGroup.defaultProps = defaultProps;

export default ApiGroup;
