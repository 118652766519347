import React from 'react';
import { Card, CardBody } from '@bytbil/bootstrap-react';

import UsersTable from './components/UsersTable';
import UserSearch from './components/UserSearch';
import UserListLegend from './components/UserListLegend';

import withUsers from './ListUsersProvider';
import EndlessUsers from './EndlessUsers';

interface SearchProps {
    search: string;
}

interface ListUsersProps {
    error: Error;
    searchUsers: (props: SearchProps) => Promise<void>;
}

const ListDealerUsers = ({ searchUsers, error }: ListUsersProps) => {
    if (error) {
        console.error('Error fetching users', error);
        return <div>Något gick fel</div>;
    }

    return (
        <>
            <UserSearch searchUsers={searchUsers} />
            <Card className="my-4">
                <CardBody>
                    <UserListLegend />
                    <UsersTable />
                </CardBody>
            </Card>
            <EndlessUsers />
        </>
    );
};

export default withUsers(ListDealerUsers);
