const getBlocketAdminUrl = () => {
    if (typeof window === 'undefined') {
        return null;
    }

    // Get current URL
    const currentUrl = window.location.href;

    // Set the admin URL based on the current URL
    let BLOCKET_ADMIN_URL;
    if (currentUrl.includes('https://toolbox.bytbil.com')) {
        BLOCKET_ADMIN_URL = 'https://beta.admin.bytbil.com';
    } else if (currentUrl.includes('https://toolbox-dev.bytbil.io')) {
        BLOCKET_ADMIN_URL = 'https://beta-admin-dev.bytbil.io';
    } else {
        BLOCKET_ADMIN_URL = 'http://localhost:3000';
    }

    return BLOCKET_ADMIN_URL;
};

export default getBlocketAdminUrl;
