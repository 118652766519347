import React from 'react';

import Helmet from 'react-helmet-async';
import { EditGroup } from 'features/api-tokens';
import MainContainer from '../../components/MainContainer';

interface Props {
    name: string;
};

const Route = ({ name }: Props) => (
    <React.Fragment>
        <Helmet>
            <title>Ändra grupp</title>
        </Helmet>
        <MainContainer>
            <EditGroup name={name} />
        </MainContainer>
    </React.Fragment>
);



export default Route;
