import React from 'react';
import { FormikProps, FormikValues, useFormikContext } from 'formik';

import { Card, CardBody, Col, FormGroup, Row } from '@bytbil/bootstrap-react';
import { Field, Toggle } from '@bytbil/general-react';

const ExtrasForm = () => {
    const { values, touched, errors, handleChange, handleBlur }: FormikProps<FormikValues> = useFormikContext();

    return (
        <Card className="mt-3">
            <CardBody>
                <h3>Övrigt</h3>
                <p>Övriga rättigheter</p>

                <FormGroup>
                    <Toggle
                        id="dealerGroupAdmin"
                        name="dealerGroupAdmin"
                        description="Administratörsrättigheter"
                        className="mb-0"
                        value={values.dealerGroupAdmin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled // No dealer-api support for this yet
                    />
                </FormGroup>
                {values.dcb && (
                    <FormGroup className="mb-2 mb-md-0">
                        <Toggle
                            id="googleReviews"
                            name="googleReviews"
                            description="Aktivera Google reviews"
                            className="mb-0"
                            value={values.googleReviews}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                )}
                {values.googleReviews && (
                    <Row className="mt-4">
                        <Col xs="12" lg="5" xl="4">
                            <Field
                                type="text"
                                label="AccountId"
                                name="accountId"
                                id="accountId"
                                touched={touched.accountId}
                                error={errors.accountId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.accountId}
                            />
                        </Col>
                        <Col xs="12" lg="5" xl="4">
                            <Field
                                type="text"
                                label="LocationId"
                                name="locationId"
                                id="locationId"
                                touched={touched.locationId}
                                error={errors.locationId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.locationId}
                            />
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

export default ExtrasForm;
