import gql from 'graphql-tag';

export default gql`
    query listBrands($approved: String, $approved__isnull: Boolean, $ordering: String) {
        listBrands(approved: $approved, approved__isnull: $approved__isnull, ordering: $ordering) {
            id
            name
            slug
            approved
            matches
        }
    }
`;
