import React from 'react';
import { Link } from '@reach/router';
import { Table, Card, CardBody, Button } from '@bytbil/bootstrap-react';
import { DealerInfo } from '../types';

interface Props {
    checkedIds: string[];
    selectedDealers: DealerInfo[];
    setDealers(value: DealerInfo[]): void;
    selectDealers(value: string[] | []): void;
}

const SelectedDealerTable = ({ checkedIds, selectedDealers, setDealers, selectDealers }: Props) => {
    const handleRemoveDealer = e => {
        const checkedId = e.currentTarget.id;
        const dealerFilter = selectedDealers?.filter(dealer => dealer.id.toString() !== checkedId);
        const selectedDealersList = checkedIds.filter(item => item !== checkedId);
        selectDealers(selectedDealersList);
        setDealers(dealerFilter);
    };

    return (
        <Card className="mt-2">
            <CardBody>
                <h5>Valda handlare</h5>

                {checkedIds.length > 0 ? (
                    <Table responsive bordered className="mb-0">
                        <thead>
                            <tr>
                                <th key="head-code">Handlarkod</th>
                                <th key="head-name">Företagsnamn</th>
                                <th key="head-id">Handlar-ID</th>
                                <th key="head-group">Grupp</th>
                                <th key="head-city">Ort</th>
                                <th key="head-action" />
                            </tr>
                        </thead>
                        <tbody>
                            {selectedDealers?.map(({ id, name, code, city, groups }) => (
                                <tr key={id}>
                                    <td key={`code-${id}`}>{code}</td>
                                    <td key={`name-${id}`}>{name}</td>
                                    <td key={`id-${id}`}>
                                        <Link to={`/dealer/${id}/edit`}>{id}</Link>
                                    </td>
                                    <td key={`groups-${id}`}>{groups?.map(group => group.code)}</td>
                                    <td key={`city-${id}`}>{city}</td>
                                    <td key={`action-${id}`}>
                                        <Button
                                            color="danger"
                                            id={id}
                                            className=""
                                            type=""
                                            onClick={handleRemoveDealer}
                                        >
                                            Ta bort
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>Inga valda anläggningar</p>
                )}
            </CardBody>
        </Card>
    );
};

export default SelectedDealerTable;
