import React, { useContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { useImmer } from 'use-immer';

import PageLayout from 'components/PageLayout';

import { ToastContext } from 'context/ToastContext';
import { DealerFormProps } from 'types';

import DealerForm from '../components/DealerForm';
import createDealerMutation from '../mutations/createDealer';
import getDealerQuery from '../queries/getDealer';
import getDealerGroupQuery from '../queries/getDealerGroup';

import dataMapper from '../helpers/dataMapper';

interface Props {
    dealerForm: DealerFormProps;
}
interface State {
    dealerCodeIsValid: boolean;
    dealerCodeMessage: string;
}

const DealerAddContainer = ({ dealerForm }: Props) => {
    const [state, setState] = useImmer<State>({
        dealerCodeIsValid: true,
        dealerCodeMessage: ''
    });
    const Toaster = useContext(ToastContext);

    const [createDealer] = useMutation(createDealerMutation);

    const resetDealerCodeState = () => {
        setState(draft => {
            draft.dealerCodeIsValid = true;
            draft.dealerCodeMessage = '';
        });
    };

    const [getDealerGroup, { loading: dealerGroupLoading }] = useLazyQuery(getDealerGroupQuery, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if (data?.dealerGroupByCode?.length > 0) {
                setState(draft => {
                    draft.dealerCodeIsValid = false;
                    draft.dealerCodeMessage = 'Det finns en handlargrupp med samma kod, välj en annan';
                });
            } else {
                resetDealerCodeState();
            }
        }
    });

    const [getDealer, { loading: dealerLoading }] = useLazyQuery(getDealerQuery, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            if (data?.dealerByCode?.length > 0) {
                setState(draft => {
                    draft.dealerCodeIsValid = false;
                    draft.dealerCodeMessage = 'Det finns redan en handlare med samma handlarkod';
                });
            } else {
                resetDealerCodeState();
            }
        }
    });

    const validateDealerCode = value => {
        // Check if dealer code is already used by another dealer
        getDealer({
            variables: { code: value }
        });

        // Also check if there is a dealer group with the same name
        getDealerGroup({
            variables: { code: value }
        });
    };

    const dealerCodeLoading = !!(dealerLoading || dealerGroupLoading);

    const submitHandler = async ({ values }) => {
        try {
            const { data } = await createDealer({
                variables: {
                    data: {
                        ...dataMapper({ values, products: dealerForm.products })
                    }
                }
            });

            if (data?.createDealerAPIDealer?.status === 200) {
                Toaster.showToast(`Handlaren skapades`, 'success');

                return navigate(`/dealer/${data?.createDealerAPIDealer.id}/edit`);
            }

            if (data?.createDealerAPIDealer?.status === 400) {
                return Toaster.showToast(
                    `Kunde inte skapa handlaren: Felkod: ${data.createDealerAPIDealer.message}`,
                    'danger',
                    5000
                );
            }

            return Toaster.showToast(`Kunde inte skapa handlaren`, 'danger');
        } catch (e) {
            console.error(e);

            return Toaster.showToast(`Kunde inte skapa handlaren`, 'danger');
        }
    };

    return (
        <PageLayout title="Lägg till handlare">
            <DealerForm
                dealerCodeIsValid={state.dealerCodeIsValid}
                dealerCodeMessage={state.dealerCodeMessage}
                dealerGroups={dealerForm.dealerGroups}
                toaster={Toaster}
                dealerCodeLoading={dealerCodeLoading}
                dealerForm={dealerForm}
                submitHandler={submitHandler}
                validateDealerCode={validateDealerCode}
                resetDealerCodeState={resetDealerCodeState}
            />
        </PageLayout>
    );
};

export default DealerAddContainer;
