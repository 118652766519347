import gql from 'graphql-tag';

export default gql`
    query listModels($brandId: String, $ordering: String, $vehicletype: String, $limit: Int) {
        listModels(brand: $brandId, ordering: $ordering, vehicletype: $vehicletype, limit: $limit) {
            id
            name
            slug
            approved
            brand
            vehicleTypeId
            vehicleTypeName
            matches
        }
    }
`;
