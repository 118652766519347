import gql from 'graphql-tag';

export default gql`
    mutation updateFtpUser($userid: String!, $passwd: String, $homedir: String, $external_id: Int) {
        updateFtpUser(userid: $userid, passwd: $passwd, homedir: $homedir, external_id: $external_id) {
            userid
            homedir
            external_id
        }
    }
`;
