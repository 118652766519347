import React, { useEffect } from 'react';
import { withFormik, FormikProps, FormikValues, Form } from 'formik';
import { Field } from '@bytbil/general-react';

interface Props {
    name: string;
    placeholder: string;
    filterOnQuery(values: FormikValues): void;
}

const Filter = (props: Props & FormikProps<FormikValues>) => {
    const { name, placeholder, values, errors, touched, handleChange, handleBlur, setFieldValue, filterOnQuery } =
        props;

    useEffect(() => {
        filterOnQuery(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const resetField = () => {
        setFieldValue('query', '');
    };

    return (
        <Form noValidate name={name}>
            <Field
                type="text"
                name="query"
                label="Filtrera"
                placeholder={placeholder}
                icon="fa-search"
                touched={touched.query}
                error={errors.query}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.query}
                className="mb-0"
                onReset={resetField}
            />
        </Form>
    );
};

interface FormValues {
    query: string;
}

const FilterForm = withFormik<Props, FormValues>({
    handleSubmit: () => {}
})(Filter);

export default FilterForm;
