import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import produce from 'immer';
import { ToastContext } from 'context/ToastContext';
import { Alert, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';
import { Formik } from 'formik';
import PageLayout from 'components/PageLayout';

import ApiGroupTable from '../components/ApiGroupTable';
import ApiGroupsSearchForm from '../components/ApiGroupsSearchForm';
import DataFilter from '../components/DataFilter';

import apiGroupsQuery from '../queries/apiGroups';
import deleteApiGroupMutation from '../mutations/deleteApiGroup';

class ApiGroups extends React.PureComponent {
    static contextType = ToastContext;

    static filterFunction(category, query) {
        if (category === 'group') {
            return item => item.name.includes(query);
        }

        if (category === 'code') {
            return item => !!item.dealers.find(dealer => dealer.name.includes(query));
        }

        if (category === 'id') {
            return item => !!item.dealers.find(dealer => dealer.id === query);
        }

        return () => true;
    }

    constructor(props) {
        super(props);

        this.state = {
            filterFunction: ApiGroups.filterFunction(undefined, undefined),
            modalState: { open: false }
        };

        this.onDismiss = this.onDismiss.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.searchForDealerGroup = this.searchForDealerGroup.bind(this);
    }

    onDismiss() {
        this.setState(
            produce(draft => {
                draft.modalState = { open: false };
            })
        );
    }

    onDelete(dealerGroup) {
        return () => {
            this.setState(
                {
                    dealerGroup
                },
                () => {
                    this.setState(
                        produce(draft => {
                            draft.modalState = { open: true };
                        })
                    );
                }
            );
        };
    }

    deleteApiGroup = mutation => async () => {
        const { dealerGroup } = this.state;

        try {
            await mutation({
                variables: { name: dealerGroup },
                update: (cache, { data: { deleteApiGroup } }) => {
                    const data = cache.readQuery({
                        query: apiGroupsQuery
                    });

                    const updatedData = data.apiGroups.filter(group => group.name !== deleteApiGroup);

                    cache.writeQuery({
                        query: apiGroupsQuery,
                        data: { apiGroups: updatedData }
                    });
                }
            });

            this.context.showToast('Gruppen togs bort.');
        } catch (e) {
            this.context.showToast('Något gick fel.', 'danger');
        }

        this.setState(
            produce(draft => {
                draft.modalState = { open: false };
            })
        );
    };

    searchForDealerGroup() {
        return (values, { setSubmitting }) => {
            this.setState(
                produce(draft => {
                    draft.filterFunction = ApiGroups.filterFunction(values.category, values.query);
                }),
                () => {
                    setSubmitting(false);
                }
            );
        };
    }

    render() {
        const { filterFunction, modalState } = this.state;

        return (
            <Query query={apiGroupsQuery} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch }) => (
                    <DataFilter data={data && data.apiGroups} filterFunction={filterFunction}>
                        {filteredData => (
                            <React.Fragment>
                                <PageLayout title="API-grupper">
                                    <p>Här ändrar man på handlare som använder API:et</p>

                                    <Card>
                                        <CardBody>
                                            <Formik
                                                onSubmit={this.searchForDealerGroup(refetch)}
                                                initialValues={{
                                                    query: '',
                                                    category: 'group'
                                                }}
                                            >
                                                {props => <ApiGroupsSearchForm {...props} />}
                                            </Formik>
                                        </CardBody>
                                    </Card>

                                    {!error ? (
                                        <div className="my-4">
                                            <Card>
                                                <CardBody>
                                                    {loading && (
                                                        <div className="text-center">
                                                            <i className="fa fa-circle-o-notch fa-spin" />
                                                        </div>
                                                    )}
                                                    {!loading && !error && (
                                                        <div className="text-right">
                                                            <Button
                                                                className="mb-3"
                                                                color="primary"
                                                                tag={Link}
                                                                to="/api-group/create"
                                                            >
                                                                Skapa ny API-grupp
                                                            </Button>
                                                        </div>
                                                    )}
                                                    {filteredData && (
                                                        <React.Fragment>
                                                            {filteredData.length ? (
                                                                <ApiGroupTable
                                                                    onDelete={this.onDelete}
                                                                    groups={filteredData}
                                                                />
                                                            ) : (
                                                                <Alert color="warning">Hittade inga grupper</Alert>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    ) : (
                                        <Alert color="danger" className="mt-3">
                                            Hittade ingen API-grupp
                                        </Alert>
                                    )}
                                    <Modal isOpen={modalState.open} toggle={this.onDismiss}>
                                        <ModalHeader toggle={this.onDismiss}>Bekräfta borttagning</ModalHeader>
                                        <ModalBody>
                                            Är du säker på att du vill ta bort den här API-gruppen? Det går inte att
                                            ångra den här åtgärden.
                                        </ModalBody>
                                        <ModalFooter>
                                            <Mutation mutation={deleteApiGroupMutation}>
                                                {mutation => (
                                                    <Button color="danger" onClick={this.deleteApiGroup(mutation)}>
                                                        Ta bort API-gruppen
                                                    </Button>
                                                )}
                                            </Mutation>
                                            <Button onClick={this.onDismiss}>Avbryt</Button>
                                        </ModalFooter>
                                    </Modal>
                                </PageLayout>
                            </React.Fragment>
                        )}
                    </DataFilter>
                )}
            </Query>
        );
    }
}

export default ApiGroups;
