import gql from 'graphql-tag';

export default gql`
    query AdHistory($id: String!, $from: Date!, $to: Date!) {
        adHistory(id: $id, from: $from, to: $to) {
            meta {
                pageNumber
                pageSize
                totalPages
                totalRecords
                hasNext
                hasPrevious
            }
            data {
                adId
                origin
                type
                eventType
                timestamp
                regNo
                title
                listPrice
            }
        }
    }
`;
