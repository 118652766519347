import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useQuery } from '@apollo/client';
import { Card, CardBody, Alert, Button, Row, Col } from '@bytbil/bootstrap-react';
import { Select } from '@bytbil/general-react';

import PageLayout from 'components/PageLayout';
import Loader from 'components/Loader/Loader';
import { SORT_NAME_ASC } from 'core/constants/common';

import { Brand } from '../types';
import listBrands from '../queries/listBrands';
import BrandContainer from '../containers/BrandContainer';
import { onQuery, BRAND_STATUS_OPTIONS, getBrandVariable } from '../helpers';

import FilterForm from './common/FilterForm';
import BrandsTable from './BrandsTable';

interface State {
    brands: Brand[];
    status: string;
    modalOpen: boolean;
    loadingStatusChange: boolean;
}

const Brands = () => {
    const [state, setState] = useImmer<State>({
        brands: [],
        status: 'true',
        modalOpen: false,
        loadingStatusChange: false
    });
    const { data, error, loading, refetch } = useQuery(listBrands, {
        variables: { ordering: SORT_NAME_ASC, ...getBrandVariable(state.status) },
        fetchPolicy: 'cache-and-network'
    });

    const filterOnQuery = ({ query }: { query: string }) => {
        const brands = data?.listBrands?.filter(onQuery(query)) || state.brands;
        setState(draft => {
            draft.brands = brands;
        });
    };

    const handleStatusChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setState(draft => {
            draft.status = value;
            draft.loadingStatusChange = true;
        });
    };

    const toggleModal = () => {
        setState(draft => {
            draft.modalOpen = !state.modalOpen;
        });
    };

    useEffect(() => {
        if (data?.listBrands) {
            setState(draft => {
                draft.brands = data.listBrands;
                draft.loadingStatusChange = false;
            });
        }
    }, [data?.listBrands, setState]);

    const hasBrands = !!state.brands.length;
    const isLoading = loading || state.loadingStatusChange;

    return (
        <PageLayout title="Märken & modeller">
            <p>Hantera Blocket och Bytbils märken och modeller</p>
            <Card>
                <CardBody>
                    <Row className="align-items-end mb-3">
                        <Col xs="12" sm="6" md="4">
                            <FilterForm
                                placeholder="Märke eller Liknande träffar"
                                name="BrandsFilterForm"
                                filterOnQuery={filterOnQuery}
                            />
                        </Col>
                        <Col xs="12" sm="6" md="4">
                            <Select
                                touched
                                onChange={handleStatusChange}
                                value={state.status}
                                options={BRAND_STATUS_OPTIONS}
                                id="statusSelector"
                                name="statusSelector"
                                className="mb-0"
                                label="Status"
                            />
                        </Col>
                        <Col xs="12" sm="12" md="4" className="d-flex justify-content-end mt-3 mt-md-0">
                            <Button color="success" onClick={() => toggleModal()}>
                                Lägg till märke
                            </Button>
                        </Col>
                        <BrandContainer modalOpen={state.modalOpen} toggle={toggleModal} refetcher={refetch} />
                    </Row>

                    {isLoading && <Loader size={3} autocenter className="my-4" />}

                    {!isLoading && !error && (
                        <>
                            {hasBrands ? (
                                <BrandsTable brands={state.brands} />
                            ) : (
                                <Alert color="warning">Hittade inga märken</Alert>
                            )}
                        </>
                    )}

                    {!isLoading && error && (
                        <Alert color="danger" className="mt-3">
                            Något gick fel, försök igen om en stund.
                        </Alert>
                    )}
                </CardBody>
            </Card>
        </PageLayout>
    );
};
export default Brands;
