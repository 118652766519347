import React from 'react';
import produce from 'immer';
import { object, string } from 'yup';
import { ApolloConsumer } from '@apollo/client';
import { Formik } from 'formik';

import { UserContext } from 'context/UserContext';

import DisplayErrors from './components/DisplayErrors';
import Layout from './components/Layout';
import Form from './components/Form';

import TOTPStatusQuery from './queries/TOTPStatus';
import generateTOTPMutation from './mutations/generateTOTP';
import loginMutation from './mutations/login';

const fetchPolicy = 'no-cache';

class LoginContainer extends React.PureComponent {
    static contextType = UserContext;

    state = {
        enabled: undefined,
        secret: undefined,
        qr: null,
        errors: []
    };

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    errorCatcher = error => {
        const { graphQLErrors, networkError } = error;
        const errors = graphQLErrors ? graphQLErrors.map(graphqlError => graphqlError.name) : [];

        if (networkError) {
            errors.push('Network error');
        }

        this.setState(
            produce(draft => {
                draft.errors = errors;
            })
        );

        return undefined;
    };

    checkEnabled = client => login =>
        client
            .query({
                query: TOTPStatusQuery,
                variables: login,
                fetchPolicy
            })
            .then(({ data }) => data.TOTPStatus, this.errorCatcher);

    submitHandler =
        client =>
        async (values, { setSubmitting }) => {
            const { username, password, secret } = values;

            this.clearErrors(async () => {
                if (!secret) {
                    const enabled = await this.checkEnabled(client, setSubmitting)({ username, password });

                    if (typeof enabled === 'undefined') {
                        return setSubmitting(false);
                    }

                    if (!enabled) {
                        const { qr, secret: generatedSecret } = await this.generateTOTP(client)(username, password);

                        setSubmitting(false);

                        return this.setState(
                            produce(draft => {
                                draft.qr = qr;
                                draft.secret = generatedSecret;
                                draft.hasValidCredentials = true;
                            })
                        );
                    }

                    setSubmitting(false);

                    return this.setState(
                        produce(draft => {
                            draft.hasValidCredentials = true;
                            draft.enabled = enabled;
                        })
                    );
                }

                await this.login(client)(username, password, secret);

                return setSubmitting(false);
            });
        };

    generateTOTP = client => (username, password) =>
        client
            .mutate({
                mutation: generateTOTPMutation,
                variables: {
                    username,
                    password
                }
            })
            .then(({ data }) => data.generateTOTP, this.errorCatcher);

    login = client => (username, password, secret) =>
        client
            .mutate({
                mutation: loginMutation,
                variables: {
                    username,
                    password,
                    secret
                }
            })
            .then(({ data }) => {
                this.context.actions
                    .login(data.login.accessToken, data.login.refreshToken)
                    .catch(() => this.createError('Internal server error'));
            })
            .catch(this.errorCatcher);

    clearErrors(fn) {
        this.setState(
            produce(draft => {
                draft.errors = [];
            }),
            fn
        );
    }

    createError(type, fn = Function.prototype) {
        this.setState(
            produce(draft => {
                draft.errors.push(type);
            }),
            fn
        );
    }

    render() {
        const { enabled, qr, secret, errors, hasValidCredentials } = this.state;

        return (
            <ApolloConsumer>
                {client => (
                    <Layout>
                        <Formik
                            key={client.username}
                            onSubmit={this.submitHandler(client)}
                            validationSchema={object().shape({
                                username: string().required('Ange användarnamn'),
                                password: string().required('Ange lösenord'),
                                ...(hasValidCredentials
                                    ? {
                                          secret: string()
                                              .min(6, 'Verifieringskoden måste minst innehålla 6 tecken')
                                              .required('Ange verifieringskod')
                                      }
                                    : {})
                            })}
                            initialValues={{
                                username: '',
                                password: '',
                                secret: ''
                            }}
                        >
                            {props => (
                                <Form
                                    {...props}
                                    key={client.username}
                                    enabled={enabled}
                                    secret={secret}
                                    qr={qr}
                                    hasValidCredentials={hasValidCredentials}
                                >
                                    <DisplayErrors errors={errors} />
                                </Form>
                            )}
                        </Formik>
                    </Layout>
                )}
            </ApolloConsumer>
        );
    }
}

export default LoginContainer;
