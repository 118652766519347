import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import { Edit } from 'features/ftp-user';
import MainContainer from '../../components/MainContainer';

const propTypes = {
    id: PropTypes.string.isRequired
};

const Route = ({ id }) => (
    <React.Fragment>
        <Helmet>
            <title>Redigera FTP-användare</title>
        </Helmet>
        <MainContainer>
            <Edit id={id} />
        </MainContainer>
    </React.Fragment>
);

Route.propTypes = propTypes;

export default Route;
