import gql from 'graphql-tag';

export default gql`
    query listRoles {
        listRoles {
            id
            name
        }
    }
`;
