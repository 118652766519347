import React from 'react';
import produce from 'immer';
import { withApollo } from '@apollo/client/react/hoc';
import { Formik } from 'formik';
import { Alert } from '@bytbil/bootstrap-react';
import PropTypes from 'prop-types';
import fileDownload from 'downloadjs';
import objectArrayToCSV from 'core/objectArrayToCSV';
import AdEventsForm from './components/AdEventsForm';
import adHistoryQuery from './queries/adHistory';

const propTypes = {
    client: PropTypes.object.isRequired
};

class AdEvents extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            error: undefined,
            loading: false
        };
    }

    async onSubmit(values) {
        this.setState(
            produce(draft => {
                draft.error = undefined;
                draft.loading = true;
            }),
            async () => {
                try {
                    const { data } = await this.props.client.query({
                        query: adHistoryQuery,
                        variables: values
                    });

                    this.setState(
                        produce(draft => {
                            draft.loading = false;
                        })
                    );

                    const headerRow = [
                        'Rapport',
                        'Annons-Id',
                        'Marknadsplats',
                        'Fordonstyp',
                        'Event',
                        'Tidpunkt',
                        'Reg.nr',
                        'Märke & Modell',
                        'Pris'
                    ];

                    const adHistoryCompleteList = [headerRow, ...data.adHistory.data];
                    const csvData = objectArrayToCSV(adHistoryCompleteList, (key, v) => v);
                    fileDownload(new Blob([csvData]), 'report.csv', 'text/csv');
                } catch (e) {
                    this.setState(
                        produce(draft => {
                            draft.error = true;
                            draft.loading = false;
                        })
                    );
                }
            }
        );
    }

    render() {
        const { dealerId } = this.props;
        const id = dealerId.toString();

        return (
            <>
                <p>
                    Denna funktion returnerar en CSV-fil med en handlares skapade och förnyade annonser för en viss
                    tidsperiod. Det ID som inkluderas är antingen Bytbils eller Blockets annons-ID.
                </p>
                <Formik
                    onSubmit={this.onSubmit}
                    initialValues={{
                        id,
                        from: '',
                        to: ''
                    }}
                >
                    {({ handleSubmit, handleBlur, handleChange, values, errors, touched }) => (
                        <AdEventsForm
                            loading={this.state.loading}
                            onSubmit={handleSubmit}
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    )}
                </Formik>
                {this.state.error && <Alert color="danger">Något gick fel, prova igen om en stund.</Alert>}
            </>
        );
    }
}

AdEvents.propTypes = propTypes;
export default withApollo(AdEvents);
