import React, { useContext } from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Row, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

import { ToastContext } from 'context/ToastContext';

import SYNC_OFFER_MUTATION from '../mutations/syncOffer';

interface FormValues {
    id: string;
}

interface OtherProps {
    loading: boolean;
}

const InnerForm = ({
    values,
    touched,
    errors,
    loading,
    handleChange,
    handleBlur
}: OtherProps & FormikProps<FormValues>) => {
    const handleFieldClick = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.select();
    };

    return (
        <Form>
            <Row>
                <Col xs="12" md="" lg="5" xl="4">
                    <Field
                        label="Reg.nr / Annons-ID"
                        type="text"
                        name="id"
                        placeholder="ABC123 / 100012302"
                        touched={touched.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={handleFieldClick}
                        value={values.id}
                        error={errors.id}
                    />
                </Col>
                <Col xs="12">
                    <Row>
                        <Col xs="12" md="auto">
                            <Button type="submit" color="primary" block loading={loading}>
                                <i className="fa fa-refresh mr-2" />
                                Synka om annons
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

interface MyFormProps {
    loading: boolean;
    handleSubmit(id: string): void;
}

const MyForm = withFormik<MyFormProps, FormValues>({
    mapPropsToValues: () => ({
        id: ''
    }),

    validationSchema: object().shape({
        id: string().required('Ange reg.nr eller fordons ID')
    }),

    handleSubmit: (values, formikBag) => {
        formikBag.props.handleSubmit(values.id);
    }
})(InnerForm);

const ResyncVehicle = () => {
    const Toaster = useContext(ToastContext);

    const [syncOffer, { loading }] = useMutation(SYNC_OFFER_MUTATION);

    const handleSyncOffer = async (id: string) => {
        try {
            const {
                data: {
                    syncOfferByRegNoOrAdId: { success, message }
                }
            } = await syncOffer({
                variables: { id }
            });

            if (success) {
                return Toaster.showToast(message);
            }
            return Toaster.showToast(message, 'danger');
        } catch (e) {
            return Toaster.showToast('Något gick fel', 'danger');
        }
    };

    return <MyForm handleSubmit={handleSyncOffer} loading={loading} />;
};

export default ResyncVehicle;
