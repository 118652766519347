import React, { ChangeEvent, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardBody } from '@bytbil/bootstrap-react';

import getOfferStocklistByDealer from './queries/getOfferStocklistByDealer';
import StocklistFilter from './components/StocklistFilter';
import StocklistTable from './components/StocklistTable';
import SyncDealerOffersBtn from './components/SyncDealerOffersBtn';

interface StocklistProps {
    id: number;
    dealerCode: string;
}

interface StockListTableWrapperProps {
    id: number;
    status: string;
    filter: string;
}

const StockListTableWrapper = ({ id, status, filter }: StockListTableWrapperProps) => {
    const { loading, error, data, refetch } = useQuery(getOfferStocklistByDealer, {
        variables: { dealerId: Number(id) },
        fetchPolicy: 'cache-and-network'
    });

    if (loading && !data) {
        return <p>Laddar...</p>;
    }

    if (error) {
        return <p>Något gick fel...</p>;
    }

    const vehicles = data.getOfferStocklistByDealer;

    const offerStatus = vehicles.reduce((acc, vehicle) => {
        if (status === 'hidden' && vehicle.status !== 'reserved' && vehicle.visible === false) {
            acc.push(vehicle);
        }
        if (status === 'visible' && vehicle.visible === true) {
            acc.push(vehicle);
        }
        if (status === 'reserved' && vehicle.status === 'reserved') {
            acc.push(vehicle);
        }
        if (status === '') {
            acc.push(vehicle);
        }
        return acc;
    }, []);

    const filteredVehicles = offerStatus.filter(vehicle =>
        Object.values(vehicle).some(value => String(value).toLowerCase().includes(filter.toLowerCase()))
    );

    return <StocklistTable vehicles={filteredVehicles} totalVehicles={vehicles.length} refetch={refetch} />;
};

const Stocklist = ({ id, dealerCode }: StocklistProps) => {
    const [filter, setFilter] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value);
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <p className="m-0">
                        Filtrera på handlarens annonser, välj status i dropdown och/eller sök på Reg.nr, märke/modell
                        eller pris
                    </p>
                </div>
                <div>
                    <SyncDealerOffersBtn dealerCode={dealerCode} />
                </div>
            </div>
            <Card>
                <CardBody>
                    <StocklistFilter
                        filter={filter}
                        status={status}
                        onFilterChange={handleFilterChange}
                        onStatusChange={handleStatusChange}
                    />

                    <StockListTableWrapper id={id} status={status} filter={filter} />
                </CardBody>
            </Card>
        </div>
    );
};

export default Stocklist;
