import React from 'react';
import { Form, FormikProps, FormikValues } from 'formik';
import { Row, Col, Button } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

const PasswordForm = ({
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting
}: FormikProps<FormikValues>) => (
    <Form onSubmit={handleSubmit} noValidate name="PasswordForm">
        <Row>
            <Col xs="12" lg="5" xl="4">
                <Field
                    type="text"
                    label="Användarnamn*"
                    name="user"
                    id="user"
                    touched={touched.user}
                    error={errors.user}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user}
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" lg="5" xl="4">
                <Field
                    type="text"
                    label="Nytt lösenord*"
                    name="password"
                    id="password"
                    touched={touched.password}
                    error={errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                />
            </Col>
        </Row>
        <Row>
            <Col xs="12" lg="5" xl="4">
                <Field
                    type="text"
                    label="Bekräfta nytt lösenord*"
                    name="confirmPassword"
                    id="confirmPassword"
                    touched={touched.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col xs="12" md="auto">
                <Button block type="submit" name="dealer" color="primary" loading={isSubmitting}>
                    Spara ändringar
                </Button>
            </Col>
        </Row>
    </Form>
);

export default PasswordForm;
