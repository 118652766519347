import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Card, CardBody, Row, Col, Button } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

interface Props {
    searchUsers: (values: FormikValues) => void;
}

const UserSearch = ({ searchUsers }: Props) => (
    <Card>
        <CardBody>
            <Formik onSubmit={searchUsers} initialValues={{ search: '' }}>
                {({ values, touched, errors, handleChange, handleBlur, resetForm }) => (
                    <Row>
                        <Col xs="12" lg="8">
                            <Form noValidate name="UserSearchForm" className="d-flex align-items-end">
                                <Field
                                    type="search"
                                    name="search"
                                    label="Sök användare"
                                    touched={touched.search}
                                    error={errors.search}
                                    onChange={handleChange}
                                    onReset={resetForm}
                                    placeholder="E-post eller Handlar-ID "
                                    onBlur={handleBlur}
                                    value={values.search}
                                    className="mb-0 w-100"
                                    icon="fa-search"
                                />
                                <Button type="submit">Sök</Button>
                            </Form>
                        </Col>
                    </Row>
                )}
            </Formik>
        </CardBody>
    </Card>
);

export default UserSearch;
