import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@bytbil/bootstrap-react';
import { Checkbox } from '@bytbil/general-react';
import { useMutation } from '@apollo/client';
import { useImmer } from 'use-immer';
import { ToastContext } from 'context/ToastContext';
import deleteBrandMutation from '../../mutations/deleteBrand';
import deleteModelMutation from '../../mutations/deleteModel';

interface Props {
    name: string;
    id: string;
    isOpen: boolean;
    isBrand?: boolean;
    toggle(): void;
}

interface State {
    checked: boolean;
}

const DeleteModal = ({ name, id, isBrand, isOpen, toggle }: Props) => {
    const Toaster = useContext(ToastContext);

    const [deleteBrand, { loading: brandLoading }] = useMutation(deleteBrandMutation);
    const [deleteModel, { loading: modelLoading }] = useMutation(deleteModelMutation, {
        refetchQueries: ['listModels']
    });

    const [state, setState] = useImmer<State>({
        checked: false
    });

    const handleDeleteBrand = async () => {
        try {
            await deleteBrand({
                variables: {
                    id
                }
            });
            Toaster.showToast(`Märket togs bort!`);
            window.history.go(-1);
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const handleDeleteModel = async () => {
        try {
            await deleteModel({
                variables: {
                    id
                }
            });
            Toaster.showToast(`Modellen togs bort!`);
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const toggleCheckbox = () => {
        setState(draft => {
            draft.checked = !state.checked;
        });
    };

    const loading = isBrand ? brandLoading : modelLoading;
    const headerText = isBrand ? 'märke' : 'modell';
    const bodyText = isBrand ? 'märket' : 'modellen';

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered fade={false} backdropClassName="invisible">
            <ModalHeader toggle={toggle}>Ta bort {headerText}</ModalHeader>
            <ModalBody className="mb-5">
                <p className="mb-5">
                    Du är på väg att ta bort {bodyText} <b>{name}</b>
                </p>
                <Checkbox
                    id="checked"
                    name="checked"
                    onChange={toggleCheckbox}
                    value={state.checked}
                    label={`Jag är säker på att jag vill ta bort ${bodyText}`}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle} disabled={loading} className="mr-2">
                    Avbryt
                </Button>
                <Button
                    onClick={() => (isBrand ? handleDeleteBrand() : handleDeleteModel())}
                    color="danger"
                    loading={loading}
                    disabled={!state.checked}
                >
                    Ta bort
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
