import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@bytbil/bootstrap-react';

import ApiGroupRow from './ApiGroupRow';

const propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            dealers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }))
        })
    ).isRequired,
    onDelete: PropTypes.func.isRequired
};

class ApiGroupTable extends React.PureComponent {
    render() {
        const { groups, onDelete } = this.props;

        return (
            <Table responsive bordered className="mb-0">
                <thead>
                    <tr>
                        <th>Gruppnamn</th>
                        <th className="text-center" width="102">
                            <i className="fa fa-trash" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {groups.map(group => (
                        <ApiGroupRow key={group.name} name={group.name} onDelete={onDelete} />
                    ))}
                </tbody>
            </Table>
        );
    }
}

ApiGroupTable.propTypes = propTypes;

export default ApiGroupTable;
