import gql from 'graphql-tag';

export default gql`
    mutation addModel(
        $name: String!
        $brandId: String!
        $vehicleTypeId: String!
        $matches: [String]
        $approved: Boolean
    ) {
        addModel(
            name: $name
            brandId: $brandId
            vehicleTypeId: $vehicleTypeId
            matches: $matches
            approved: $approved
        ) {
            status
        }
    }
`;
