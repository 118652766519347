import gql from 'graphql-tag';

export default gql`
    query listVehicleTypes($ordering: String) {
        listVehicleTypes(ordering: $ordering) {
            id
            name
            slug
        }
    }
`;
