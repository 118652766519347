import gql from 'graphql-tag';

export default gql`
    query ftpUsers {
        ftpUsers {
            userid
            homedir
            external_id
        }
    }
`;
