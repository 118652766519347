import gql from 'graphql-tag';

export default gql`
    query getUser($id: String) {
        getUser(id: $id) {
            id
            email
            dealers
            roles {
                name
                id
            }
            firstName
            lastName
        }
    }
`;
