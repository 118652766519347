import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import arraySort from '@bytbil/array-sort';
import { Table } from '@bytbil/bootstrap-react';
import SortableTableHead from 'components/SortableTableHead';
import Loader from 'components/Loader/Loader';
import { Dealer } from 'types';

import DealerRow from './DealerRow';

interface Props {
    dealers: [Dealer] | undefined;
    called: boolean;
    loading: boolean;
    filterInactive: boolean;
}

const DealerTable = ({ dealers, loading, filterInactive, called }: Props) => {
    const getActiveDealers = ({ allDealers }) => allDealers.filter(dealer => dealer.active === true);

    const [state, setState] = useImmer({
        allDealers: dealers || [],
        activeDealers: dealers ? getActiveDealers({ allDealers: dealers }) : [],
        column: 'login',
        sortReverse: false
    });

    const onHeaderCellClick = (column, sortFunction = arraySort) => {
        const { column: currentColumn, sortReverse: currentSortReverse } = state;

        let sortReverse = false;
        if (currentColumn === column && !currentSortReverse) {
            sortReverse = true;
        }

        setState(draft => {
            draft.allDealers.sort((a, b) => sortFunction(a, b, sortReverse, column));
            draft.activeDealers.sort((a, b) => sortFunction(a, b, sortReverse, column));
            draft.sortReverse = sortReverse;
            draft.column = column;
        });
    };

    const { column, sortReverse, allDealers, activeDealers } = state;

    useEffect(() => {
        if (dealers) {
            setState(draft => {
                draft.allDealers = dealers;
                draft.activeDealers = getActiveDealers({ allDealers: dealers });
            });
        }
    }, [called, loading, dealers, setState]);

    return (
        <React.Fragment>
            <Table responsive className="mb-0" hover>
                <thead>
                    <tr>
                        <SortableTableHead
                            column="code"
                            sortDirection={column === 'code' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Handlarkod
                        </SortableTableHead>
                        <SortableTableHead
                            column="level"
                            sortDirection={column === 'level' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Anv. typ
                        </SortableTableHead>
                        <SortableTableHead
                            column="name"
                            sortDirection={column === 'name' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Företagsnamn
                        </SortableTableHead>
                        <SortableTableHead
                            column="id"
                            sortDirection={column === 'id' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Handlar-ID
                        </SortableTableHead>
                        <SortableTableHead
                            column="group"
                            sortDirection={column === 'group' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Grupp
                        </SortableTableHead>
                        <SortableTableHead
                            column="city"
                            sortDirection={column === 'city' ? sortReverse : undefined}
                            onSortChange={onHeaderCellClick}
                        >
                            Ort
                        </SortableTableHead>
                        <th />
                    </tr>
                </thead>
                {!loading && (
                    <tbody>
                        {filterInactive &&
                            activeDealers.map(dealer => <DealerRow {...dealer} loading={loading} key={dealer.id} />)}
                        {!filterInactive &&
                            allDealers.map(dealer => <DealerRow {...dealer} loading={loading} key={dealer.id} />)}
                    </tbody>
                )}
            </Table>
            {loading && <Loader autocenter size={4} className="mt-5 mb-4" />}
        </React.Fragment>
    );
};

export default DealerTable;
