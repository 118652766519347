import { Product } from 'types';
import { DealerFormValues, ProductOut } from '../types';

const productsMapper = ({ values, products }: { values: DealerFormValues; products: Product[] }) =>
    /**
     * Helper function that will return which products the dealer should have
     * It will map through all of the available dealer products and extract the code variable,
     * then check if that value is true or false which has been set by each product's Toggle
     *
     * Add the products that have true, skip the rest
     */

    products.reduce((acc: ProductOut[], product) => {
        const { code, name } = product;

        let productSettings;

        // Is the toggle active?
        if (values.code) {
            // Does the product have a settings_schema?
            if (product.settings_schema) {
                productSettings = Object.keys(product.settings_schema.properties).reduce((accu, property) => {
                    const propertyKey = `${code}_settings_${property}`;

                    if (values[propertyKey]) {
                        accu[property] = values[propertyKey];
                    }

                    // IF the value is false, via a toggle - it need's to be set
                    if (values[propertyKey] === false) {
                        accu[property] = false;
                    }

                    return accu;
                }, {});
            }
        }

        if (values[code]) {
            acc.push({
                code,
                name,
                end_date: values[`${code}_end_date`] || null,
                settings: productSettings || null
            });
        }

        return acc;
    }, []);

const dataMapper = ({ values, products }: { values: DealerFormValues; products: Product[] }) => {
    const {
        active,
        address,
        authorizedBrandsCar,
        authorizedBrandsMc,
        bumpPermission,
        city,
        code,
        companyName,
        county,
        emailDisplay,
        emailExport,
        emailImport,
        emailLead,
        established,
        ftpAccount,
        ftpNumber,
        googleReviews,
        groups,
        id,
        orgNumber,
        phoneNumber,
        postAddress,
        postalNumber,
        accountId: reviewsAccountId,
        locationId: reviewsLocationId,
        website,
        tags
    } = values;

    const mappedProducts = productsMapper({ values, products });

    const mappedGroups = groups?.map(({ id: groupId, value, label }) => ({ id: groupId, code: value, name: label }));

    const mappedTags = tags ? tags.map(tag => ({ tag })) : undefined;

    return {
        active,
        address,
        authorizedBrandsCar: authorizedBrandsCar?.map(brand => ({
            vehicleType: 'car',
            brand: `vehicle-api:brand:${brand.value}`
        })),
        authorizedBrandsMc: authorizedBrandsMc?.map(brand => ({
            vehicleType: 'mc',
            brand: `vehicle-api:brand:${brand.value}`
        })),
        bumpPermission,
        city,
        code,
        county,
        emailDisplay,
        emailExport,
        emailImport,
        emailLead,
        established,
        ftpAccount,
        ftpNumber: ftpNumber || 0,
        groups: mappedGroups,
        id,
        name: companyName,

        orgNumber,
        phoneNumber,
        postAddress,
        postalNumber,
        products: mappedProducts,
        reviewsAccountId,
        reviewsActive: googleReviews,
        reviewsLocationId,
        website,
        tags: mappedTags
    };
};

export default dataMapper;
