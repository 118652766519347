import React from 'react';
import { useMutation } from '@apollo/client';
import PageLayout from 'components/PageLayout';
import UserForm from './components/UserForm';
import createNewUserQuery from './mutations/createUser';

const AddUser = () => {
    const [createUser, { loading }] = useMutation(createNewUserQuery);

    const initialValues = {
        email: '',
        roles: '',
        search: ''
    };

    return (
        <PageLayout title="Bjud in användare" backBtn>
            <UserForm isEdit={false} initialValues={initialValues} submitLoading={loading} createUser={createUser} />
        </PageLayout>
    );
};

export default AddUser;
