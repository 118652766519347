import { DealerFormValues } from '../types';

import END_DATE_PRODUCTS from '../components/Product/endDateProducts';

const normalizeDealer = (dealer: any): DealerFormValues => {
    const [googleReviews] = dealer.reviewProviders.filter(provider => provider.code === 'google');
    const { accountId, locationId, active: googleReviewsActive } = googleReviews || {};

    const endDateProducts = dealer.products.reduce((acc, product) => {
        if (END_DATE_PRODUCTS.includes(product.code)) {
            acc[`${product.code}_end_date`] = product.end_date;
        }
        return acc;
    }, {});

    return {
        ...dealer,
        ...endDateProducts,

        orgNumber: dealer.org_number,
        companyName: dealer.name,

        bumpPermission: dealer.bumpPermission && dealer.bumpPermission.hasBumpPermission,
        bumpApiVerified: dealer.bumpPermission && dealer.bumpPermission.apiVerified,

        phoneNumber: dealer.phoneNumber || '',

        googleReviews: !!googleReviewsActive,
        accountId,
        locationId,
        hideDealerApiFields: dealer.hide_dealer_api_fields,
        tags: dealer.tags.map(tag => tag.tag)
    };
};

export default normalizeDealer;
