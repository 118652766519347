import React from 'react';

import useElementOnScreen from 'core/useElementOnScreen';

interface Props {
    rootMargin?: string;
    threshold?: number;
    callBack(): void;
}

const EndlessScrollCallback = ({ rootMargin = '0px', threshold = 1.0, callBack }: Props) => {
    const [{ componentRef }] = useElementOnScreen({
        root: null,
        rootMargin,
        threshold,
        callBack
    });

    return <div ref={componentRef} style={{ height: 1 }} />;
};

export default EndlessScrollCallback;
