import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { Table } from '@bytbil/bootstrap-react';

const propTypes = {
    privacyRequests: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            entityID: PropTypes.string,
            action: PropTypes.string,
            docType: PropTypes.string,
            state: PropTypes.string,
            created: PropTypes.string,
            requestSent: PropTypes.string,
            responseCompleted: PropTypes.string,
            done: PropTypes.string
        })
    ).isRequired
};

const defaultProps = {};

const renderTimestamps = (column, date, label) => (
    <div className="d-flex justify-content-between" key={`timestamp-${column}`}>
        <div>
            <strong>{label}</strong>
        </div>
        <div>{date ? format(date, 'YYYY-MM-DD HH:mm:ss') : '-'}</div>
    </div>
);

const timestampColumns = [
    { name: 'created', label: 'Created' },
    { name: 'requestSent', label: 'Sent' },
    { name: 'responseCompleted', label: 'Completed' },
    { name: 'Done', label: 'Done' }
];

const PrivacyRequestTable = React.memo(props => {
    const { privacyRequests } = props;

    return (
        <Table responsive bordered className="mb-0">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>EntityID</th>
                    <th>Action</th>
                    <th>Doctype</th>
                    <th>State</th>
                    <th>Timestamps</th>
                </tr>
            </thead>
            <tbody>
                {privacyRequests.map(request => (
                    <tr key={`request-${request.id}`}>
                        <th scope="row">{request.id}</th>
                        <td>{request.entityID}</td>
                        <td>{request.action}</td>
                        <td>{request.docType}</td>
                        <td>{request.state}</td>
                        <td>
                            {timestampColumns.map(column =>
                                renderTimestamps(column.name, request[column.name], column.label)
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
});

PrivacyRequestTable.propTypes = propTypes;
PrivacyRequestTable.defaultProps = defaultProps;

export default PrivacyRequestTable;
