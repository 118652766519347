import React, { useContext } from 'react';
import { useImmer } from 'use-immer';
import { useMutation, useQuery } from '@apollo/client';

import { Alert, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';
import copy from 'copy-to-clipboard';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';

import deleteApiTokenMutation from '../mutations/deleteApiToken';
import regenerateApiTokenMutation from '../mutations/regenerateApiToken';
import apiUserQuery from '../queries/apiUser';

import ApiTokenTable from '../components/ApiTokenTable';

interface Props {
    username: string;
}

interface StateProps {
    apiToken: string | undefined;
    deletionSubject: string | undefined;
    regenerationSubject: string | undefined;
    modalState: {
        id: string | undefined;
        open: boolean;
    };
}

const ApiTokens = ({ username }: Props) => {
    const Toaster = useContext(ToastContext);

    const [state, setState] = useImmer<StateProps>({
        apiToken: undefined,
        deletionSubject: undefined,
        regenerationSubject: undefined,
        modalState: {
            id: undefined,
            open: false
        }
    });

    const { modalState, apiToken } = state;

    const { loading, error, data, refetch } = useQuery(apiUserQuery, {
        variables: {
            username
        },
        fetchPolicy: 'cache-and-network'
    });

    const [regenerateApiToken, { loading: regeneratingToken }] = useMutation(regenerateApiTokenMutation);
    const [deleteApiToken] = useMutation(deleteApiTokenMutation);

    const onRegenerate = regenerationSubject => {
        setState(draft => {
            draft.regenerationSubject = regenerationSubject;
            draft.modalState = {
                id: 'CONFIRM_API_TOKEN_REGENERATION',
                open: true
            };
        });
    };

    const handleRegenerateApiToken = async () => {
        const { regenerationSubject } = state;

        try {
            const {
                data: { regenerateApiToken: regeneratedApiToken }
            } = await regenerateApiToken({
                variables: {
                    id: regenerationSubject
                }
            });

            setState(draft => {
                draft.apiToken = regeneratedApiToken;
                draft.modalState = {
                    id: 'API_TOKEN_REGENERATED',
                    open: true
                };
            });

            refetch();
        } catch (e) {
            Toaster.showToast(`Kunde inte generera ny nyckel`, 'danger');
        }
    };

    const cancelDeletionModal = () => {
        setState(draft => {
            draft.modalState = {
                id: 'CONFIRM_API_USER_DELETION',
                open: false
            };
        });
    };

    const handleDeleteApiToken = async () => {
        const { deletionSubject } = state;

        try {
            await deleteApiToken({
                variables: {
                    id: deletionSubject
                }
            });

            Toaster.showToast(`API-nyckeln togs bort`);

            cancelDeletionModal();

            refetch();
        } catch (e) {
            Toaster.showToast(`Kunde inte ta bort nyckeln`, 'danger');

            cancelDeletionModal();
        }
    };

    const onDelete = deletionSubject => {
        setState(draft => {
            draft.deletionSubject = deletionSubject;
        });

        setState(draft => {
            draft.modalState = {
                id: 'CONFIRM_API_TOKEN_DELETE',
                open: true
            };
        });
    };

    const copyKey = () => {
        if (apiToken) {
            copy(apiToken);
        }
    };

    const dismissRegenerationCompleteModal = () => {
        setState(draft => {
            draft.modalState = {
                id: 'API_TOKEN_REGENERATED',
                open: false
            };
        });
    };

    const cancelRegenerationModal = () => {
        setState(draft => {
            draft.modalState = {
                id: 'CONFIRM_API_USER_PASSWORD_REGENERATION',
                open: false
            };
        });
    };

    if (error) {
        return <Alert color="danger">Något gick fel</Alert>;
    }

    return (
        <PageLayout title="API-nycklar">
            <p>
                Utfärdade API-nycklar för API-användare: <span className="font-weight-bold">{username}</span>
            </p>
            <Button tag={Link} color="primary" to={`/api-token/${username}/create/`}>
                Skapa ny API-nyckel
            </Button>
            <Card className="my-4">
                <CardBody>
                    {loading && (
                        <div className="text-center">
                            <i className="fa fa-circle-o-notch fa-spin" />
                        </div>
                    )}
                    {data && data.apiUser && (
                        <ApiTokenTable apiTokens={data.apiUser.keys} onRegenerate={onRegenerate} onDelete={onDelete} />
                    )}
                </CardBody>
            </Card>
            <Modal
                isOpen={modalState.id === 'CONFIRM_API_TOKEN_DELETE' && modalState.open}
                toggle={cancelDeletionModal}
            >
                <ModalHeader toggle={cancelDeletionModal}>Bekräfta borttagning</ModalHeader>
                <ModalBody>
                    Är du säker på att du vill ta bort den här nyckeln? Det går inte att ångra den här åtgärden.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDeleteApiToken}>
                        Ta bort
                    </Button>
                    <Button onClick={cancelDeletionModal}>Avbryt</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={modalState.id === 'CONFIRM_API_TOKEN_REGENERATION' && modalState.open}
                toggle={cancelRegenerationModal}
            >
                <ModalHeader toggle={cancelRegenerationModal}>Generera ny API-nyckel</ModalHeader>
                <ModalBody>
                    Är du säker på att du vill generera en ny nyckel? Det går inte att ångra den här åtgärden. Notera
                    att den befintliga nyckeln kommer att inaktiveras.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleRegenerateApiToken} loading={regeneratingToken}>
                        Återskapa nyckel
                    </Button>
                    <Button onClick={cancelRegenerationModal}>Avbryt</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={modalState.id === 'API_TOKEN_REGENERATED' && modalState.open}
                toggle={dismissRegenerationCompleteModal}
            >
                <ModalHeader toggle={dismissRegenerationCompleteModal}>Nyckel genererad</ModalHeader>
                <ModalBody>
                    <pre style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{apiToken}</pre>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={copyKey}>
                        Kopiera
                    </Button>
                </ModalFooter>
            </Modal>
        </PageLayout>
    );
};

export default ApiTokens;
