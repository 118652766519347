import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';

import { ToastContext } from 'context/ToastContext';
import DealerGroupFormWrapper from './DealerGroupForm';

import createApiGroupMutation from '../mutations/createApiGroup';

const CreateGroup = () => {
    const Toaster = useContext(ToastContext);

    const [createApiGroup] = useMutation(createApiGroupMutation, {
        refetchQueries: ['apiGroup']
    });

    const submitHandler = async values => {
        try {
            const dealerIds = values.dealerIds.map(dealerId => parseInt(dealerId, 10));

            await createApiGroup({
                variables: {
                    name: values.name,
                    dealerIds
                }
            });

            Toaster.showToast(`API-gruppen uppdaterades`);

            return navigate(`/api-groups`);
        } catch (e) {
            console.error(e);
            return Toaster.showToast(`Någt gick fel: ${e}`, 'danger');
        }
    };

    const initialValues = {
        name: '',
        dealerIds: []
    };

    return (
        <DealerGroupFormWrapper
            title="Skapa ny API-Grupp"
            initialValues={initialValues}
            onSubmit={submitHandler}
            edit={false}
            data={undefined}
        />
    );
};

export default CreateGroup;
