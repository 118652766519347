import React from 'react';
import { useFormikContext, FormikValues } from 'formik';
import { number } from 'yup';
import { Alert, Form, Row, Col, Container, Button } from '@bytbil/bootstrap-react';
import { ActionPanel, Field, MultipleValueInput } from '@bytbil/general-react';
import { ApiGroup } from '../types';

interface Props {
    edit: boolean;
    data: ApiGroup | undefined;
}

const ApiGroupForm = (props: Props) => {
    const { dirty, values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm } =
        useFormikContext<FormikValues>();

    const { edit, data } = props;

    const submitBtnText = `${edit ? 'Uppdatera' : 'Skapa'} API-Grupp`;

    const changeHandler = key => value => {
        // this is going to call setFieldValue and manually update values[key]
        setFieldValue(key, value);
    };

    return (
        <Form onSubmit={handleSubmit} noValidate name="create-api-token-form">
            <Row>
                <Col xs="12" lg="5" xl="4">
                    <Field
                        name="name"
                        id="name"
                        type="text"
                        value={values.name}
                        label="Gruppnamn"
                        disabled={edit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="5" xl="4">
                    <div className="d-multi-select-badges-as-row">
                        <MultipleValueInput
                            name="dealerIds"
                            validator={value => number().validate(value)}
                            value={values.dealerIds}
                            onChange={changeHandler}
                            onBlur={handleBlur}
                            label="Handlare"
                            placeholder="Handlarid, tex: 1191"
                        />
                    </div>
                    {errors.dealerIds && <span className="text-danger">{errors.dealerIds}</span>}
                </Col>
            </Row>

            <ActionPanel showPanel={dirty}>
                <Container>
                    <Row>
                        <Col md="12" className="d-flex align-items-center">
                            <Col md="12" className="py-3 d-none d-md-block">
                                <div className="d-flex justify-content-between justify-content-md-end">
                                    {edit && (
                                        <Button onClick={resetForm} outline className="mr-2" disabled={isSubmitting}>
                                            Återställ
                                        </Button>
                                    )}
                                    <Button color="success" disabled={isSubmitting} type="submit">
                                        {isSubmitting ? (
                                            <div className="loader fa fa-circle-o-notch fa-spin" />
                                        ) : (
                                            submitBtnText
                                        )}
                                    </Button>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </ActionPanel>

            {data && data.dealers && (
                <Alert color="success" className="mt-3 mb-0 d-inline-block">
                    Gruppen skapades.
                </Alert>
            )}
        </Form>
    );
};

export default ApiGroupForm;
