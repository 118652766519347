import React from 'react';

interface Props {
    id: string;
    children: React.ReactNode;
    className?: string;
    showTab(id: string): void;
}

const TabLink = ({ id, showTab, children, className = '' }: Props) => {
    const onClick = e => {
        e.preventDefault();

        showTab(id);
    };

    return (
        <a href={`#${id}`} onClick={onClick} role="button" className={className}>
            {children}
        </a>
    );
};

export default TabLink;
