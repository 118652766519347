import React, { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from '@reach/router';
import { Alert, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@bytbil/bootstrap-react';
import { PageLoader } from '@bytbil/general-react';

import PageLayout from 'components/PageLayout';
import { ToastContext } from 'context/ToastContext';

import DealerGroupTable from '../components/DealerGroupTable';
import DealerGroupsSearchForm from '../components/DealerGroupsSearchForm';

import dealerGroupsQuery from '../queries/dealerGroups';
import deleteDealerGroupMutation from '../mutations/deleteDealerGroup';

import { DealerGroup } from '../types';

type State = {
    modal: {
        open: boolean;
    };
    tempDealerGroupId: null | number;
    dealerGroups: DealerGroup[];
};

const ListGroups: React.FunctionComponent = () => {
    const [deleteDealerGroup, { loading: deletingDealerGroup }] = useMutation(deleteDealerGroupMutation);
    const Toaster = useContext(ToastContext);

    const [state, setState] = useImmer<State>({
        dealerGroups: [],
        modal: { open: false },
        tempDealerGroupId: null
    });

    const { data, loading, error, refetch } = useQuery(dealerGroupsQuery, {
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (data?.dealerGroups) {
            setState(draft => {
                draft.dealerGroups = data.dealerGroups;
            });
        }
    }, [data?.dealerGroups]);

    if (loading) {
        return <PageLoader show />;
    }

    const filterFunction = ({ query, category }) => {
        if (category === 'group') {
            return data.dealerGroups.filter(group => group.code.includes(query));
        }

        if (category === 'code') {
            return data.dealerGroups.filter(group => group.code.includes(query));
        }

        if (category === 'id') {
            return data.dealerGroups.filter(group => group.id === parseInt(query, 10));
        }

        return state.dealerGroups;
    };

    const onDismiss = () => {
        setState(draft => {
            draft.modal = { open: false };
        });
    };

    const onDelete = (id: number) => () => {
        setState(draft => {
            draft.modal = { open: true };
            draft.tempDealerGroupId = id;
        });
    };

    const searchForDealerGroup = values => {
        const filteredGroups = filterFunction(values);

        setState(draft => {
            draft.dealerGroups = filteredGroups;
        });
    };

    const confirmDealerGroupDelete = async () => {
        try {
            const { data: deleteData } = await deleteDealerGroup({
                variables: { id: state.tempDealerGroupId }
            });
            if (deleteData?.deleteDealerGroup?.status === 200) {
                Toaster.showToast('Gruppen togs bort.');

                refetch();
            } else {
                Toaster.showToast('Något gick fel.', 'danger');
            }
        } catch (err) {
            Toaster.showToast('Något gick fel.', 'danger');
        }

        setState(draft => {
            draft.modal = { open: false };
            draft.tempDealerGroupId = null;
        });
    };

    return (
        <PageLayout title="Handlargrupper - EJ Access API">
            <p>Här kan man ändra på DealerGroups. OBS! Ej för API-användare!</p>

            <Card>
                <CardBody>
                    <DealerGroupsSearchForm searchForDealerGroup={searchForDealerGroup} />
                </CardBody>
            </Card>

            {!error ? (
                <div className="my-4">
                    <Card>
                        <CardBody>
                            {loading && (
                                <div className="text-center">
                                    <i className="fa fa-circle-o-notch fa-spin" />
                                </div>
                            )}
                            {!loading && !error && (
                                <div className="text-right">
                                    <Button className="mb-3" color="primary" tag={Link} to="/dealer-group/create">
                                        Skapa ny grupp
                                    </Button>
                                </div>
                            )}
                            {data && (
                                <React.Fragment>
                                    {state.dealerGroups?.length ? (
                                        <DealerGroupTable onDelete={onDelete} groups={state.dealerGroups} />
                                    ) : (
                                        <Alert color="warning">Hittade inga grupper</Alert>
                                    )}
                                </React.Fragment>
                            )}
                        </CardBody>
                    </Card>
                </div>
            ) : (
                <Alert color="danger" className="mt-3">
                    Hittade ingen DealerGroup
                </Alert>
            )}
            <Modal isOpen={state.modal.open} toggle={onDismiss}>
                <ModalHeader toggle={onDismiss}>Bekräfta borttagning</ModalHeader>
                <ModalBody>
                    Är du säker på att du vill ta bort den här gruppen? Det går inte att ångra den här åtgärden.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDealerGroupDelete} loading={deletingDealerGroup}>
                        Ta bort handlargruppen
                    </Button>

                    <Button onClick={onDismiss}>Avbryt</Button>
                </ModalFooter>
            </Modal>
        </PageLayout>
    );
};

export default ListGroups;
