import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@bytbil/bootstrap-react';
import ApiUserRow from './ApiUserRow';

const propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({ username: PropTypes.string })),
    onRegeneratePassword: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};
const defaultProps = { users: [] };

const ApiUserTable = React.memo(({ users, onRegeneratePassword, onDelete }) => (
    <Table responsive bordered className="mb-0">
        <thead>
            <tr>
                <th>Användarnamn</th>
                <th className="text-center" width="102">
                    <i className="fa fa-refresh" />
                </th>
                <th className="text-center" width="102">
                    <i className="fa fa-trash" />
                </th>
            </tr>
        </thead>
        <tbody>
            {users.map(user => (
                <ApiUserRow
                    key={user.username}
                    username={user.username}
                    onRegeneratePassword={onRegeneratePassword}
                    onDelete={onDelete}
                />
            ))}
        </tbody>
    </Table>
));

ApiUserTable.propTypes = propTypes;
ApiUserTable.defaultProps = defaultProps;

export default ApiUserTable;
