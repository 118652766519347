import React from 'react';
import { Formik } from 'formik';
import { string, object } from 'yup';
import produce from 'immer';
import jwtDecode from 'jwt-decode';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import PageLayout from 'components/PageLayout';
import JWTDecodeForm from '../components/JWTDecodeForm';

class JWTDecode extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
        this.decodeJWT = this.decodeJWT.bind(this);
    }

    decodeJWT = ({ jwt }, { setSubmitting }) => {
        this.setState(
            produce(draft => {
                draft.error = undefined;
                draft.json = undefined;
            }),
            () => {
                try {
                    const json = jwtDecode(jwt);
                    this.setState(
                        produce(draft => {
                            draft.json = json;
                        })
                    );
                } catch (e) {
                    this.setState(
                        produce(draft => {
                            draft.error = 'Ogiltig JWT';
                        })
                    );
                }

                setSubmitting(false);
            }
        );
    };

    render() {
        const { error, json } = this.state;

        return (
            <PageLayout title="Decode JWT-token">
                <Card>
                    <CardBody>
                        <Formik
                            onSubmit={this.decodeJWT}
                            validationSchema={object().shape({
                                jwt: string().required('Ange en JWT token')
                            })}
                            initialValues={{
                                jwt: ''
                            }}
                        >
                            {props => <JWTDecodeForm {...props} error={error} json={json} />}
                        </Formik>
                    </CardBody>
                </Card>
            </PageLayout>
        );
    }
}

export default JWTDecode;
