import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import { object, string } from 'yup';

import { ToastContext } from 'context/ToastContext';
import { SORT_NAME_ASC } from 'core/constants/common';
import ModalForm from '../components/common/ModalForm';
import addModelMutation from '../mutations/addModel';
import updateModelMutation from '../mutations/updateModel';
import listVehicleTypes from '../queries/listVehicleTypes';
import { Model } from '../types';
import { getApprovedOptions, getIsApprovedPending, getModelValues, getVehicleTypeOptions } from '../helpers';

const validationSchema = object().shape({
    vehicleType: string()
        .required('Ange fordonstyp')
        .matches(/^(?!0\b)/i, 'Du måste välja en fordonstyp'),
    name: string().required('Ange en modell'),
    approved: string().matches(/^(?!null\b)/i, 'Du måste godkänna eller neka modellen')
});

interface Props {
    brandId?: string;
    model?: Model;
    modalOpen: boolean;
    useSlugAsVehicleTypeValue: boolean;
    toggle(): void;
    refetchListModels(): void;
}

const ModelContainer = ({ brandId, model, modalOpen, useSlugAsVehicleTypeValue, toggle, refetchListModels }: Props) => {
    const Toaster = useContext(ToastContext);

    const { data: vehicleTypesData } = useQuery(listVehicleTypes, { variables: { ordering: SORT_NAME_ASC } });

    const [addMutation] = useMutation(addModelMutation);
    const [updateMutation] = useMutation(updateModelMutation);

    const addModel = async ({ name, vehicleType, matches }: FormikValues, { setFieldError, resetForm }) => {
        try {
            const { data } = await addMutation({
                variables: {
                    name,
                    brandId,
                    vehicleTypeId: vehicleType,
                    matches,
                    approved: true
                }
            });
            const { status } = data?.addModel;

            switch (status) {
                case 201:
                    Toaster.showToast(`Modellen ${name} har lagts till`);

                    refetchListModels();
                    resetForm();
                    toggle();
                    break;
                case 409:
                    setFieldError('name', 'Modellen finns redan');
                    break;
                default:
                    Toaster.showToast('Något gick fel', 'danger');
            }
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const editModel = async ({ name, slug, vehicleType, matches, approved }: FormikValues, { setFieldError }) => {
        try {
            const { data } = await updateMutation({
                variables: {
                    name,
                    slug,
                    id: model?.id,
                    vehicleTypeId: vehicleType,
                    matches,
                    approved: approved === 'true'
                }
            });

            const { status } = data?.updateModel;

            switch (status) {
                case 200:
                    Toaster.showToast(`Modellen ${name} har uppdaterats`);

                    refetchListModels();
                    toggle();
                    break;
                case 409:
                    setFieldError('name', 'Modellen finns redan');
                    break;
                default:
                    Toaster.showToast('Något gick fel', 'danger');
            }
        } catch (e) {
            Toaster.showToast(`Något gick fel: ${e}`, 'danger');
            console.error(e);
        }
    };

    const edit = !!model;

    const vehicleTypeOptions = getVehicleTypeOptions({
        vehicleTypes: vehicleTypesData?.listVehicleTypes,
        edit,
        useSlugAsVehicleTypeValue
    });
    const approvedOptions = edit ? getApprovedOptions({ isBrand: false }) : [];
    const isApprovedPending = edit && getIsApprovedPending(model.approved);
    const initialValues = getModelValues(model);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={edit ? editModel : addModel}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {props => (
                <ModalForm
                    {...props}
                    title={edit ? 'Redigera modell' : 'Lägg till modell'}
                    vehicleTypeOptions={vehicleTypeOptions}
                    approvedOptions={approvedOptions}
                    id={edit ? model.id : ''}
                    modalOpen={modalOpen}
                    toggle={toggle}
                    edit={edit}
                    isApprovedPending={isApprovedPending}
                />
            )}
        </Formik>
    );
};

export default ModelContainer;
