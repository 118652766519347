import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

class CreateApiUserForm extends React.PureComponent {
    static propTypes = {
        isSubmitting: PropTypes.bool.isRequired,
        touched: PropTypes.shape({ username: PropTypes.bool }).isRequired,
        values: PropTypes.shape({ username: PropTypes.string }).isRequired,
        errors: PropTypes.shape({ username: PropTypes.string }).isRequired,
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired
    };

    render() {
        const { touched, values, errors, handleChange, handleBlur, handleSubmit, isSubmitting } = this.props;
        return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs="12" lg="5" xl="4">
                        <Field
                            name="username"
                            id="username"
                            type="text"
                            touched={touched.username}
                            value={values.username}
                            error={errors.username}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Användarnamn"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="auto">
                        <Button type="submit" color="primary" block loading={isSubmitting} onClick={handleSubmit}>
                            Skapa API-användare
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default CreateApiUserForm;
