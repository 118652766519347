import React from 'react';
import PropTypes from 'prop-types';
import { Field } from '@bytbil/general-react';
import { Form, Button, Card, CardBody, Row, Col } from '@bytbil/bootstrap-react';

const propTypes = {
    values: PropTypes.shape({
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        from: PropTypes.bool,
        to: PropTypes.bool
    }).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

const AdEventsForm = React.memo(props => {
    const { onSubmit, values, errors, touched, onChange, onBlur, loading } = props;
    return (
        <Card>
            <CardBody>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Field
                                label="Från"
                                type="date"
                                name="from"
                                value={values.from}
                                error={errors.from}
                                touched={touched.from}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </Col>
                        <Col>
                            <Field
                                label="Till"
                                type="date"
                                name="to"
                                value={values.to}
                                error={errors.to}
                                touched={touched.to}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </Col>
                    </Row>
                    <Button type="submit" loading={loading}>
                        Hämta
                    </Button>
                </Form>
            </CardBody>
        </Card>
    );
});

AdEventsForm.propTypes = propTypes;

export default AdEventsForm;
