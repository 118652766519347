import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    binaryData: PropTypes.string.isRequired,
    className: PropTypes.string
};

const defaultProps = {
    className: undefined
};

const QRImage = React.memo(props => {
    const { binaryData, className } = props;

    return <img className={className} src={`data:image/jpeg;base64,${binaryData}`} alt="qr" />;
});

QRImage.propTypes = propTypes;
QRImage.defaultProps = defaultProps;

export default QRImage;
