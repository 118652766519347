import gql from 'graphql-tag';

export default gql`
    mutation createNewUser($email: String!, $roles: String!, $dealerIds: [String]!) {
        createNewUser(email: $email, roles: $roles, dealerIds: $dealerIds) {
            detail
            status
        }
    }
`;
