import React from 'react';
import produce from 'immer';
import { ToastContext } from 'context/ToastContext';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { Card, CardBody } from '@bytbil/bootstrap-react';
import PageLayout from 'components/PageLayout';
import Tabs from './components/Tabs';

import UserNameForm from './components/UserNameForm';
import ResetTOTPMutation from './mutations/resetTOTP';

class TOTP extends React.PureComponent {
    static contextType = ToastContext;

    static reset(mutate) {
        return values => mutate({ variables: { username: values.username } });
    }

    constructor(props) {
        super(props);

        this.showTab = this.showTab.bind(this);

        this.state = {
            activeTab: 'status'
        };
    }

    showTab(tab) {
        const { activeTab } = this.state;

        if (activeTab !== tab) {
            this.setState(
                produce(draft => {
                    draft.activeTab = tab;
                    draft.showExportHistory = {
                        loading: false,
                        data: undefined,
                        error: false
                    };
                    draft.exportImages = {
                        loading: false
                    };
                })
            );
        }
    }

    render() {
        const { activeTab } = this.state;

        return (
            <PageLayout title="TOTP (Time based one time password)">
                <React.Fragment>
                    <Tabs activeTab={activeTab} showTab={this.showTab} />
                    <Card className="tab-content-card">
                        <CardBody>
                            <Mutation mutation={ResetTOTPMutation}>
                                {(mutate, { data, error, loading }) =>
                                    activeTab === 'status' && (
                                        <Formik
                                            onSubmit={TOTP.reset(mutate)}
                                            validationSchema={object().shape({
                                                username: string().required('Ange användarnamn')
                                            })}
                                            initialValues={{
                                                username: ''
                                            }}
                                        >
                                            {props => (
                                                <UserNameForm
                                                    {...props}
                                                    success={data ? data.resetTOTP.success : undefined}
                                                    error={error}
                                                    isSubmitting={loading}
                                                />
                                            )}
                                        </Formik>
                                    )
                                }
                            </Mutation>
                        </CardBody>
                    </Card>
                </React.Fragment>
            </PageLayout>
        );
    }
}

export default TOTP;
