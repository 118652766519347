import gql from 'graphql-tag';

export default gql`
    mutation changePassword($data: ChangePasswordInput!) {
        changePassword(data: $data) {
            message
            success
        }
    }
`;
