import React from 'react';
import { useImmer } from 'use-immer';

import { Card, CardBody } from '@bytbil/bootstrap-react';
import PageLayout from 'components/PageLayout';
import Tabs from './components/Tabs';

import ResyncVehicle from './components/ResyncVehicle';
import ResyncVehicles from './components/ResyncVehicles';

import DeleteAd from './components/DeleteAd';

interface StateProps {
    activeTab: string;
}

const BlocketExporter = () => {
    const [state, setState] = useImmer<StateProps>({
        activeTab: 'updateAds'
    });

    const showTab = (tab: string) => {
        if (state.activeTab !== tab) {
            setState(draft => {
                draft.activeTab = tab;
            });
        }
    };

    const { activeTab } = state;

    return (
        <PageLayout title="Annonser">
            <React.Fragment>
                <Tabs activeTab={activeTab} showTab={showTab} />
                <Card className="tab-content-card">
                    <CardBody>
                        {activeTab === 'updateAds' && <ResyncVehicles />}

                        {activeTab === 'resyncVehicle' && <ResyncVehicle />}

                        {activeTab === 'deleteOffer' && <DeleteAd />}
                    </CardBody>
                </Card>
            </React.Fragment>
        </PageLayout>
    );
};

export default BlocketExporter;
