import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

const propTypes = {
    filterFunction: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any)
};

const Filter = React.memo(props => {
    const { data, filterFunction } = props;

    return typeof data !== 'undefined' ? props.children(data.filter(filterFunction)) : props.children(data);
});

Filter.propTypes = propTypes;

export default Filter;
