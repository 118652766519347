import React from 'react';
import Helmet from 'react-helmet-async';
import { CreateGroup } from 'features/api-tokens';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Skapa ny API-Grupp</title>
        </Helmet>
        <MainContainer>
            <CreateGroup />
        </MainContainer>
    </React.Fragment>
);

export default Route;
