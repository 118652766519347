import { ApolloError } from '@apollo/client';

interface GraphQLError {
    data?: {
        correlationId: string;
    };
    message: string;
    name: string;
}

const onGraphQLError = (error: ApolloError) => {
    if (error.message) {
        if (error.message === 'Timeout exceeded') {
            return 'Sökningen tog för lång tid';
        }
        return `Något gick fel - ${error.message}`;
    }

    if (error.graphQLErrors.length > 0) {
        const firstError: GraphQLError = error.graphQLErrors[0];

        const correlationId = firstError?.data?.correlationId || undefined;

        return `Något gick fel - Ange korrelation-ID ${correlationId} vid felrapportering`;
    }

    return 'Något gick fel';
};

export default onGraphQLError;
