import React from 'react';
import { Button } from '@bytbil/bootstrap-react';
import { Link } from '@reach/router';

interface Props {
    id: number;
    name: string;
    onDelete(id: number): void;
}

const DealerGroupRow = React.memo(({ id, name, onDelete }: Props) => (
    <tr>
        <td>
            <Link to={`/dealer-group/edit/${id}`}>{name}</Link>
        </td>
        <td>
            <Button color="danger" onClick={onDelete(id)}>
                Ta bort
            </Button>
        </td>
    </tr>
));

export default DealerGroupRow;
