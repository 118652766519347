import React from 'react';
import { useImmer } from 'use-immer';
import { withFormik, FormikProps, FormikValues, Form } from 'formik';

import { Row, Col, Card, CardBody, ButtonGroup, Button, Alert } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

import ScopeTable from './ScopeTable';
import CustomScopeForm from './CustomScopeForm';
import StatisticsScopeForm from './StatisticsScopeForm';
import VehicleScopeForm from './VehicleScopeForm';

import { ScopeHeader } from '../../types';

interface StateProps {
    scope: string;
}

interface Props extends FormikProps<FormikValues> {
    scopeHeaders: ScopeHeader;
    error: boolean;
}

const CreateApiTokenForm = (props: Props) => {
    const {
        scopeHeaders,
        touched,
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        error,
        isSubmitting
    } = props;

    const [state, setState] = useImmer<StateProps>({
        scope: 'isAdmin'
    });

    const { scope } = state;

    const isEqual = (a, b) => a.scope === b.scope && a.type === b.type && a.value === b.value;

    const filterEqual = value => item => !isEqual(value, item);

    const setScope = scopeId => {
        setState(draft => {
            draft.scope = scopeId;
        });
    };

    const addValue = value => {
        setFieldValue('scopes', [...values.scopes.filter(filterEqual(value)), value]);
    };

    const deleteValue = value => {
        setFieldValue('scopes', values.scopes.slice(0).filter(filterEqual(value)));
    };

    return (
        <Form onSubmit={handleSubmit} noValidate name="create-api-token-form">
            <Card>
                <CardBody>
                    <h6>Ge tillgång till</h6>
                    <ButtonGroup>
                        {Object.keys(scopeHeaders).map(scopeId => (
                            <Button key={scopeId} active={scope === scopeId} onClick={() => setScope(scopeId)}>
                                {scopeHeaders[scopeId].name}
                            </Button>
                        ))}
                    </ButtonGroup>

                    {scope === 'custom' && <CustomScopeForm onSubmit={addValue} className="mt-3 mb-0" />}
                    {scope === 'getVehicles' && <VehicleScopeForm onSubmit={addValue} className="mt-3 mb-0" />}
                    {scope === 'getStatistics' && <StatisticsScopeForm onSubmit={addValue} className="mt-3 mb-0" />}
                    {scope === 'isAdmin' && (
                        <ButtonGroup className="d-block mt-3">
                            <Button color="success" onClick={() => addValue({ scope: 'isAdmin', value: true })}>
                                <i className="fa fa-plus-circle fs-2" /> Lägg till
                            </Button>
                        </ButtonGroup>
                    )}
                </CardBody>
                <CardBody className="card-border-top">
                    <Row>
                        <Col xs="12">
                            {values.scopes.length > 0 && (
                                <ScopeTable
                                    createdScopes={values.scopes}
                                    scopeHeaders={scopeHeaders}
                                    onDelete={deleteValue}
                                />
                            )}
                        </Col>
                        <Col xs="12" lg="5" xl="4">
                            <Field
                                name="expiration"
                                id="expiration"
                                type="datetime-local"
                                placeholder="ÅÅÅÅ-MM-DD TT:MM"
                                touched={touched.expiration}
                                value={values.expiration}
                                label="Utgångsdatum"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.expiration}
                            />
                        </Col>
                    </Row>
                    {error && (
                        <Row>
                            <Col xs="12" lg="5" xl="4">
                                <Alert color="danger" className="mt-1 mb-0">
                                    Något gick fel, försök igen
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    {errors.scope && (
                        <Row>
                            <Col xs="12" lg="5" xl="4">
                                <Alert color="danger" className="mt-1 mb-0">
                                    Lägg till minst ett scope
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </CardBody>
            </Card>
            <div className="mt-3">
                <Button color="primary" type="submit" loading={isSubmitting}>
                    Skapa nyckel
                </Button>
            </div>
        </Form>
    );
};

const initialValues = {
    scopes: [],
    expiration: ''
};

interface MyFormProps {
    scopeHeaders: any;
    submitHandler(values: FormikValues): void;
    error: boolean;
}

const EnhancedCreateApiTokenForm = withFormik<MyFormProps, FormikValues>({
    enableReinitialize: true,

    mapPropsToValues: () => ({
        ...initialValues
    }),

    handleSubmit: async (values: FormikValues, formikBag) => {
        if (values.scopes.length < 1) {
            return formikBag.setErrors({ scope: 'oooops' });
        }

        return formikBag.props.submitHandler(values);
    },

    displayName: 'CreateApiTokenForm'
})(CreateApiTokenForm);

export default EnhancedCreateApiTokenForm;
