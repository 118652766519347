import gql from 'graphql-tag';

export default gql`
    query listUsers($offset: Int, $limit: Int, $search: String) {
        listUsers(offset: $offset, limit: $limit, search: $search) {
            count
            next
            previous
            userData {
                id
                email
                dealers
                firstName
                lastName
                roles {
                    name
                    id
                }
                state
            }
        }
    }
`;
