import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';
import QRImage from './QRImage';

const propTypes = {
    enabled: PropTypes.bool,
    values: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        username: PropTypes.bool,
        password: PropTypes.bool
    }),
    hasValidCredentials: PropTypes.bool,
    qr: PropTypes.string,
    secret: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.node
};

const defaultProps = {
    hasValidCredentials: false,
    touched: undefined,
    qr: undefined,
    secret: undefined,
    enabled: undefined,
    children: undefined
};

const LoginForm = React.memo(props => {
    const {
        hasValidCredentials,
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        children,
        qr,
        enabled,
        secret
    } = props;

    useEffect(() => {
        if (values.secret.length === 6) {
            handleSubmit();
        }
    }, [values.secret, handleSubmit]);

    return (
        <Form onSubmit={handleSubmit} noValidate name="LoginForm">
            {!enabled && !qr && (
                <React.Fragment>
                    <Field
                        type="text"
                        placeholder="T.ex. 2signum"
                        label="Användarnamn"
                        name="username"
                        touched={touched.username}
                        error={errors.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                    />
                    <Field
                        type="password"
                        name="password"
                        placeholder="••••••"
                        label="Lösenord"
                        touched={touched.password}
                        error={errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                    />
                </React.Fragment>
            )}
            {children}
            {((hasValidCredentials && secret) || enabled) && (
                <React.Fragment>
                    {qr && (
                        <React.Fragment>
                            <p>
                                För att logga in i toolbox måste du ange en verifieringskod. För att få
                                verifieringskoder behöver du följa stegen nedan första gången du loggar in.
                            </p>
                            <ol className="pl-3">
                                <li>Ladda ner Google Authenticator från Google Play/App Store</li>
                                <li>I appen: scanna QR-koden eller skriv in engångskoden nedan</li>
                                <li>Använd den verifieringskod som visas för att logga in</li>
                            </ol>
                            <QRImage binaryData={qr} className="mb-2" />
                        </React.Fragment>
                    )}
                    {secret && (
                        <p className="d-block">
                            <strong>Engångskod:</strong> {secret}
                        </p>
                    )}
                    <Field
                        type="secret"
                        name="secret"
                        placeholder="123456"
                        label="Verifieringskod"
                        touched={touched.secret}
                        error={errors.secret}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.secret}
                        autoFocus
                    />
                </React.Fragment>
            )}
            <FormGroup className="mb-0">
                <Button type="submit" color="primary" block loading={isSubmitting}>
                    Logga in
                </Button>
            </FormGroup>
        </Form>
    );
});

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
