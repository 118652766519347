import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import { Create } from 'features/api-tokens';
import MainContainer from '../../components/MainContainer';

const propTypes = {
    username: PropTypes.string.isRequired
};

const Route = ({ username }) => (
    <React.Fragment>
        <Helmet>
            <title>Skapa API-nyckel</title>
        </Helmet>
        <MainContainer>
            <Create username={username} />
        </MainContainer>
    </React.Fragment>
);

Route.propTypes = propTypes;

export default Route;
