import React from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { ApolloConsumer } from '@apollo/client';
import { Card, CardBody } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import PageLayout from 'components/PageLayout';
import Tabs from './components/Tabs';
import DealerPrivacyForm from './components/DealerPrivacyForm';
import PrivacyRequestTable from './components/PrivacyRequestTable';
import PrivacyListForm from './components/PrivacyListForm';

import getPrivacyRequestsQuery from './queries/privacyRequests';
import takeoutMutation from './mutations/takeout';
import deletionMutation from './mutations/deletion';

class DealerTakeout extends React.PureComponent {
    static contextType = ToastContext;

    static propTypes = {
        privacyRequests: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                entityID: PropTypes.string,
                action: PropTypes.string,
                docType: PropTypes.string,
                state: PropTypes.string,
                created: PropTypes.string,
                requestSent: PropTypes.string,
                responseCompleted: PropTypes.string,
                done: PropTypes.string
            })
        ),
        takeoutError: PropTypes.func,
        takeoutSuccess: PropTypes.func,
        deleteError: PropTypes.func,
        deleteSuccess: PropTypes.func,
        listSuccess: PropTypes.func,
        listError: PropTypes.func
    };

    static defaultProps = {
        privacyRequests: undefined,
        takeoutError: Function.prototype,
        takeoutSuccess: Function.prototype,
        deleteError: Function.prototype,
        deleteSuccess: Function.prototype,
        listSuccess: Function.prototype,
        listError: Function.prototype
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'takeout',
            isFetchingDeletion: false,
            isFetchingTakeout: false
        };

        this.onDelete = this.onDelete.bind(this);
        this.onTakeout = this.onTakeout.bind(this);
        this.clear = this.clear.bind(this);
        this.showTab = this.showTab.bind(this);
    }

    onTakeout(client) {
        return ({ entityID, docType }) => {
            this.setState(
                produce(draft => {
                    draft.isFetchingTakeout = true;
                })
            );

            client
                .mutate({
                    mutation: takeoutMutation,
                    variables: {
                        entityID,
                        docType
                    }
                })
                .then(() => {
                    this.props.takeoutSuccess();
                    this.context.showToast('Takeout-förfrågan har registrerats', 'success');
                })
                .catch(() => {
                    this.props.takeoutError();
                    this.context.showToast(
                        `Kunde inte skapa en takeout för ${
                            docType === 'Dealer' ? 'organisationsnumret' : 'SPID användaren'
                        }`,
                        'danger'
                    );
                })
                .finally(() => {
                    this.setState(
                        produce(draft => {
                            draft.isFetchingTakeout = false;
                        })
                    );
                });
        };
    }

    onDelete(client) {
        return ({ entityID, docType }) => {
            if (
                // eslint-disable-next-line no-alert
                window.confirm(
                    `Är du säker på att du vill radera all data för alla ${
                        docType === 'Dealer' ? 'handlare' : 'användare'
                    } kopplat till ${docType === 'Dealer' ? 'organisationsnummer' : 'SPID-ID'} ${entityID}`
                )
            ) {
                this.setState(
                    produce(draft => {
                        draft.isFetchingDeletion = true;
                    })
                );

                client
                    .mutate({
                        mutation: deletionMutation,
                        variables: {
                            entityID,
                            docType
                        }
                    })
                    .then(() => {
                        this.props.deleteSuccess();
                        this.context.showToast('Deletion-förfrågan har registrerats', 'success');
                    })
                    .catch(() => {
                        this.props.deleteError();
                        this.context.showToast('Kunde inte ta bort data för organisationsnumret', 'danger');
                    })
                    .finally(() => {
                        this.setState(
                            produce(draft => {
                                draft.isFetchingDeletion = false;
                            })
                        );
                    });
            }
        };
    }

    getPrivacyRequests(client) {
        return ({ startDate, endDate }) => {
            const { displayToast } = this.props;

            this.setState(
                produce(draft => {
                    draft.isFetchingRequests = true;
                })
            );

            client
                .query({
                    query: getPrivacyRequestsQuery,
                    variables: {
                        from: startDate,
                        to: endDate
                    }
                })
                .then(({ data }) => {
                    this.props.listSuccess();
                    this.setState(
                        produce(draft => {
                            draft.privacyRequests = data.privacyRequests;
                        })
                    );
                })
                .catch(() => {
                    this.props.listError();
                    displayToast('Kunde inte hämta data för perioden', 'danger');
                })
                .finally(() => {
                    this.setState(
                        produce(draft => {
                            draft.isFetchingRequests = false;
                        })
                    );
                });
        };
    }

    showTab(tab) {
        const { activeTab } = this.state;

        if (activeTab !== tab) {
            this.setState(
                produce(draft => {
                    draft.activeTab = tab;
                    draft.privacyRequest = undefined;
                })
            );
        }
    }

    clear() {
        const { clearDeletion, clearTakeout, clearQueue } = this.props;
        clearDeletion();
        clearTakeout();
        clearQueue();
    }

    render() {
        const { privacyRequests } = this.state;
        const { activeTab, isFetchingTakeout, isFetchingDeletion, isFetchingRequests } = this.state;

        if (typeof privacyRequests !== 'undefined') {
            privacyRequests.sort((a, b) => {
                if (a.created < b.created) {
                    return 1;
                }
                if (a.created > b.created) {
                    return -1;
                }

                return 0;
            });
        }

        return (
            <ApolloConsumer>
                {client => (
                    <PageLayout title="Privacy">
                        <p>
                            Här kan man hämta ut eller ta bort all data för en handlare samt se information samt lista
                            alla actions för ett givet tidsintervall.
                        </p>
                        <Tabs activeTab={activeTab} showTab={this.showTab} />
                        <Card className="tab-content-card">
                            <CardBody>
                                {activeTab === 'takeout' && (
                                    <Formik
                                        onSubmit={this.onTakeout(client)}
                                        initialValues={{
                                            entityID: '',
                                            docType: 'Dealer'
                                        }}
                                        validationSchema={object().shape({
                                            entityID: string()
                                                .matches(/[0-9-]+/, 'Ange ett giltigt org-nr eller spid-id.')
                                                .required('Ange org-nr eller spid-id.')
                                        })}
                                    >
                                        {props => (
                                            <DealerPrivacyForm
                                                {...props}
                                                buttonIcon="download"
                                                buttonColor="primary"
                                                buttonText="Hämta"
                                                isFetching={isFetchingTakeout}
                                            />
                                        )}
                                    </Formik>
                                )}
                                {activeTab === 'deletion' && (
                                    <Formik
                                        onSubmit={this.onDelete(client)}
                                        initialValues={{
                                            entityID: '',
                                            docType: 'Dealer'
                                        }}
                                        validationSchema={object().shape({
                                            entityID: string()
                                                .matches(/\d+/, 'Ange ett giltigt org. nr/spid-id.')
                                                .required('Ange org. nr/spid-id.')
                                        })}
                                    >
                                        {props => (
                                            <DealerPrivacyForm
                                                {...props}
                                                buttonIcon="trash"
                                                buttonColor="danger"
                                                buttonText="Ta bort"
                                                isFetching={isFetchingDeletion}
                                            />
                                        )}
                                    </Formik>
                                )}
                                {activeTab === 'list' && (
                                    <Formik
                                        initialValues={{ startDate: '', endDate: '' }}
                                        onSubmit={this.getPrivacyRequests(client)}
                                        enableReinitialize
                                    >
                                        {props => <PrivacyListForm {...props} isFetching={isFetchingRequests} />}
                                    </Formik>
                                )}
                            </CardBody>
                        </Card>

                        {privacyRequests && (
                            <Card className="mt-3">
                                <CardBody>
                                    {privacyRequests && (
                                        <React.Fragment>
                                            <h3>History</h3>
                                            <PrivacyRequestTable privacyRequests={privacyRequests} />
                                        </React.Fragment>
                                    )}
                                </CardBody>
                            </Card>
                        )}
                    </PageLayout>
                )}
            </ApolloConsumer>
        );
    }
}

export default DealerTakeout;
