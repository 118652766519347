import React from 'react';
import Icon from 'components/icon';
import { Button, Card } from '@bytbil/bootstrap-react';
import LoginToDealerButton from 'components/LoginToDealerButton';
import { navigate } from '@reach/router';

interface Props {
    dealerName: string;
    dealerId: number;
    dealerCode: string;
    dealerStoreCode: string;
}

const DealerHeader = ({ dealerName, dealerId, dealerCode, dealerStoreCode }: Props) => {
    const goBack = () => {
        navigate(`/`);
    };

    const handleClick = () => {
        const link = `https://control-panel.blocket.io/stores/${dealerStoreCode}`;
        window.open(link, '_blank');
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center pb-2">
                    <Button type="button" onClick={goBack} color="link" className="p-0 mr-3">
                        <Icon name="arrow-left text-dark" />
                    </Button>
                    <h1 className="m-0 mr-5">{dealerName}</h1>
                </div>
            </div>
            <Card className="mb-3 p-2">
                <div className="d-flex justify-content-between">
                    <div className="d-flex p-2">
                        <div className="mr-4">
                            <p className="m-0 p-0 font-weight-500">Handlar-ID</p>
                            <p className="mb-0">{dealerId}</p>
                        </div>
                        <div className="mr-4">
                            <p className="m-0 p-0 font-weight-500">Butiks-ID</p>
                            <p className="mb-0">{dealerStoreCode}</p>
                        </div>
                        <div className="">
                            <p className="m-0 p-0 font-weight-500">Handlarkod</p>
                            <p className="mb-0">{dealerCode}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <Button onClick={handleClick} color="link" className="mr-2">
                            <i className="mr-2 fa fa-external-link fs-1" />
                            Butikssida i Kontrollpanelen
                        </Button>

                        <LoginToDealerButton dealerId={dealerId.toString()} className="ml-2" />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default DealerHeader;
