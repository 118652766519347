import gql from 'graphql-tag';

export default gql`
    mutation privacyRequest($entityID: String!, $docType: String!) {
        privacyRequest(entityID: $entityID, action: "Takeout", docType: $docType) {
            id
            entityID
            action
            docType
            state
            created
            requestSent
            responseCompleted
            done
        }
    }
`;
