import React from 'react';
import Helmet from 'react-helmet-async';
import BlocketExporter from 'features/blocket-exporter';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>Uppdatera annonser</title>
        </Helmet>
        <MainContainer>
            <BlocketExporter />
        </MainContainer>
    </React.Fragment>
);

export default Route;
