import React from 'react';
import { Badge } from '@bytbil/bootstrap-react';

import { Dealer } from 'types';
import LoginToDealerButton from 'components/LoginToDealerButton';
import DealerLinkCell from './DealerLinkCell';

const prettyDealerGroupsString = groups =>
    groups.map((group, index, array) => {
        if (array.length - 1 === index) {
            return group.code;
        }
        return `${group.code}, `;
    });

const UserRow = ({ active, groups, id, name, code, city }: Dealer) => {
    const isAdmin = groups.map(group => group.admin);

    const stocklistLink = `/dealer/${id}/stocklist`;

    return (
        <tr className={!active ? `inactive` : ''}>
            <DealerLinkCell title={code} to={stocklistLink}>
                {code}
            </DealerLinkCell>

            <DealerLinkCell title={code} to={stocklistLink}>
                <Badge>{groups && isAdmin.includes(true) ? 'Admin' : 'Användare'}</Badge>
            </DealerLinkCell>

            <DealerLinkCell title={code} to={stocklistLink}>
                <div className="ml-1">{name}</div>
            </DealerLinkCell>

            <DealerLinkCell title={code} to={stocklistLink}>
                <div className="ml-1">{id}</div>
            </DealerLinkCell>

            <DealerLinkCell title={code} to={stocklistLink}>
                <div className="ml-1">{groups && prettyDealerGroupsString(groups)}</div>
            </DealerLinkCell>

            <DealerLinkCell title={code} to={stocklistLink}>
                <div className="ml-1">{city}</div>
            </DealerLinkCell>

            <td className="p-0 m-0">
                <div className="d-flex">
                    <LoginToDealerButton dealerId={id.toString()} className="ml-2" />
                </div>
            </td>
        </tr>
    );
};

export default UserRow;
