import React from 'react';
import produce from 'immer';
import { Query, Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@bytbil/bootstrap-react';

import { ToastContext } from 'context/ToastContext';

import ImportList from './components/ImportList';
import ImportsQuery from './queries/imports';
import clearImportLocksMutation from './mutations/clearImportLocks';

class Imports extends React.PureComponent {
    static contextType = ToastContext;

    static propTypes = {
        dealerId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.onClear = this.onClear.bind(this);
    }

    onClear(mutation) {
        return () => {
            const { dealerId } = this.props;

            this.setState(
                produce(draft => {
                    draft.loading = true;
                })
            );

            mutation({
                variables: {
                    dealerId
                }
            })
                .then(() => {
                    this.context.showToast('Rensade låsen för anläggningen.', 'success');
                })
                .catch(() => {
                    this.context.showToast('Kunde inte rensa lås för anläggningen.', 'danger');
                })
                .finally(() => {
                    this.setState(
                        produce(draft => {
                            draft.loading = false;
                        })
                    );
                });
        };
    }

    render() {
        const { dealerId } = this.props;
        const { loading: clearing } = this.state;

        return (
            <Card>
                <CardBody>
                    <Mutation mutation={clearImportLocksMutation}>
                        {mutation => (
                            <Query query={ImportsQuery} variables={{ dealerId }} fetchPolicy="cache-and-network">
                                {({ loading, data, error }) => (
                                    <ImportList
                                        clearing={clearing}
                                        loading={loading}
                                        imports={data && data.imports}
                                        importFile={data && data.importFile}
                                        onClear={this.onClear(mutation)}
                                        error={error}
                                    />
                                )}
                            </Query>
                        )}
                    </Mutation>
                </CardBody>
            </Card>
        );
    }
}

export default Imports;
