import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    column: PropTypes.string.isRequired,
    onSortChange: PropTypes.func.isRequired,
    sortDirection: PropTypes.bool
};

const defaultProps = {
    sortDirection: undefined,
    className: undefined
};

const SortableTableHead = props => {
    const { children, className, column, onSortChange, sortDirection } = props;

    const onClick = () => {
        onSortChange(column);
    };

    return (
        <th onClick={onClick} role="button" tabIndex={0} className={`${className} white-space-nowrap`}>
            {children}
            {sortDirection === false && <i className="fa fa-caret-up ml-1" />}
            {sortDirection && <i className="fa fa-caret-down ml-1" />}
        </th>
    );
};

SortableTableHead.propTypes = propTypes;
SortableTableHead.defaultProps = defaultProps;

export default SortableTableHead;
