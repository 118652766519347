import React from 'react';
import IconComponent from 'components/icon';
import { UncontrolledTooltip } from '@bytbil/bootstrap-react';

interface Props {
    approved: boolean | null;
    id: string;
}

const tooltipText = (approved: boolean | null) => {
    switch (approved) {
        case true:
            return 'Godkänd';
        case false:
            return 'Nekad';
        default:
            return 'Ej hanterad';
    }
};

const Icon = ({ approved, id }: Props) => {
    switch (approved) {
        case true:
            return <IconComponent name="check" color="#24c5a1" id={id} />;
        case false:
            return <IconComponent name="times" color="#f24c5a" id={id} />;
        default:
            return <IconComponent name="question" color="#09f" id={id} />;
    }
};

const ApprovedIcon = ({ approved, id }: Props) => {
    const tooltipId = `${approved}-${id}`;
    return (
        <>
            <Icon approved={approved} id={tooltipId} />
            <UncontrolledTooltip target={tooltipId}>{tooltipText(approved)}</UncontrolledTooltip>
        </>
    );
};
export default ApprovedIcon;
