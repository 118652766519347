import React from 'react';
import { useFormikContext, FormikProps } from 'formik';

import { Card, CardBody, Col, FormGroup, Row } from '@bytbil/bootstrap-react';
import { Toggle } from '@bytbil/general-react';

import { Product } from 'types';
import ProductTag from './Product/ProductTag';

import { DealerFormValues } from '../types';

const ProductsForm = ({ products }: { products: Product[] }) => {
    const { values, handleChange, handleBlur }: FormikProps<DealerFormValues> = useFormikContext();

    return (
        <Card className="mt-3">
            <CardBody>
                <h3>Produkter</h3>
                <p>Hantera produkter för handlare</p>

                {products.map(product => (
                    <FormGroup key={product.code}>
                        <Row>
                            <Col sm="6">
                                <Toggle
                                    id={product.code}
                                    name={product.code}
                                    description={product.name}
                                    value={values[product.code]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {product.description && (
                                    <small className="form-text text-muted">{product.description}</small>
                                )}
                            </Col>
                            {values[product.code] && (
                                <Col sm="6">
                                    <ProductTag {...product} />
                                </Col>
                            )}
                        </Row>
                    </FormGroup>
                ))}
            </CardBody>
        </Card>
    );
};

export default ProductsForm;
