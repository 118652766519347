import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col, FormGroup, Label } from '@bytbil/bootstrap-react';
import { Field } from '@bytbil/general-react';

const propTypes = {
    touched: PropTypes.shape({
        organizationNumber: PropTypes.bool
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        organizationNumber: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.shape({
        organizationNumber: PropTypes.string
    }).isRequired,
    isFetching: PropTypes.bool
};

const defaultProps = {
    isFetching: false
};

const PrivacyListForm = React.memo(props => {
    const { handleSubmit, handleChange, handleBlur, values, isFetching } = props;

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-0" row>
                <Col xs="12" md="6" lg="4" xl="3">
                    <Label for="startDate">Från</Label>
                    <Field
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.startDate}
                    />
                </Col>
                <Col xs="12" md="6" lg="4" xl="3">
                    <Label for="endDate">Till</Label>
                    <Field
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endDate}
                    />
                </Col>
            </FormGroup>
            <Row>
                <Col xs="12" md="auto">
                    <Button type="submit" loading={isFetching} color="primary" block>
                        <i className="fa fa-download mr-2" />
                        Hämta
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});

PrivacyListForm.propTypes = propTypes;
PrivacyListForm.defaultProps = defaultProps;

export default PrivacyListForm;
