import React from 'react';
import { Button } from '@bytbil/bootstrap-react';

import { Scope, ScopeHeader } from '../../types';

interface Props {
    scope: Scope;
    scopeHeaders: ScopeHeader;
    onDelete(scope: Scope): void;
}

const ScopeTableTow = React.memo(({ scope, onDelete, scopeHeaders }: Props) => {
    const { scope: scopeScope, dealerId, dealerGroup, value } = scope;

    return (
        <tr>
            <td>{scopeHeaders[scopeScope] ? scopeHeaders[scopeScope].name : scopeScope}</td>
            <td>{dealerId || '-'}</td>
            <td>{dealerGroup || '-'}</td>
            <td>{value ? value.toString() : '-'}</td>
            <td>
                <Button color="danger" onClick={() => onDelete(scope)}>
                    Ta bort
                </Button>
            </td>
        </tr>
    );
});

export default ScopeTableTow;
