import gql from 'graphql-tag';

export default gql`
    query SearchDealers($search: String!, $searchById: Boolean) {
        searchDealers(search: $search, searchById: $searchById) {
            id
            code
            name
            city
            groups {
                code
                admin
            }
        }
    }
`;
