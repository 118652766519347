import React from 'react';
import { useQuery } from '@apollo/client';

import { DealerFormData } from 'types';

import Loader from 'components/Loader/Loader';

import DealerAddContainer from './containers/DealerAddContainer';
import DealerEditContainer from './containers/DealerEditContainer';

import dealerFormQuery from './queries/dealerForm';

interface Props {
    dealerId?: number;
}

const Dealer = ({ dealerId }: Props) => {
    const { loading, data, error } = useQuery<DealerFormData>(dealerFormQuery);

    if (loading && !data) {
        return <Loader autocenter size={4} />;
    }

    if (!loading && !error && !data) {
        return <></>;
    }

    if (dealerId && data?.dealerForm) {
        return <DealerEditContainer dealerId={dealerId} dealerForm={data.dealerForm} />;
    }

    if (data?.dealerForm) {
        return <DealerAddContainer dealerForm={data.dealerForm} />;
    }

    return <>Något gick fel</>;
};

export default Dealer;
