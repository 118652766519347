import React from 'react';
import Helmet from 'react-helmet-async';
import { List } from 'features/ftp-user';
import MainContainer from '../../components/MainContainer';

const Route = () => (
    <React.Fragment>
        <Helmet>
            <title>FTP-användare</title>
        </Helmet>
        <MainContainer>
            <List />
        </MainContainer>
    </React.Fragment>
);

export default Route;
